import React, { useEffect, useState } from "react";
import { defineSearch, refineSubtitle } from "../../utilities/functions";
import { newsItems } from "../../utilities/types";
import { LineBottom, NextPage, PrevPage } from "../animations/Icons";
import manReadingNewsPaper from "../../media/imgs/background/manReadingNewsPaper.png";
import $ from "jquery";
import { getData } from "../../utilities/data/get_data";
import { Link, useLocation } from "react-router-dom";
import { REPLACE_URL } from "../../utilities/constraints";
import DOMPurify from "isomorphic-dompurify";

const SearchContent: React.FC<newsItems[]> = (props) => {
  const [data, setDataLoaded] = useState<newsItems[]>(props);
  const [objectsKey, setObjectsKey] = useState<string[]>(Object.keys(props));
  const [offSet, setOffSet] = useState<number>(0);
  const [firstTimeOffSet, setFirstTimeOffSet] = useState<boolean>(true);
  const [latsPageToSearch, setLastPageToSearch] = useState<boolean>(false);
  const location = useLocation();
  const slug = location.pathname.replace("/pesquisa=", "");
  const search = defineSearch(slug);

  const contentOpacityMedium = () => {
    $("#id_search_content").fadeTo("slow", 0.5);
  };
  const contentOpacityMax = () => {
    $("#id_search_content").fadeTo("slow", 1);
  };

  const scroolToTop = () => {
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $("#id_search_content").offset()?.top,
      },
      1000,
      () => contentOpacityMax()
    );
  };

  const getDataGeneralNews = async () => {
    const auxGeneralNewsData: newsItems[] = await getData(
      {
        dataToGet: "SEARCH_FIELDS_NAVIGATE",
      },
      search,
      offSet * 10
    );

    if (auxGeneralNewsData.length === 0) {
      setOffSet(offSet - 1);
      setLastPageToSearch(true);
      contentOpacityMax();
    } else if (auxGeneralNewsData.length < 10) {
      const newData: newsItems[] | void = refineSubtitle(auxGeneralNewsData);
      const auxObjectsKey = Object.keys(newData);
      setObjectsKey(auxObjectsKey);
      scroolToTop();
      setLastPageToSearch(true);
      setDataLoaded(newData);
    } else if (auxGeneralNewsData) {
      const newData: newsItems[] | void = refineSubtitle(auxGeneralNewsData);
      const auxObjectsKey = Object.keys(newData);
      setObjectsKey(auxObjectsKey);
      scroolToTop();
      setDataLoaded(newData);
      if (latsPageToSearch) {
        setLastPageToSearch(false);
      }
    }
  };

  useEffect(() => {
    if (firstTimeOffSet) {
      setFirstTimeOffSet(false);
    } else {
      getDataGeneralNews();
      contentOpacityMedium();
    }
  }, [offSet]);

  useEffect(() => {
    const newData: newsItems[] | void = refineSubtitle(props);
    setDataLoaded(newData);
  }, []);
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center mt-5">
        <div className="col-12 col-lg-8">
          <div className="col-12">
            <div id="id_search_content">
              {objectsKey.map((key, index) => {
                if (index === 0) {
                  $("#id_first_news_search").css(
                    "background-image",
                    `url(${data[+key].jetpack_featured_media_url})`
                  );
                  return (
                    <div key={index}>
                      <div
                        id="id_first_news_search"
                        className="divBigImg imgBigCardsNews"
                      ></div>
                      <div className="backgroundBlueBlurSearch text-center">
                        <div className="row justify-content-center align-items-center maxHeight p-2">
                          <h3
                            className="fontBebasNeue text-light"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                data[+key].title.rendered
                              ),
                            }}
                          >
                            {/* {data[+key].title.rendered} */}
                          </h3>
                          <p
                            className="text-light fs-5 fontIbarraReal"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                data[+key].excerpt.rendered
                              ),
                            }}
                          >
                            {/* {data[+key].excerpt.rendered} */}
                          </p>
                          <div className="col-6">
                            <Link
                              to={data[+key].link.replace(REPLACE_URL, "")}
                              state={{ data: data[+key] }}
                            >
                              <button className="button w-100">
                                <span className="txtBtn">Acessar</span>
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="hrdivider mb-2 mt-2 d-block d-md-none w-100" />
                    </div>
                  );
                } else if (index !== 4 && index !== 5 && index !== 9) {
                  if (index % 2 === 0) {
                    return (
                      <div
                        key={index}
                        className="row mt-1 divNewsCars justify-content-center align-items-center"
                      >
                        <div className="col-12 col-md-5">
                          <img
                            src={data[+key].jetpack_featured_media_url}
                            className="imgCardsNews"
                          />
                        </div>
                        <div className="col-12 col-md-7">
                          <h3 className="text-md-start text-center fs-5 fontIbarraReal fw-semibold">
                            {data[+key].title.rendered}
                          </h3>
                          <div className="col-12 row justify-content-center align-items-center">
                            <div className="col-6">
                              <Link
                                to={data[+key].link.replace(REPLACE_URL, "")}
                                state={{ data: data[+key] }}
                              >
                                <button className="button w-100">
                                  <span className="txtBtn">Acessar</span>
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="hrdivider mb-2 mt-2 d-block d-md-none w-100" />
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={index}
                        className="row mt-1 divNewsCars justify-content-center align-items-center"
                      >
                        <div className="col-12 d-block d-md-none">
                          <img
                            src={data[+key].jetpack_featured_media_url}
                            className="imgCardsNews"
                          />
                        </div>
                        <div className="col-12 col-md-7">
                          <h3 className="text-md-start text-centerstart fs-5 fontIbarraReal fw-semibold">
                            {data[+key].title.rendered}
                          </h3>
                          <div className="col-12 row justify-content-center align-items-center">
                            <div className="col-6">
                              <Link
                                to={data[+key].link.replace(REPLACE_URL, "")}
                                state={{ data: data[+key] }}
                              >
                                <button className="button w-100">
                                  <span className="txtBtn">Acessar</span>
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                        <div className="col-5 d-none d-md-block">
                          <img
                            src={data[+key].jetpack_featured_media_url}
                            className="imgCardsNews"
                          />
                        </div>
                        <div className="hrdivider mb-2 mt-2 d-block d-md-none w-100" />
                      </div>
                    );
                  }
                } else if (index === 4) {
                  $("#id_second_news_search").css(
                    "background-image",
                    `url(${data[+key].jetpack_featured_media_url})`
                  );
                  return (
                    <div key={index}>
                      <div
                        id="id_second_news_search"
                        className="divBigImg mt-1 imgBigCardsNews"
                      >
                        <div className="row justify-content-end align-items-center maxHeight">
                          <div className="d-none d-md-block col-6 backgroundBlueBlur text-center">
                            <div className="row justify-content-center align-items-center maxHeight p-2">
                              <h3
                                className="fontBebasNeue text-light"
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(
                                    data[+key].title.rendered
                                  ),
                                }}
                              >
                                {/* {data[+key].title.rendered} */}
                              </h3>
                              <p
                                className="text-light fs-5 fontIbarraReal"
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(
                                    data[+key].excerpt.rendered
                                  ),
                                }}
                              >
                                {/* {data[+key].excerpt.rendered} */}
                              </p>
                              <div className="col-6">
                                <Link
                                  to={data[+key].link.replace(REPLACE_URL, "")}
                                  state={{ data: data[+key] }}
                                >
                                  <button className="button w-100">
                                    <span className="txtBtn">Acessar</span>
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-block d-md-none col-12 backgroundBlueBlur">
                        <div className="row justify-content-center align-items-center maxHeight p-2 text-center">
                          <h3
                            className="fontBebasNeue text-light"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                data[+key].title.rendered
                              ),
                            }}
                          >
                            {/* {data[+key].title.rendered} */}
                          </h3>
                          <p
                            className="text-light fs-5 fontIbarraReal"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                data[+key].excerpt.rendered
                              ),
                            }}
                          >
                            {/* {data[+key].excerpt.rendered} */}
                          </p>
                          <div className="col-6">
                            <Link
                              to={data[+key].link.replace(REPLACE_URL, "")}
                              state={{ data: data[+key] }}
                            >
                              <button className="button w-100">
                                <span className="txtBtn">Acessar</span>
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="hrdivider mb-2 mt-2 d-block d-md-none w-100" />
                    </div>
                  );
                } else if (index === 5) {
                  $("#id_third_news_search").css(
                    "background-image",
                    `url(${data[+key].jetpack_featured_media_url})`
                  );
                  return (
                    <div key={index} className="col-12">
                      <div
                        id="id_third_news_search"
                        className="divBigImg imgBigCardsNews mt-1"
                      >
                        <div className="d-none d-md-block col-6 backgroundBlueBlur text-center">
                          <div className="row justify-content-center align-items-center maxHeight p-2">
                            <h3
                              className="fontBebasNeue text-light"
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  data[+key].title.rendered
                                ),
                              }}
                            >
                              {/* {data[+key].title.rendered} */}
                            </h3>
                            <p
                              className="text-light fs-5 fontIbarraReal"
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  data[+key].excerpt.rendered
                                ),
                              }}
                            >
                              {/* {data[+key].excerpt.rendered} */}
                            </p>
                            <div className="col-6">
                              <Link
                                to={data[+key].link.replace(REPLACE_URL, "")}
                                state={{ data: data[+key] }}
                              >
                                <button className="button w-100">
                                  <span className="txtBtn">Acessar</span>
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-block d-md-none col-12 backgroundBlueBlur">
                        <div className="row justify-content-center align-items-center maxHeight p-2 text-center">
                          <h3
                            className="fontBebasNeue text-light"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                data[+key].title.rendered
                              ),
                            }}
                          >
                            {/* {data[+key].title.rendered} */}
                          </h3>
                          <p
                            className="text-light fs-5 fontIbarraReal"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                data[+key].excerpt.rendered
                              ),
                            }}
                          >
                            {/* {data[+key].excerpt.rendered} */}
                          </p>
                          <div className="col-6">
                            <Link
                              to={data[+key].link.replace(REPLACE_URL, "")}
                              state={{ data: data[+key] }}
                            >
                              <button className="button w-100">
                                <span className="txtBtn">Acessar</span>
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="hrdivider mb-2 mt-2 d-block d-md-none w-100" />
                    </div>
                  );
                } else {
                  $("#id_last_news_search").css(
                    "background-image",
                    `url(${data[+key].jetpack_featured_media_url})`
                  );
                  return (
                    <div key={index}>
                      <div
                        id="id_last_news_search"
                        className="divBigImg mt-1 imgBigCardsNews"
                      >
                        <div className="row justify-content-end align-items-center maxHeight">
                          <div className="d-none d-md-block col-6 backgroundBlueBlur text-center">
                            <div className="row justify-content-center align-items-center maxHeight p-2">
                              <h3
                                className="fontBebasNeue text-light"
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(
                                    data[+key].title.rendered
                                  ),
                                }}
                              >
                                {/* {data[+key].title.rendered} */}
                              </h3>
                              <p
                                className="text-light fs-5 fontIbarraReal"
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(
                                    data[+key].excerpt.rendered
                                  ),
                                }}
                              >
                                {/* {data[+key].excerpt.rendered} */}
                              </p>
                              <div className="col-6">
                                <Link
                                  to={data[+key].link.replace(REPLACE_URL, "")}
                                  state={{ data: data[+key] }}
                                >
                                  <button className="button w-100">
                                    <span className="txtBtn">Acessar</span>
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-block d-md-none col-12 backgroundBlueBlur">
                        <div className="row justify-content-center align-items-center maxHeight p-2 text-center">
                          <h3
                            className="fontBebasNeue text-light"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                data[+key].title.rendered
                              ),
                            }}
                          >
                            {/* {data[+key].title.rendered} */}
                          </h3>
                          <p
                            className="text-light fs-5 fontIbarraReal"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                data[+key].excerpt.rendered
                              ),
                            }}
                          >
                            {/* {data[+key].excerpt.rendered} */}
                          </p>
                          <div className="col-6">
                            <Link
                              to={data[+key].link.replace(REPLACE_URL, "")}
                              state={{ data: data[+key] }}
                            >
                              <button className="button w-100">
                                <span className="txtBtn">Acessar</span>
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>

            <div className="row justify-content-center align-items-center mt-3 mb-5">
              <div className="container row w-100">
                <div className="col-6 col-md-3">
                  {!latsPageToSearch && (
                    <div
                      className="row justify-content-center align-items-center btnButtons pt-2 pb-2"
                      onClick={() => setOffSet(offSet + 1)}
                    >
                      <div className="col-3 col-md-3">
                        <PrevPage />
                      </div>
                      <div className="col-6 fontRalewayRegular fs-5 text-start">
                        Anterior
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-6 col-md-3 ms-auto">
                  {offSet !== 0 && (
                    <div
                      className="row justify-content-center align-items-center btnButtons pt-2 pb-2"
                      onClick={() => setOffSet(offSet - 1)}
                    >
                      <div className="col-6 fontRalewayRegular fs-5 text-end">
                        Próximo
                      </div>
                      <div className="col-3 col-md-3">
                        <NextPage />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 mb-5">
        <LineBottom />
      </div>
      <div className="row justify-content-center align-items-center">
        <div className="col-8">
          <img
            src={manReadingNewsPaper}
            className="img-fluid"
            alt="Homem lendo jornal"
          />
        </div>
      </div>
    </div>
  );
};

export default SearchContent;
