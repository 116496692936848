import React, { useEffect, useState } from "react";
import { refineSubtitle } from "../../utilities/functions";
import { newsItems } from "../../utilities/types";
import $ from "jquery";
import { Link } from "react-router-dom";
import { REPLACE_URL } from "../../utilities/constraints";
import DOMPurify from "isomorphic-dompurify";

const Sports: React.FC<newsItems[]> = (props) => {
  const [data, setDataLoaded] = useState<newsItems[]>(props);

  const defineImageBackgroundNews = () => {
    $("#id_first_news_sports").css(
      "background-image",
      `url(${data[0].jetpack_featured_media_url})`
    );
    $("#id_second_news_sports").css(
      "background-image",
      `url(${data[1].jetpack_featured_media_url})`
    );
    $("#id_third_news_sports").css(
      "background-image",
      `url(${data[2].jetpack_featured_media_url})`
    );
    $("#id_last_news_sports").css(
      "background-image",
      `url(${data[3].jetpack_featured_media_url})`
    );
  };

  useEffect(() => {
    const newData: newsItems[] | void = refineSubtitle(props);
    setDataLoaded(newData);
    defineImageBackgroundNews();
  }, []);
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 d-block d-lg-none">
          <div className="positionLabelFixed text-center">
            <div className="fontBebasNeue display-4 fontNewsTitleColor">
              Esportes
            </div>
            <div>
              <div
                id="id_sports"
                className="newsCategoryTitle fontBebasNeue display-4 "
              >
                Esportes
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-lg-12 row align-items-md-center align-items-start ">
          <div className="col-12 col-md-8">
            <div>
              <Link
                to={data[0].link.replace(REPLACE_URL, "")}
                state={{ data: data[0] }}
                className="text-decoration-none"
              >
                <div
                  id="id_first_news_sports"
                  className="divBigImg imgBigCardsNews text-center divCardsCovers"
                >
                  <div className="row justify-content-end align-items-center maxHeight">
                    <div className="d-none d-md-block col-6 backgroundBlueBlur">
                      <div className="row justify-content-center align-items-center maxHeight p-2">
                        <h3
                          className="fontBebasNeue text-light"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(data[0].title.rendered),
                          }}
                        >
                          {/* {data[0].title.rendered} */}
                        </h3>
                        <p
                          className="text-light fs-5 fontIbarraReal"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              data[0].excerpt.rendered
                            ),
                          }}
                        >
                          {/* {data[0].excerpt.rendered} */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-block d-md-none col-12 backgroundBlueBlur">
                  <div className="row justify-content-center align-items-center maxHeight p-2 text-center">
                    <h3
                      className="fontBebasNeue text-light"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data[0].title.rendered),
                      }}
                    >
                      {/* {data[0].title.rendered} */}
                    </h3>
                    <p
                      className="text-light fs-5 fontIbarraReal"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data[0].excerpt.rendered),
                      }}
                    >
                      {/* {data[0].excerpt.rendered} */}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="col-12 text-center text-lg-start col-lg-4 col-xl-4 d-none d-lg-block">
            <div id="id_sports_label" className="text-center">
              <div className="fontBebasNeue display-4 fontNewsTitleColor ">
                Esportes
              </div>
              <div>
                <div
                  id="id_sports"
                  className="newsCategoryTitle fontBebasNeue display-4"
                >
                  Esportes
                </div>
              </div>
            </div>
          </div>

          <div className="hrdivider mb-2 mt-2 d-block d-md-none w-100" />

          <div className="col-12 col-md-8">
            <Link
              to={data[3].link.replace(REPLACE_URL, "")}
              state={{ data: data[3] }}
              className="text-decoration-none"
            >
              <div
                id="id_last_news_sports"
                className="divBigImg mt-1 imgBigCardsNews text-center"
              >
                <div className="row justify-content-start align-items-center maxHeight">
                  <div className="d-none d-md-block col-6 backgroundBlueBlur">
                    <div className="row justify-content-center align-items-center maxHeight p-2">
                      <h3
                        className="fontBebasNeue text-light"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(data[3].title.rendered),
                        }}
                      >
                        {/* {data[3].title.rendered} */}
                      </h3>
                      <p
                        className="text-light fs-5 fontIbarraReal"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(data[3].excerpt.rendered),
                        }}
                      >
                        {/* {data[3].excerpt.rendered} */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-block d-md-none col-12 backgroundBlueBlur">
                <div className="row justify-content-center align-items-center maxHeight p-2 text-center">
                  <h3
                    className="fontBebasNeue text-light"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(data[3].title.rendered),
                    }}
                  >
                    {/* {data[3].title.rendered} */}
                  </h3>
                  <p
                    className="text-light fs-5 fontIbarraReal"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(data[3].excerpt.rendered),
                    }}
                  >
                    {/* {data[3].excerpt.rendered} */}
                  </p>
                </div>
              </div>
            </Link>
          </div>
          <div className="hrdivider mb-2 mt-2 d-block d-md-none w-100" />
          <div className="col-12 col-md-4 divLeft">
            <Link
              to={data[1].link.replace(REPLACE_URL, "")}
              state={{ data: data[1] }}
              className="text-decoration-none"
            >
              <div
                id="id_second_news_sports"
                className="col-12 mb-2 mt-1 divSmallImg"
              >
                <div className="row align-items-end h-100">
                  <div className="row align-items-center col-12 backgroundBlueBlurSmall">
                    <h3
                      className="text-center fs-6 fontIbarraReal text-light"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data[1].title.rendered),
                      }}
                    >
                      {/* {data[1].title.rendered} */}
                    </h3>
                  </div>
                </div>
              </div>
            </Link>

            <div className="hrdivider mb-2 mt-2 d-block d-md-none w-100" />
            <Link
              to={data[2].link.replace(REPLACE_URL, "")}
              state={{ data: data[2] }}
              className="text-decoration-none"
            >
              <div
                id="id_third_news_sports"
                className="col-12 mt-2 divSmallImg"
              >
                <div className="row align-items-end h-100">
                  <div className="row align-items-center col-12 backgroundBlueBlurSmall">
                    <h3
                      className="text-center fs-6 fontIbarraReal text-light"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data[2].title.rendered),
                      }}
                    >
                      {/* {data[2].title.rendered} */}
                    </h3>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sports;
