export const API_URL = "https://admin.lftv.com.br/wp-json/wp/v2";
export const COVER =
  "/posts?per_page=4&categories=5&categories=6&tax_related=AND&_fields=title,id,date,slug,link,content,excerpt,author,categories,tags,jetpack_featured_media_url,x_author,x_date";
export const ITEMS_CATEGORIES = "/categories?per_page=100&_fields=name,id";
export const HOME_LAURO_DE_FREITAS =
  "/posts?per_page=4&categories=10&_fields=title,id,date,slug,link,content,excerpt,author,categories,tags,jetpack_featured_media_url,x_author,x_date";
export const HOME_CAMACARI =
  "/posts?per_page=4&categories=4&_fields=title,id,date,slug,link,content,excerpt,author,categories,tags,jetpack_featured_media_url,x_author,x_date";
export const HOME_BAHIA =
  "/posts?per_page=4&categories=2&_fields=title,id,date,slug,link,content,excerpt,author,categories,tags,jetpack_featured_media_url,x_author,x_date";
export const HOME_SPORTS =
  "/posts?per_page=4&categories=9&_fields=title,id,date,slug,link,content,excerpt,author,categories,tags,jetpack_featured_media_url,x_author,x_date";
export const GENERAL_NEWS =
  "/posts?per_page=10&_fields=title,id,date,slug,link,content,excerpt,author,categories,tags,jetpack_featured_media_url,x_author,x_date";
export const GENERAL_NEWS_NAVIGATE =
  "/posts?per_page=10&_fields=title,id,date,slug,link,content,excerpt,author,categories,tags,jetpack_featured_media_url,x_author,x_date&offset=";
export const SPORTS_NEWS_NAVIGATE =
  "/posts?&categories=9&per_page=10&_fields=title,id,date,slug,link,content,excerpt,author,categories,tags,jetpack_featured_media_url,x_author,x_date&offset=";
export const FIRST_BANNER =
  "/primeiro_banner?slug=primeiro-banner&_fields=x_metadata,x_featured_media_original";
export const SECOND_BANNER =
  "/segundo_banner?slug=segundo-banner&_fields=x_metadata,x_featured_media_original";
export const THIRD_BANNER =
  "/terceiro_banner?slug=terceiro-banner&_fields=x_metadata,x_featured_media_original";
export const FOURTH_BANNER =
  "/quarto_banner?slug=quarto-banner&_fields=x_metadata,x_featured_media_original";
export const POP_UP =
  "/pop_up?slug=pop-up-atual&_fields=x_metadata,x_featured_media_original";
export const RELATED_NEWS = "?_fields=jetpack-related-posts";
export const GET_NEWS =
  "/posts?_fields=title,id,date,slug,link,content,excerpt,author,categories,tags,jetpack_featured_media_url,x_author,x_date,jetpack-related-posts&slug=";
export const COMMENTS = "/comments?post=";
export const AUTHOR = "/users/";
export const AUTHOR_FIELDS = "?_fields=name";
export const COMMENTS_FIELDS = "&_fields=id,author_name,date,content";
export const SEARCH =
  "/posts?_fields=title,id,date,slug,link,content,excerpt,author,categories,tags,jetpack_featured_media_url,x_author,x_date&orderby=relevance&search=";
export const SEARCH_FIELDS_NAVIGATE = "&offset=";
export const PAGE_PER_CATEGORY =
  "/posts?per_page=10&_fields=title,id,date,slug,link,content,excerpt,author,categories,tags,jetpack_featured_media_url,x_author,x_date&categories=";
export const PAGE_PER_CATEGORY_NAVIGATE = "&offset=";
export const REPLACE_URL = "https://admin.lftv.com.br";
