import React, { useEffect, useState } from "react";
import { currentPositionAnimation } from "../../utilities/types";
import { animate, motion, useMotionValue } from "framer-motion";
import { backgroundleft, backgroundright } from "../../utilities/paths";

const path = [backgroundleft, backgroundright];

const variants = {
  outter: {
    scale: 2,
    opacity: 0,
  },
  inner: {
    scale: 1,
    opacity: 1,
  },
};

const Background: React.FC<currentPositionAnimation> = (
  { currentPosition },
  props: React.SVGProps<SVGSVGElement>
) => {
  const [currentPath, setCurrentPath] = useState<number>(0);

  useEffect(() => {
    if (currentPosition === "LEFT") {
      setCurrentPath(0);
    } else if (currentPosition === "RIGHT") {
      setCurrentPath(1);
    }
  }, [currentPosition]);

  return (
    <motion.svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="100vw"
      height="100vh"
      viewBox="0 0 1600 900"
      preserveAspectRatio="none"
      className="svgBackground"
      id="id_animated_background_svg"
      initial="outter"
      animate={currentPosition === "MIDDLE" ? "outter" : "inner"}
      variants={variants}
      transition={{ duration: 1 }}
    >
      <g transform="scale(50 50)">
        <motion.path
          layout
          d={path[currentPath]}
          fill="url(#linearGradient4934)"
        />
      </g>
      <defs id="defs273">
        <linearGradient
          id="linearGradient4934"
          x1="4.3644052"
          x2="4.4144053"
          y1="14.41967"
          y2="2.3324037"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#006b89" offset="0" id="stop233" />
          <stop stopColor="#fff" offset="1" id="stop235" />
        </linearGradient>
        <filter
          id="filter6837"
          x="-15.859102"
          y="-28.906282"
          width="30.955208"
          height="54.711115"
          colorInterpolationFilters="sRGB"
        >
          <feFlood
            floodColor="rgb(0,0,0)"
            floodOpacity=".498"
            result="flood"
            id="feFlood238"
          />
          <feComposite
            in="flood"
            in2="SourceGraphic"
            operator="in"
            result="composite1"
            id="feComposite240"
          />
          <feGaussianBlur
            in="composite1"
            result="blur"
            stdDeviation="39.8754"
            id="feGaussianBlur242"
          />
          <feOffset
            dx="5.45171"
            dy="-26.324"
            result="offset"
            id="feOffset244"
          />
          <feComposite
            in="SourceGraphic"
            in2="offset"
            result="fbSourceGraphic"
            id="feComposite246"
          />
          <feColorMatrix
            in="fbSourceGraphic"
            result="fbSourceGraphicAlpha"
            values="0 0 0 -1 0 0 0 0 -1 0 0 0 0 -1 0 0 0 0 1 0"
            id="feColorMatrix248"
          />
          <feFlood
            floodColor="rgb(0,0,0)"
            floodOpacity=".498"
            in="fbSourceGraphic"
            result="flood"
            id="feFlood250"
          />
          <feComposite
            in="flood"
            in2="fbSourceGraphic"
            operator="in"
            result="composite1"
            id="feComposite252"
          />
          <feGaussianBlur
            in="composite1"
            result="blur"
            stdDeviation="83.4891"
            id="feGaussianBlur254"
          />
          <feOffset
            dx="-34.1121"
            dy="-27.8816"
            result="offset"
            id="feOffset256"
          />
          <feComposite
            in="fbSourceGraphic"
            in2="offset"
            result="fbSourceGraphic"
            id="feComposite258"
          />
          <feColorMatrix
            in="fbSourceGraphic"
            result="fbSourceGraphicAlpha"
            values="0 0 0 -1 0 0 0 0 -1 0 0 0 0 -1 0 0 0 0 1 0"
            id="feColorMatrix260"
          />
          <feFlood
            floodColor="rgb(0,0,0)"
            in="fbSourceGraphic"
            result="flood"
            id="feFlood262"
          />
          <feComposite
            in="flood"
            in2="fbSourceGraphic"
            operator="in"
            result="composite1"
            id="feComposite264"
          />
          <feGaussianBlur
            in="composite1"
            result="blur"
            stdDeviation="83.4891"
            id="feGaussianBlur266"
          />
          <feOffset
            dx="-34.1121"
            dy="-27.8816"
            result="offset"
            id="feOffset268"
          />
          <feComposite
            in="fbSourceGraphic"
            in2="offset"
            result="composite2"
            id="feComposite270"
          />
        </filter>
      </defs>
      {/* <defs id="defs8">
        <linearGradient id="linearGradient4932">
          <stop stopColor="#006b89" stopOpacity={1} offset="0" id="stop4928" />
          <stop stopColor="#ffffff" stopOpacity={1} offset="1" id="stop4930" />
        </linearGradient>
        <linearGradient
          xlinkHref="#linearGradient4932"
          id="linearGradient4934"
          x1="4.0216699"
          y1="16.396038"
          x2="4.0658641"
          y2="8.7946405"
          gradientUnits="userSpaceOnUse"
        />
        <filter
          colorInterpolationFilters="sRGB"
          id="filter6837"
          x="-15.863449"
          y="-28.912852"
          width="30.96342"
          height="54.723321"
        >
          <feFlood
            floodOpacity="0.498039"
            floodColor="rgb(0,0,0)"
            result="flood"
            id="feFlood6827"
          />
          <feComposite
            in="flood"
            in2="SourceGraphic"
            operator="in"
            result="composite1"
            id="feComposite6829"
          />
          <feGaussianBlur
            in="composite1"
            stdDeviation="39.8754"
            result="blur"
            id="feGaussianBlur6831"
          />
          <feOffset
            dx="5.45171"
            dy="-26.324"
            result="offset"
            id="feOffset6833"
          />
          <feComposite
            in="SourceGraphic"
            in2="offset"
            operator="over"
            result="fbSourceGraphic"
            id="feComposite6835"
          />
          <feColorMatrix
            result="fbSourceGraphicAlpha"
            in="fbSourceGraphic"
            values="0 0 0 -1 0 0 0 0 -1 0 0 0 0 -1 0 0 0 0 1 0"
            id="feColorMatrix6935"
          />
          <feFlood
            id="feFlood6937"
            floodOpacity="0.498039"
            floodColor="rgb(0,0,0)"
            result="flood"
            in="fbSourceGraphic"
          />
          <feComposite
            in2="fbSourceGraphic"
            id="feComposite6939"
            in="flood"
            operator="in"
            result="composite1"
          />
          <feGaussianBlur
            id="feGaussianBlur6941"
            in="composite1"
            stdDeviation="83.4891"
            result="blur"
          />
          <feOffset
            id="feOffset6943"
            dx="-34.1121"
            dy="-27.8816"
            result="offset"
          />
          <feComposite
            in2="offset"
            id="feComposite6945"
            in="fbSourceGraphic"
            operator="over"
            result="fbSourceGraphic"
          />
          <feColorMatrix
            result="fbSourceGraphicAlpha"
            in="fbSourceGraphic"
            values="0 0 0 -1 0 0 0 0 -1 0 0 0 0 -1 0 0 0 0 1 0"
            id="feColorMatrix6947"
          />
          <feFlood
            id="feFlood6949"
            floodOpacity="1"
            floodColor="rgb(0,0,0)"
            result="flood"
            in="fbSourceGraphic"
          />
          <feComposite
            in2="fbSourceGraphic"
            id="feComposite6951"
            in="flood"
            operator="in"
            result="composite1"
          />
          <feGaussianBlur
            id="feGaussianBlur6953"
            in="composite1"
            stdDeviation="83.4891"
            result="blur"
          />
          <feOffset
            id="feOffset6955"
            dx="-34.1121"
            dy="-27.8816"
            result="offset"
          />
          <feComposite
            in2="offset"
            id="feComposite6957"
            in="fbSourceGraphic"
            operator="over"
            result="composite2"
          />
        </filter>
      </defs> */}
    </motion.svg>
  );
};

export default React.memo(Background);
