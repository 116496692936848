import React, { useEffect, useState } from "react";
import Footer from "../components/geral/Footer";
import Navbar from "../components/geral/NavBar";

import {
  banner,
  commentsItems,
  newsItems,
  relatedNews,
} from "../utilities/types";
import { useLocation, useNavigate } from "react-router-dom";
import NewsContent from "../components/geral/NewsContent";
import Related from "../components/geral/Related";
import { getData } from "../utilities/data/get_data";
import { UniqueNewsBanner } from "../components/animations/BannerAnimations";
import { refineContentComments, refineSubtitle } from "../utilities/functions";
import Comments from "../components/geral/Comments";
import PostComment from "../components/geral/PostComment";
import { LoadingContent } from "../components/animations/LoadingPage";
import PopUp from "../components/geral/PopUp";
import { Helmet } from "react-helmet";
import GoogleAds from "../components/geral/GoogleAds";

const News: React.FC = () => {
  const location = useLocation();
  const [dataProps, setDataProps] = useState<newsItems | any>(null);
  const [relatedNewsData, setRelatedNewsData] = useState<relatedNews | any>(
    null
  );
  const [newsData, setNewsData] = useState<newsItems | any>(null);
  const [commentsData, setCommentsData] = useState<commentsItems[] | any>(null);

  const [bannerData, setBannerData] = useState<banner[] | null | boolean>(null);
  const [firstTimeRelatedNewsData, setFirstTimeRelatedNewsData] =
    useState<boolean>(true);
  const [firstTimeBannerData, setFirstTimeBannerData] = useState<boolean>(true);
  const [firstTimeCommentsData, setFirstTimeCommentsData] =
    useState<boolean>(true);
  const navigate = useNavigate();
  const [statusNews, setStatusNews] = useState<boolean>(false);

  const getNewsData = async () => {
    const auxData: newsItems[] | any = await getData(
      { dataToGet: "GET_NEWS" },
      location.pathname.split("/")[4]
    );
    if (auxData.length > 0) {
      const newDataAjusted: newsItems[] | void = refineSubtitle(auxData);
      setNewsData(newDataAjusted[0]);
      setStatusNews(true);
    } else {
      navigate("../erro_404/" + location.pathname.split("/")[4], {
        replace: true,
      });
    }
  };

  const getDataRelated = async () => {
    const auxRelatedNewsData: relatedNews = await getData(
      { dataToGet: "RELATED_NEWS" },
      dataProps.id
    );
    if (auxRelatedNewsData) {
      setRelatedNewsData(auxRelatedNewsData);
      setStatusNews(true);
    }
  };

  const getDataBanner = async () => {
    const auxBannerData: banner[] = await getData({
      dataToGet: "FIRST_BANNER",
    });
    if (auxBannerData && !Array.isArray(auxBannerData[0])) {
      setBannerData(auxBannerData);
    } else {
      if (bannerData === null) {
        setBannerData(false);
      } else {
        setBannerData(null);
      }
    }
  };

  const getComments = async () => {
    const auxCommentsData: commentsItems[] = await getData(
      {
        dataToGet: "COMMENTS",
      },
      newsData.id
    );
    if (auxCommentsData) {
      const newCommentsData = refineContentComments(auxCommentsData);
      setCommentsData(newCommentsData);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (location.state) {
      const { data }: newsItems | any = location.state;
      setDataProps(data);
    }

    if (dataProps) {
      setNewsData(dataProps);
    } else {
      getNewsData();
    }
  }, []);

  useEffect(() => {
    if (!bannerData) {
      getDataBanner();
    }
  }, [bannerData]);

  useEffect(() => {
    if (!bannerData && firstTimeBannerData) {
      getDataBanner();
      setFirstTimeBannerData(false);
    } else if (!commentsData && firstTimeCommentsData && newsData) {
      getComments();
      setFirstTimeCommentsData(false);
    } else if (!relatedNewsData && firstTimeRelatedNewsData && dataProps) {
      getDataRelated();
      setFirstTimeRelatedNewsData(false);
    }
  }, [relatedNewsData, bannerData, newsData, commentsData]);

  const RelatedNews = () => (
    <span>
      {dataProps ? <Related {...relatedNewsData} /> : <Related {...newsData} />}
    </span>
  );

  return (
    <div>
      <Helmet>
        {newsData && <title>{newsData.title.rendered}</title>}
        {newsData && (
          <meta name="description" content={newsData.excerpt.rendered} />
        )}
        {newsData && (
          <meta
            property="og:image"
            content={newsData.jetpack_featured_media_url}
          />
        )}
        <meta property="og:type" content="article" />
        <meta
          property="og:locale"
          content="pt_BR"
          className="yoast-seo-meta-tag"
        />
        <meta property="og:image:type" content="image/png" />
        <meta
          property="og:image:width"
          content="800"
          className="yoast-seo-meta-tag"
        />
        <meta
          property="og:image:height"
          content="450"
          className="yoast-seo-meta-tag"
        />
      </Helmet>
      <Navbar />
      {newsData ? <NewsContent {...newsData} /> : <LoadingContent />}
      {statusNews ? <RelatedNews /> : <LoadingContent />}
      {/* {relatedNewsData ? <Related {...relatedNewsData} /> : <LoadingContent />}
      {!dataProps && newsData && <Related {...newsData} />} */}
      <div className="row justify-content-center">
        <div className="col-12 col-9">
          {bannerData && bannerData !== true ? (
            <UniqueNewsBanner {...bannerData} />
          ) : (
            <LoadingContent />
          )}
        </div>
      </div>
      {commentsData ? <Comments {...commentsData} /> : <LoadingContent />}
      <PostComment />
      <GoogleAds />
      <Footer />
      {/* <PopUp /> */}
    </div>
  );
};

export default News;
