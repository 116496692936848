import React from "react";
import { Route, Routes } from "react-router-dom";
import Categories from "./pages/Categories";
import Home from "./pages/Home";
import News from "./pages/News";
import PageNotFound from "./pages/PageNotFound";
import Search from "./pages/Search";
import { Helmet } from "react-helmet";
// import Logo from "./media/svgs/logoDesktop.svg";
import Logo from "./media/imgs/logo.png";
import PopUp from "./components/geral/PopUp";

function App() {
  return (
    <div>
      <PopUp />
      <Helmet>
        <title>LFTV</title>
        <meta property="og:title" content="LFTV" />
        <meta property="og:image" content="./media/imgs/logo.png" />
        <meta property="og:url" content="https://lftv.com.br" />
        <meta property="og:description" content="Aqui a gente se vê" />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
        <meta property="og:type" content="website" />
        <meta property="og:image:type" content="image/png" />
        <meta name="description" content="aqui a gente se vê" />
        <meta
          name="keywords"
          content="Notícias, LFTV, Jornalismo, Bahia, Lauro de Freitas"
        />
      </Helmet>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:year/:month/:day/:slug" element={<News />} />
        <Route path="/pesquisa/:searchvalue" element={<Search />} />
        <Route path="/categorias/:name" element={<Categories />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
