import React, { useEffect, useState } from "react";
import { currentPositionAnimation } from "../../utilities/types";
import { animate, motion, useMotionValue } from "framer-motion";
import $ from "jquery";

let auxWidth = $(window).width();
let auxHeight = $(window).height();

const LogoAnimated: React.FC<currentPositionAnimation> = ({
  currentPosition,
}) => {
  const [x, setX] = useState<number>(0);
  const [y, setY] = useState<number>(0);
  const [scale, setScale] = useState<number>(1.3);
  const [width, setWidth] = useState<number | any>(null);
  const [height, setHeight] = useState<number | any>(null);

  const logoColor = useMotionValue<string>("#ffffff");
  const logoTextOpacity = useMotionValue<number>(0);

  useEffect(() => {
    auxWidth = $(window).width();
    auxHeight = $(window).height();
    setWidth(auxWidth);
    setHeight(auxHeight);
    if (width > 768) {
      setX(width / 2);
    } else {
      setX(width / 8);
    }
  }, []);

  useEffect(() => {
    if (currentPosition === "MIDDLE") {
      animate(logoColor, "#ffffff", {
        duration: 1,
      });
      animate(logoTextOpacity, 0, {
        duration: 1,
      });
      if (width > 768) {
        setX(width / 2);
        setScale(1.3);
        setY(height * 0.2);
      } else {
        setX(width / 8);
        setScale(1.3);
        setY(height * 0.45);
      }
    } else if (currentPosition === "LEFT") {
      animate(logoColor, "#4064b0", {
        duration: 1,
      });
      animate(logoTextOpacity, 1, {
        duration: 1,
      });
      if (width > 768) {
        setX(0);
        setY(height * 0.2);
      } else {
        setX(width / 10);
        setY(height * 0.085);
        setY(height * 0.45);
      }
      setScale(1);
    } else {
      animate(logoColor, "#4064b0", {
        duration: 1,
      });
      animate(logoTextOpacity, 1, {
        duration: 1,
      });
      if (width > 768) {
        setX(width * 0.75);
        setY(height * 0.2);
      } else {
        setX(width / 10);
        setY(height * 0.085);
        setY(height * 0.45);
      }
      setScale(1);
    }
  }, [currentPosition]);

  return (
    <div className="position-absolute mb-5 pb-5 mt-5 zIndex-1 maxPageWidth h-100 overflowHidden">
      {width && height && auxWidth && (
        <motion.div
          animate={{ x, scale, y }}
          initial={{
            x: auxWidth > 768 ? auxWidth / 2 : auxWidth / 8,
            scale,
            y: height * 0.2,
          }}
          transition={{ type: "tween", duration: 1 }}
          // className="w-100"
        >
          <div className="d-none d-md-block">
            <div className="col-md-2">
              {/* <img src={Logo} className="img-fluid" alt="lftv logo" /> */}
              <svg
                version="1.1"
                viewBox="0 0 210 210"
                xmlSpace="preserve"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <clipPath id="clipPath81">
                    <path d="m0 1080h1920v-1080h-1920z" />
                  </clipPath>
                </defs>
                <g transform="matrix(.35278 0 0 -.35278 223.18 381.53)">
                  <g
                    transform="translate(-1178.9 117.89)"
                    clipPath="url(#clipPath81)"
                  >
                    <g transform="matrix(.53827 0 0 .53827 721.96 660.44)">
                      <motion.path
                        d="m0 0h-3.421c-7.011 0-12.823 2.103-17.275 6.249-4.556 4.234-6.869 10.668-6.869 19.122v44.914c0 8.036 2.36 14.142 7.016 18.148 4.434 3.816 10.197 5.75 17.128 5.75h3.421v92.519c0 2.061-0.37 3.862-1.132 5.506-0.83 1.783-1.847 3.317-3.018 4.552-1.146 1.202-2.375 2.139-3.643 2.775-1.09 0.542-2.074 0.819-2.915 0.819h-60.131c-4.662 0-7.844-1.123-9.455-3.339-1.999-2.749-2.971-6.122-2.971-10.313v-366.68c0-5.002 0.939-8.683 2.792-10.939 1.61-1.965 4.851-2.959 9.634-2.959h56.941c4.314 0 7.671 1.142 10.264 3.491 2.445 2.218 3.634 5.623 3.634 10.407z"
                        fill={logoColor}
                      />
                    </g>
                    <motion.path
                      d="m783.4 665.82h20.305c-1.2687 2.1116-1.9286 4.8256-1.9286 8.1009v25.497c0 2.4497 0.41555 4.5624 1.2057 6.3327h-19.582c-1.4862 0-2.6913 1.2052-2.6913 2.6913v10.348c0 2.2726 0.50005 4.3976 1.4867 6.3171 0.9479 1.8414 2.2268 3.4664 3.8013 4.832 1.5502 1.3419 3.3216 2.4109 5.267 3.1779 2.427 0.9592 9.5828 1.1788 10.579 1.1788h2.6418c1.9997 0 3.4923 0.70567 4.6975 2.2209 1.3398 1.6832 1.9911 3.7921 1.9911 6.4495v25.233c0 2.5029-0.57326 4.3546-1.7515 5.6615-1.1056 1.2235-2.721 1.8193-4.937 1.8193h-2.6418c-8.1122 0-20.106-1.5427-27.334-4.4908-7.3328-2.9928-13.907-7.1326-19.541-12.305-5.6244-5.1625-10.179-11.27-13.537-18.155-3.3254-6.8172-5.0118-14.198-5.0118-21.939v-10.348c0-1.4862-1.2052-2.6913-2.6913-2.6913h-13.607c-2.4394 0-4.3067-0.58617-5.7083-1.7924-1.2638-1.0873-1.9049-3.0014-1.9049-5.6884v-24.176c0-2.9933 0.66691-5.1302 1.9814-6.3516 1.3893-1.294 3.2312-1.9222 5.6319-1.9222h13.607c1.4862 0 2.6913-1.2046 2.6913-2.6913v-99.567c0-2.1192 0.68468-3.8658 2.0939-5.3396 1.3769-1.4409 3.2253-2.1412 5.6513-2.1412h28.536c2.054 0 3.917 0.66153 5.6976 2.0228 1.5771 1.2063 2.3119 2.9406 2.3119 5.458v99.567c0 1.4867 1.2052 2.6913 2.6913 2.6913"
                      fill={logoColor}
                    />
                    <motion.path
                      d="m900.49 666.5-16.456 40.267h-4.7879c-0.71482 0-1.4 0.2842-1.9055 0.79017-0.50489 0.50651-0.78748 1.1928-0.78587 1.9076l0.13188 51.94c0 2.5778-0.43277 4.6345-1.287 6.1056-0.57918 0.99472-1.997 1.5007-4.212 1.5007h-1.1228c-0.16956 0-0.35957-0.0172-0.56841-0.0511l-29.791-10.368c-3.0401-1.1212-5.4074-2.3049-6.99-3.502-0.70406-0.53235-1.6401-1.5265-1.6401-3.9966v-8e-3l-0.13241-41.636c-4e-3 -1.4829-1.2079-2.6827-2.6914-2.6827h-13.607c-5.3132 0-7.4808-2.1294-7.4808-7.349v-25.497c0-5.689 2.1676-8.0094 7.4808-8.0094h13.607c1.4851 0 2.6897-1.2025 2.6914-2.6876l0.13241-99.658c0-5.3176 2.1671-7.4852 7.4809-7.4852h29.857c2.7409 0 4.852 0.63946 6.2741 1.8996 1.3457 1.1928 1.9997 3.0191 1.9997 5.5818l-0.13188 99.655c-1e-3 0.71482 0.28259 1.4 0.78695 1.9055 0.50543 0.50544 1.1901 0.78964 1.9044 0.78964h18.099c1.1976 0 2.2376 0.19862 3.1456 0.58833"
                      fill={logoColor}
                    />
                    <motion.path
                      d="m1043.3 717.8c-0.6567 0.30574-2.0034 0.67176-4.6738 0.67176h-34.744c-2.0595 0-3.6678-0.34665-4.6518-1.0017-0.67391-0.44999-1.7192-1.5787-2.869-4.3885l-27.984-81.021c-0.36872-1.0674-1.3634-1.7908-2.4916-1.8124-1.1288-0.0339-2.1504 0.66261-2.56 1.7144-0.0964 0.24707-0.65076 1.7607-2.0072 5.4672-22.122 60.449-28.688 77.11-30.148 79.962-1.1659 0.72505-2.4949 1.0792-4.0446 1.0792h-33.82c-4.4391 0-5.1539-1.6595-5.3891-2.2048-0.77188-1.7903-0.45699-4.1457 0.93766-7.0023 0.0264-0.0533 0.0506-0.10765 0.0727-0.16255l60.903-149.02c0.51727-1.2945 1.2606-2.2596 2.2828-2.9707 0.55657-0.38809 2.0637-1.0367 5.9269-1.0367h15.721c1.9555 0 3.4148 0.35472 4.22 1.0265 1.0405 0.86608 1.9674 1.941 2.7586 3.1984l62.801 150.54c0.5426 1.3198 0.9355 2.5918 1.1621 3.7674 0.1631 0.84239 0.1287 1.5249-0.1065 2.0896-0.1712 0.40963-0.6083 0.78319-1.2962 1.1051"
                      fill={logoColor}
                    />
                    <g transform="matrix(.53827 0 0 .53827 846.88 904.12)">
                      <motion.path
                        d="m0 0c-242.89 0-440.5-198.41-440.5-442.3 0-243.88 197.61-442.3 440.5-442.3s440.5 198.41 440.5 442.3c0 243.88-197.61 442.3-440.5 442.3m0-969.59c-70.943 0-139.78 13.955-204.61 41.476-62.586 26.571-118.78 64.596-167.03 113.02-48.235 48.414-86.108 104.8-112.57 167.59-27.399 65.019-41.292 134.06-41.292 205.21 0 71.146 13.893 140.19 41.292 205.21 26.46 62.791 64.333 119.18 112.57 167.59 48.245 48.425 104.44 86.45 167.03 113.02 64.828 27.522 133.67 41.477 204.61 41.477 70.944 0 139.79-13.955 204.61-41.477 62.586-26.57 118.78-64.595 167.03-113.02 48.234-48.414 86.108-104.8 112.57-167.59 27.4-65.02 41.292-134.06 41.292-205.21 0-71.147-13.892-140.19-41.292-205.21-26.459-62.791-64.333-119.18-112.57-167.59-48.246-48.425-104.44-86.45-167.03-113.02-64.828-27.521-133.67-41.476-204.61-41.476"
                        fill={logoColor}
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div>

            <motion.div
              className="col-lg-2 col-xl-3 text-lg-center text-xl-start"
              style={{ opacity: logoTextOpacity }}
            >
              <h2 className="fs-3 fontInter">Aqui a gente se vê</h2>
            </motion.div>
          </div>
          <div className="d-block d-md-none pb-5 row justify-content-center">
            <div className="col-8">
              {/* <img src={LogoMobile} alt="lftv logo" className="img-fluid" /> */}
              <svg
                version="1.1"
                viewBox="0 0 210 210"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <clipPath id="clipPath81">
                    <path d="m0 1080h1920v-1080h-1920z" />
                  </clipPath>
                </defs>
                <g transform="matrix(.35278 0 0 -.35278 223.18 381.53)">
                  <g
                    transform="translate(-1178.9 117.89)"
                    clipPath="url(#clipPath81)"
                  >
                    <g transform="matrix(.53827 0 0 .53827 721.96 660.44)">
                      <path
                        d="m0 0h-3.421c-7.011 0-12.823 2.103-17.275 6.249-4.556 4.234-6.869 10.668-6.869 19.122v44.914c0 8.036 2.36 14.142 7.016 18.148 4.434 3.816 10.197 5.75 17.128 5.75h3.421v92.519c0 2.061-0.37 3.862-1.132 5.506-0.83 1.783-1.847 3.317-3.018 4.552-1.146 1.202-2.375 2.139-3.643 2.775-1.09 0.542-2.074 0.819-2.915 0.819h-60.131c-4.662 0-7.844-1.123-9.455-3.339-1.999-2.749-2.971-6.122-2.971-10.313v-366.68c0-5.002 0.939-8.683 2.792-10.939 1.61-1.965 4.851-2.959 9.634-2.959h56.941c4.314 0 7.671 1.142 10.264 3.491 2.445 2.218 3.634 5.623 3.634 10.407z"
                        fill="#ffffff"
                      />
                    </g>
                    <path
                      d="m783.4 665.82h20.305c-1.2687 2.1116-1.9286 4.8256-1.9286 8.1009v25.497c0 2.4497 0.41555 4.5624 1.2057 6.3327h-19.582c-1.4862 0-2.6913 1.2052-2.6913 2.6913v10.348c0 2.2726 0.50005 4.3976 1.4867 6.3171 0.9479 1.8414 2.2268 3.4664 3.8013 4.832 1.5502 1.3419 3.3216 2.4109 5.267 3.1779 2.427 0.9592 9.5828 1.1788 10.579 1.1788h2.6418c1.9997 0 3.4923 0.70567 4.6975 2.2209 1.3398 1.6832 1.9911 3.7921 1.9911 6.4495v25.233c0 2.5029-0.57326 4.3546-1.7515 5.6615-1.1056 1.2235-2.721 1.8193-4.937 1.8193h-2.6418c-8.1122 0-20.106-1.5427-27.334-4.4908-7.3328-2.9928-13.907-7.1326-19.541-12.305-5.6244-5.1625-10.179-11.27-13.537-18.155-3.3254-6.8172-5.0118-14.198-5.0118-21.939v-10.348c0-1.4862-1.2052-2.6913-2.6913-2.6913h-13.607c-2.4394 0-4.3067-0.58617-5.7083-1.7924-1.2638-1.0873-1.9049-3.0014-1.9049-5.6884v-24.176c0-2.9933 0.66691-5.1302 1.9814-6.3516 1.3893-1.294 3.2312-1.9222 5.6319-1.9222h13.607c1.4862 0 2.6913-1.2046 2.6913-2.6913v-99.567c0-2.1192 0.68468-3.8658 2.0939-5.3396 1.3769-1.4409 3.2253-2.1412 5.6513-2.1412h28.536c2.054 0 3.917 0.66153 5.6976 2.0228 1.5771 1.2063 2.3119 2.9406 2.3119 5.458v99.567c0 1.4867 1.2052 2.6913 2.6913 2.6913"
                      fill="#ffffff"
                    />
                    <path
                      d="m900.49 666.5-16.456 40.267h-4.7879c-0.71482 0-1.4 0.2842-1.9055 0.79017-0.50489 0.50651-0.78748 1.1928-0.78587 1.9076l0.13188 51.94c0 2.5778-0.43277 4.6345-1.287 6.1056-0.57918 0.99472-1.997 1.5007-4.212 1.5007h-1.1228c-0.16956 0-0.35957-0.0172-0.56841-0.0511l-29.791-10.368c-3.0401-1.1212-5.4074-2.3049-6.99-3.502-0.70406-0.53235-1.6401-1.5265-1.6401-3.9966v-8e-3l-0.13241-41.636c-4e-3 -1.4829-1.2079-2.6827-2.6914-2.6827h-13.607c-5.3132 0-7.4808-2.1294-7.4808-7.349v-25.497c0-5.689 2.1676-8.0094 7.4808-8.0094h13.607c1.4851 0 2.6897-1.2025 2.6914-2.6876l0.13241-99.658c0-5.3176 2.1671-7.4852 7.4809-7.4852h29.857c2.7409 0 4.852 0.63946 6.2741 1.8996 1.3457 1.1928 1.9997 3.0191 1.9997 5.5818l-0.13188 99.655c-1e-3 0.71482 0.28259 1.4 0.78695 1.9055 0.50543 0.50544 1.1901 0.78964 1.9044 0.78964h18.099c1.1976 0 2.2376 0.19862 3.1456 0.58833"
                      fill="#ffffff"
                    />
                    <path
                      d="m1043.3 717.8c-0.6567 0.30574-2.0034 0.67176-4.6738 0.67176h-34.744c-2.0595 0-3.6678-0.34665-4.6518-1.0017-0.67391-0.44999-1.7192-1.5787-2.869-4.3885l-27.984-81.021c-0.36872-1.0674-1.3634-1.7908-2.4916-1.8124-1.1288-0.0339-2.1504 0.66261-2.56 1.7144-0.0964 0.24707-0.65076 1.7607-2.0072 5.4672-22.122 60.449-28.688 77.11-30.148 79.962-1.1659 0.72505-2.4949 1.0792-4.0446 1.0792h-33.82c-4.4391 0-5.1539-1.6595-5.3891-2.2048-0.77188-1.7903-0.45699-4.1457 0.93766-7.0023 0.0264-0.0533 0.0506-0.10765 0.0727-0.16255l60.903-149.02c0.51727-1.2945 1.2606-2.2596 2.2828-2.9707 0.55657-0.38809 2.0637-1.0367 5.9269-1.0367h15.721c1.9555 0 3.4148 0.35472 4.22 1.0265 1.0405 0.86608 1.9674 1.941 2.7586 3.1984l62.801 150.54c0.5426 1.3198 0.9355 2.5918 1.1621 3.7674 0.1631 0.84239 0.1287 1.5249-0.1065 2.0896-0.1712 0.40963-0.6083 0.78319-1.2962 1.1051"
                      fill="#ffffff"
                    />
                    <g transform="matrix(.53827 0 0 .53827 846.88 904.12)">
                      <path
                        d="m0 0c-242.89 0-440.5-198.41-440.5-442.3 0-243.88 197.61-442.3 440.5-442.3s440.5 198.41 440.5 442.3c0 243.88-197.61 442.3-440.5 442.3m0-969.59c-70.943 0-139.78 13.955-204.61 41.476-62.586 26.571-118.78 64.596-167.03 113.02-48.235 48.414-86.108 104.8-112.57 167.59-27.399 65.019-41.292 134.06-41.292 205.21 0 71.146 13.893 140.19 41.292 205.21 26.46 62.791 64.333 119.18 112.57 167.59 48.245 48.425 104.44 86.45 167.03 113.02 64.828 27.522 133.67 41.477 204.61 41.477 70.944 0 139.79-13.955 204.61-41.477 62.586-26.57 118.78-64.595 167.03-113.02 48.234-48.414 86.108-104.8 112.57-167.59 27.4-65.02 41.292-134.06 41.292-205.21 0-71.147-13.892-140.19-41.292-205.21-26.459-62.791-64.333-119.18-112.57-167.59-48.246-48.425-104.44-86.45-167.03-113.02-64.828-27.521-133.67-41.476-204.61-41.476"
                        fill="#ffffff"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </div>

            {/* <div className="col-12">
              <h2 className="fs-3 fontInter text-light test-start">
                Aqui a gente se vê
              </h2>
            </div> */}
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default React.memo(LogoAnimated);
