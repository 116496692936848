import React from "react";

declare global {
  interface Window {
    api?: any;
  }
}

export default class GoogleAds extends React.Component {
  componentDidMount() {
    if (window.api && window.api.adsbygoogle) {
      (window.api.adsbygoogle = window.api.adsbygoogle || []).push({});
    }
  }

  render() {
    return (
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-7292798487036853"
        data-ad-slot="2164752469"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    );
  }
}
