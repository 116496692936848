/* eslint-disable prefer-spread */
import React, { useEffect, useRef, useState } from "react";
import { banner } from "../../utilities/types";
import firstBannerImg from "../../media/imgs/banners/firstbanner.png";
import secondbanner from "../../media/imgs/banners/secondbanner.png";
import thirdbanner from "../../media/imgs/banners/thirdbanner.png";
import { animate, motion, useInView, useMotionValue } from "framer-motion";
import $ from "jquery";

let defaultValue = 0;

export const FirstBanner: React.FC<banner[]> = (props) => {
  const translateX = useMotionValue<number>(0);
  const translate2X = useMotionValue<number>(0);
  const showBanner = useMotionValue<number>(0);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const [onFinish, setOnFinish] = useState<boolean>(false);
  const [onFinish2, setOnFinish2] = useState<boolean>(false);
  const [takeOutAnimation, setTakeOutAnimmation] = useState<boolean>(false);

  const getBannerSize = () => {
    const bannerWidth = $("#id_first_banner").width();
    const bannerHeight = $("#id_first_banner").height();
    if (bannerWidth && bannerHeight) {
      const auxWidth = bannerWidth / 2;
      defaultValue = window.screen.width + auxWidth;
      translateX.set(defaultValue);
      translate2X.set(defaultValue);
      $("#id_first_banner_animation").width(bannerWidth);
      $("#id_first_banner_animation").height(bannerHeight);
    }
  };

  const animationView = () => {
    animate(translateX, 0, {
      duration: 1,
      onComplete: () => {
        setOnFinish(true);
      },
    });
    animate(translate2X, 0, {
      delay: 0.1,
      duration: 1,
      onComplete: () => {
        showBanner.set(1);
        setOnFinish2(true);
      },
    });
  };

  useEffect(() => {
    if (onFinish) {
      animate(translateX, defaultValue, {
        delay: 0.5,
        duration: 1,
        onComplete: () => setOnFinish(false),
      });
    }
    if (onFinish2) {
      animate(translate2X, defaultValue, {
        delay: 0.6,
        duration: 1,
        onComplete: () => {
          setOnFinish2(false);
          setTakeOutAnimmation(true);
        },
      });
    }
  }, [onFinish, onFinish2]);

  useEffect(() => {
    if (isInView) {
      getBannerSize();
      animationView();
    }
  }, [isInView]);

  // useEffect(() => {
  //   getBannerSize();
  // }, []);

  if (!props) {
    return <div></div>;
  }

  return (
    <div ref={ref} className="container mt-5 mb-5">
      <div className="row justify-content-center align-items-center">
        <div className="col-8 col-md-4">
          <div className="col-12 col-md-10">
            <img
              src={firstBannerImg}
              className="img-fluid"
              alt="mulher usando comupador e relaxando"
            />
          </div>
        </div>
        {props[0] && (
          <div className="col-12 col-md-8 row align-items-center">
            <a
              href={props[0]?.x_metadata?.link_banner || "#"}
              rel="noreferrer"
              target={"_blank"}
            >
              <div id="id_first_banner">
                <motion.div style={{ opacity: showBanner }}>
                  <img
                    src={props[0].x_featured_media_original}
                    className="img-fluid"
                    alt="banner de anuncio"
                  />
                </motion.div>
              </div>
            </a>
            {!takeOutAnimation && (
              <div
                id="id_first_banner_animation"
                className="position-absolute overflowHidden"
              >
                <motion.div
                  style={{ x: translateX }}
                  className="h-50 w-100 bg-primary"
                />
                <motion.div
                  style={{ x: translate2X }}
                  className="h-50 w-100 bg-primary"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

let defaultValue2 = 0;

export const SecondBanner: React.FC<banner[]> = (props) => {
  const translateX = useMotionValue<number>(0);
  const translate2X = useMotionValue<number>(0);
  const showBanner = useMotionValue<number>(0);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const [onFinish, setOnFinish] = useState<boolean>(false);
  const [onFinish2, setOnFinish2] = useState<boolean>(false);
  const [takeOutAnimation, setTakeOutAnimmation] = useState<boolean>(false);

  const getBannerSize = () => {
    const bannerWidth = $("#id_second_banner").width();
    const bannerHeight = $("#id_second_banner").height();
    const bannerWidth2 = $("#id_second_banner_2").width();
    const bannerHeight2 = $("#id_second_banner_2").height();
    if (bannerWidth && bannerHeight) {
      defaultValue2 = -bannerWidth * 1.5;
      translateX.set(defaultValue2);
      translate2X.set(defaultValue2);
      $("#id_second_banner_animation").width(bannerWidth);
      $("#id_second_banner_animation").height(bannerHeight);
    }
    if (bannerWidth2 && bannerHeight2) {
      defaultValue2 = -bannerWidth2 * 1.5;
      translateX.set(defaultValue2);
      translate2X.set(defaultValue2);
      $("#id_second_banner_animation_2").width(bannerWidth2);
      $("#id_second_banner_animation_2").height(bannerHeight2);
    }
  };

  const animationView = () => {
    animate(translateX, 0, {
      duration: 1,
      onComplete: () => {
        setOnFinish(true);
      },
    });
    animate(translate2X, 0, {
      delay: 0.1,
      duration: 1,
      onComplete: () => {
        showBanner.set(1);
        setOnFinish2(true);
      },
    });
  };

  useEffect(() => {
    if (onFinish) {
      animate(translateX, defaultValue2, {
        delay: 0.5,
        duration: 1,
        onComplete: () => setOnFinish(false),
      });
    }
    if (onFinish2) {
      animate(translate2X, defaultValue2, {
        delay: 0.6,
        duration: 1,
        onComplete: () => {
          setOnFinish2(false);
          setTakeOutAnimmation(true);
        },
      });
    }
  }, [onFinish, onFinish2]);

  useEffect(() => {
    if (isInView) {
      getBannerSize();
      animationView();
    }
  }, [isInView]);

  // useEffect(() => {
  //   getBannerSize();
  // }, []);

  if (!props) {
    return <div></div>;
  }

  return (
    <div ref={ref} className="container mt-5 mb-5">
      <div className="row justify-content-center align-items-center">
        <div className="col-8 row align-items-center d-none d-md-block">
          {!takeOutAnimation && (
            <div id="id_second_banner_animation" className="position-absolute">
              <motion.div
                style={{ x: translateX }}
                className="h-50 w-100 bg-primary"
              />
              <motion.div
                style={{ x: translate2X }}
                className="h-50 w-100 bg-primary"
              />
            </div>
          )}
          {props[0] && (
            <div id="id_second_banner">
              <a
                href={props[0]?.x_metadata?.link_banner || "#"}
                rel="noreferrer"
                target={"_blank"}
              >
                <motion.div style={{ opacity: showBanner }}>
                  <img
                    src={props[0].x_featured_media_original}
                    className="img-fluid"
                    alt="banner de anuncio"
                  />
                </motion.div>
              </a>
            </div>
          )}
        </div>

        <div className="col-8 col-md-4">
          <div className="col-12 col-md-9">
            <img
              src={secondbanner}
              className="img-fluid"
              alt="homem usando comupador e relaxando"
            />
          </div>
        </div>

        <div className="col-12 col-md-8 row align-items-center d-block d-md-none">
          {!takeOutAnimation && (
            <div
              id="id_second_banner_animation_2"
              className="position-absolute overflowHidden"
            >
              <motion.div
                style={{ x: translateX }}
                className="h-50 w-100 bg-primary"
              />
              <motion.div
                style={{ x: translate2X }}
                className="h-50 w-100 bg-primary"
              />
            </div>
          )}
          {props[0] && (
            <div id="id_second_banner_2">
              <a
                href={props[0]?.x_metadata?.link_banner || "#"}
                rel="noreferrer"
                target={"_blank"}
              >
                <motion.div style={{ opacity: showBanner }}>
                  <img
                    src={props[0].x_featured_media_original}
                    className="img-fluid"
                    alt="banner de anuncio"
                  />
                </motion.div>
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const ThirdBanner: React.FC<banner[]> = (props) => {
  const translateX = useMotionValue<number>(0);
  const translate2X = useMotionValue<number>(0);
  const showBanner = useMotionValue<number>(0);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const [onFinish, setOnFinish] = useState<boolean>(false);
  const [onFinish2, setOnFinish2] = useState<boolean>(false);
  const [takeOutAnimation, setTakeOutAnimmation] = useState<boolean>(false);

  const getBannerSize = () => {
    const bannerWidth = $("#id_third_banner").width();
    const bannerHeight = $("#id_third_banner").height();
    if (bannerWidth && bannerHeight) {
      const auxWidth = bannerWidth / 2;
      defaultValue = window.screen.width + auxWidth;
      translateX.set(defaultValue);
      translate2X.set(defaultValue);
      $("#id_third_banner_animation").width(bannerWidth);
      $("#id_third_banner_animation").height(bannerHeight);
    }
  };

  const animationView = () => {
    animate(translateX, 0, {
      duration: 1,
      onComplete: () => {
        setOnFinish(true);
      },
    });
    animate(translate2X, 0, {
      delay: 0.1,
      duration: 1,
      onComplete: () => {
        showBanner.set(1);
        setOnFinish2(true);
      },
    });
  };

  useEffect(() => {
    if (onFinish) {
      animate(translateX, defaultValue, {
        delay: 0.5,
        duration: 1,
        onComplete: () => setOnFinish(false),
      });
    }
    if (onFinish2) {
      animate(translate2X, defaultValue, {
        delay: 0.6,
        duration: 1,
        onComplete: () => {
          setOnFinish2(false);
          setTakeOutAnimmation(true);
        },
      });
    }
  }, [onFinish, onFinish2]);

  useEffect(() => {
    if (isInView) {
      getBannerSize();
      animationView();
    }
  }, [isInView]);

  // useEffect(() => {
  //   getBannerSize();
  // }, []);
  if (!props) {
    return <div></div>;
  }

  return (
    <div ref={ref} className="container mt-5 mb-5">
      <div className="row justify-content-center align-items-center">
        <div className="col-8 col-md-4">
          <div className="col-12 col-md-10">
            <img
              src={thirdbanner}
              className="img-fluid"
              alt="mulher usando comupador e relaxando"
            />
          </div>
        </div>
        <div className="col-12 col-md-8 row align-items-center">
          {props[0] && (
            <div id="id_third_banner">
              <a
                href={props[0]?.x_metadata?.link_banner || "#"}
                rel="noreferrer"
                target={"_blank"}
              >
                <motion.div style={{ opacity: showBanner }}>
                  <img
                    src={props[0].x_featured_media_original}
                    className="img-fluid"
                    alt="banner de anuncio"
                  />
                </motion.div>
              </a>
            </div>
          )}
          {!takeOutAnimation && (
            <div
              id="id_third_banner_animation"
              className="position-absolute overflowHidden"
            >
              <motion.div
                style={{ x: translateX }}
                className="h-50 w-100 bg-primary"
              />
              <motion.div
                style={{ x: translate2X }}
                className="h-50 w-100 bg-primary"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const UniqueNewsBanner: React.FC<banner[]> = (props) => {
  if (!props) {
    return <div></div>;
  }

  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-12 col-md-9">
          {props[0] && (
            <a
              href={props[0]?.x_metadata?.link_banner || "#"}
              rel="noreferrer"
              target={"_blank"}
            >
              <img
                src={props[0].x_featured_media_original}
                className="w-100"
                alt="banner de anuncio"
              />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
