import React, { useEffect, useState } from "react";
import AnimatedBackground from "../components/animations/TopBackgroundAnimation";
import Navbar from "../components/geral/NavBar";
import Cover from "../components/geral/Cover";
import Categories from "../components/geral/Categories";
import LauroDeFreitas from "../components/geral/LauroDeFreitas";
import { getData } from "../utilities/data/get_data";
import { newsItems, categoriesItems, banner } from "../utilities/types";
import {
  FirstBanner,
  SecondBanner,
  ThirdBanner,
} from "../components/animations/BannerAnimations";
import Camacari from "../components/geral/Camacari";
import Bahia from "../components/geral/Bahia";
import Sports from "../components/geral/Sports";
import GeneralNews from "../components/geral/GeneralNews";
import Footer from "../components/geral/Footer";
import { LoadingContent } from "../components/animations/LoadingPage";
import PopUp from "../components/geral/PopUp";
import GoogleAds from "../components/geral/GoogleAds";

const Home: React.FC = () => {
  const [coverData, setCoverData] = useState<newsItems[] | null>(null);
  const [lauroDeFreitasData, setLauroDeFreitasData] = useState<
    newsItems[] | null
  >(null);
  const [camacariData, setCamacariData] = useState<newsItems[] | null>(null);
  const [bahiaData, setBahiaData] = useState<newsItems[] | null>(null);
  const [sportsData, setSportsData] = useState<newsItems[] | null>(null);
  const [generalNewsData, setGeneralNewsData] = useState<newsItems[] | null>(
    null
  );
  const [categoriesData, setCategoriesData] = useState<
    categoriesItems[] | null
  >(null);
  const [bannerData, setBannerData] = useState<banner[] | null>(null);
  const [secondBannerData, setSecondBannerData] = useState<
    banner[] | null | boolean
  >(null);
  const [thirdBannerData, setThirdBannerData] = useState<
    banner[] | null | boolean
  >(null);
  const [fourthBannerData, setFourthBannerData] = useState<
    banner[] | null | boolean
  >(null);

  const [firstTimeCoverData, setFirstTimeCoverData] = useState<boolean>(true);
  const [firstTimeLauroDeFreitasData, setFirstTimeLauroDeFreitasData] =
    useState<boolean>(true);
  const [firstTimeCamacariData, setFirstTimeCamacariData] =
    useState<boolean>(true);
  const [firstTimeBahiaData, setFirstTimeBahiaData] = useState<boolean>(true);
  const [firstTimeSportsData, setFirstTimeSportsData] = useState<boolean>(true);
  const [firstTimeGeneralNewsData, setFirstTimeGeneralNewsData] =
    useState<boolean>(true);
  const [firstTimeCategoriesData, setFirstTimeCategoriesData] =
    useState<boolean>(true);
  const [firstTimeBannerData, setFirstTimeBannerData] = useState<boolean>(true);
  const [firstTimeSecondBannerData, setFirstTimeSecondBannerData] =
    useState<boolean>(true);
  const [firstTimeThirdBannerData, setFirstTimeThirdBannerData] =
    useState<boolean>(true);

  const getDataState = async () => {
    const auxCoverData: newsItems[] = await getData({
      dataToGet: "COVER",
    });
    if (auxCoverData) {
      setCoverData(auxCoverData);
    }
  };
  const getDataLauroDeFreitas = async () => {
    const auxLauroDeFreitasData: newsItems[] = await getData({
      dataToGet: "HOME_LAURO_DE_FREITAS",
    });
    if (auxLauroDeFreitasData) {
      setLauroDeFreitasData(auxLauroDeFreitasData);
    }
  };
  const getDataCamacari = async () => {
    const auxCamacariData: newsItems[] = await getData({
      dataToGet: "HOME_CAMACARI",
    });
    if (auxCamacariData) {
      setCamacariData(auxCamacariData);
    }
  };
  const getDataBahia = async () => {
    const auxBahiaData: newsItems[] = await getData({
      dataToGet: "HOME_BAHIA",
    });
    if (auxBahiaData) {
      setBahiaData(auxBahiaData);
    }
  };
  const getDataSports = async () => {
    const auxSportsData: newsItems[] = await getData({
      dataToGet: "HOME_SPORTS",
    });
    if (auxSportsData) {
      setSportsData(auxSportsData);
    }
  };
  const getDataGeneralNews = async () => {
    const auxGeneralNewsData: newsItems[] = await getData({
      dataToGet: "GENERAL_NEWS",
    });
    if (auxGeneralNewsData) {
      setGeneralNewsData(auxGeneralNewsData);
    }
  };
  const getDataCategories = async () => {
    const auxCategoriesData: categoriesItems[] = await getData({
      dataToGet: "ITEMS_CATEGORIES",
    });
    if (auxCategoriesData) {
      setCategoriesData(auxCategoriesData);
    }
  };

  const getDataBanner = async () => {
    const secondAuxBannerData: banner[] = await getData({
      dataToGet: "SECOND_BANNER",
    });
    if (secondAuxBannerData && !Array.isArray(secondAuxBannerData[0])) {
      setSecondBannerData(secondAuxBannerData);
    } else {
      if (secondBannerData === null) {
        setSecondBannerData(false);
      } else {
        setSecondBannerData(null);
      }
    }
  };
  const getSecondDataBanner = async () => {
    const thirdAuxBannerData: banner[] = await getData({
      dataToGet: "THIRD_BANNER",
    });
    if (thirdAuxBannerData && !Array.isArray(thirdAuxBannerData[0])) {
      setThirdBannerData(thirdAuxBannerData);
    } else {
      if (thirdBannerData === null) {
        setThirdBannerData(false);
      } else {
        setThirdBannerData(null);
      }
    }
  };

  const getThirdDataBanner = async () => {
    const fourthAuxBannerData: banner[] = await getData({
      dataToGet: "FOURTH_BANNER",
    });
    if (fourthAuxBannerData && !Array.isArray(fourthAuxBannerData[0])) {
      setFourthBannerData(fourthAuxBannerData);
    } else {
      if (fourthBannerData === null) {
        setFourthBannerData(false);
      } else {
        setFourthBannerData(null);
      }
    }
  };

  useEffect(() => {
    if (coverData && firstTimeCoverData) {
      getDataCategories();
      setFirstTimeCoverData(false);
    } else if (categoriesData && firstTimeCategoriesData) {
      getDataLauroDeFreitas();
      setFirstTimeCategoriesData(false);
    } else if (lauroDeFreitasData && firstTimeLauroDeFreitasData) {
      getDataBanner();
      setFirstTimeLauroDeFreitasData(false);
    } else if (secondBannerData && firstTimeBannerData) {
      getDataCamacari();
      setFirstTimeBannerData(false);
    } else if (camacariData && firstTimeCamacariData) {
      getSecondDataBanner();
      setFirstTimeCamacariData(false);
    } else if (thirdBannerData && firstTimeSecondBannerData) {
      getDataBahia();
      setFirstTimeSecondBannerData(false);
    } else if (bahiaData && firstTimeBahiaData) {
      getThirdDataBanner();
      setFirstTimeBahiaData(false);
    } else if (fourthBannerData && firstTimeThirdBannerData) {
      getDataSports();
      setFirstTimeThirdBannerData(false);
    } else if (sportsData && firstTimeSportsData) {
      getDataGeneralNews();
      setFirstTimeSportsData(false);
    } else if (generalNewsData && firstTimeGeneralNewsData) {
      setFirstTimeGeneralNewsData(false);
    }
  }, [
    coverData,
    lauroDeFreitasData,
    camacariData,
    bahiaData,
    sportsData,
    generalNewsData,
    categoriesData,
    secondBannerData,
    thirdBannerData,
    fourthBannerData,
  ]);

  useEffect(() => {
    if (!secondBannerData && !firstTimeBannerData) {
      getDataBanner();
    } else if (!thirdBannerData && !firstTimeSecondBannerData) {
      getSecondDataBanner();
    } else if (!fourthBannerData && !firstTimeThirdBannerData) {
      getThirdDataBanner();
    }
  }, [secondBannerData, thirdBannerData, fourthBannerData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getDataState();
  }, []);

  return (
    <div className="maxPageWidth">
      <AnimatedBackground />
      <Navbar />
      {coverData ? <Cover {...coverData} /> : <LoadingContent />}
      {categoriesData ? <Categories {...categoriesData} /> : <LoadingContent />}
      {lauroDeFreitasData ? (
        <LauroDeFreitas {...lauroDeFreitasData} />
      ) : (
        <LoadingContent />
      )}
      {lauroDeFreitasData && secondBannerData && secondBannerData !== true ? (
        <FirstBanner {...secondBannerData} />
      ) : (
        <LoadingContent />
      )}
      {camacariData ? <Camacari {...camacariData} /> : <LoadingContent />}
      {camacariData && thirdBannerData && thirdBannerData !== true ? (
        <SecondBanner {...thirdBannerData} />
      ) : (
        <LoadingContent />
      )}
      {bahiaData ? <Bahia {...bahiaData} /> : <LoadingContent />}
      {bahiaData && fourthBannerData && fourthBannerData !== true ? (
        <ThirdBanner {...fourthBannerData} />
      ) : (
        <LoadingContent />
      )}
      {sportsData ? <Sports {...sportsData} /> : <LoadingContent />}
      {generalNewsData ? (
        <GeneralNews {...generalNewsData} />
      ) : (
        <LoadingContent />
      )}
      <Footer />
      <GoogleAds />
    </div>
  );
};

export default Home;
