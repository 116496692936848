import React, { useEffect, useState } from "react";
import {
  currentPositionAnimation,
  goToVariations,
} from "../../utilities/types";
import backgroundplaces from "../../media/imgs/animated_top_background/backgroundplaces.png";
import brasilmap from "../../media/imgs/animated_top_background/brasilmap.png";
import footballField from "../../media/imgs/animated_top_background/backgroundsports.png";
import basketball from "../../media/imgs/animated_top_background/basketball.png";
import soccer from "../../media/imgs/animated_top_background/soccer.png";
import woman from "../../media/imgs/animated_top_background/woman.png";
import { variations } from "../../utilities/types";
import { animate, motion, useMotionValue } from "framer-motion";
import $ from "jquery";

let width = 0;
let height = 0;
let aspectRatio = 0;
let valImgsMarginX = 0;

let valBasketball = 0;
let valSoccer = 0;
let valWoman = 0;

let firstTimePass = true;

const BackgroundVariations: React.FC<variations> = ({
  currentPosition,
  goTo,
}) => {
  const [renderPlaces, setRenderPlaces] = useState<boolean>(false);
  const [renderSports, setRenderSports] = useState<boolean>(false);
  const prevPosition: currentPositionAnimation = { currentPosition: "MIDDLE" };

  const [valBackgroundX, setValBackgroundX] = useState<number>(0);
  const [animationToGo, setAnimationToGo] = useState<goToVariations>({
    goTo: "_",
  });
  const [triggerAnimation, setTriggerAnimation] = useState<goToVariations>({
    goTo: "_",
  });

  const [backgroundPosition, setBackgroundPosition] =
    useState<currentPositionAnimation>({ currentPosition: "LEFT" });

  const [backgroundImg, setBackgroundImg] = useState<string>(backgroundplaces);
  const backgroundX = useMotionValue<number>(200);
  const backgroundOpacity = useMotionValue<number>(0);
  const imgsMarginX = useMotionValue<number>(0);
  const imgsMarginY = useMotionValue<number>(0);
  const imgsOpacity = useMotionValue<number>(0);

  const imgsBasketballY = useMotionValue<number>(0);
  const imgsSoccerY = useMotionValue<number>(0);
  const imgsWomanY = useMotionValue<number>(0);
  const imgsBasketballX = useMotionValue<number>(0);
  const imgsSoccerX = useMotionValue<number>(0);
  const imgsWomanX = useMotionValue<number>(0);

  const side = useMotionValue<number>(1);

  const renderPlacesFunc = () => {
    if (goTo !== "PLACES") {
      animate(imgsOpacity, 0, {
        duration: 1,
        ease: "easeInOut",
      });
      animate(imgsMarginX, 0, {
        duration: 1,
        ease: "easeInOut",
      });
      animate(backgroundX, 200, {
        duration: 0.5,
        delay: 0.5,
        ease: "easeInOut",
        onComplete: () => {
          setRenderPlaces(false);
          if (goTo === "SPORTS") {
            defineSportsVariablesValues();
          }
        },
      });
      animate(backgroundOpacity, 0, {
        duration: 0.5,
        delay: 0.5,
        ease: "easeInOut",
      });
    } else {
      setRenderSports(false);

      animate(imgsOpacity, 1, {
        delay: 1.5,
        duration: 0.5,
        ease: "easeInOut",
      });
      animate(imgsMarginX, valImgsMarginX, {
        duration: 1,
        delay: 1.5,
        ease: "easeInOut",
      });
      animate(backgroundX, valBackgroundX, {
        duration: 1,
        delay: 1,
        ease: "easeInOut",
      });
      animate(backgroundOpacity, 1, {
        duration: 1,
        delay: 1,
        ease: "easeInOut",
      });
    }
  };

  const renderSportsFunc = () => {
    if (goTo !== "SPORTS") {
      animate(imgsBasketballX, width + 100, {
        duration: 1,
        // type: "spring",
      });
      animate(imgsSoccerX, width + 100, {
        duration: 1,
        // type: "spring",
      });
      animate(imgsWomanX, width + 100, {
        duration: 1,
        // type: "spring",
      });
      animate(backgroundX, -200, {
        duration: 0.5,
        delay: 1.2,
        ease: "easeInOut",
        onComplete: () => {
          setRenderSports(false);
          if (goTo === "PLACES") {
            defineVariablesValues();
          }
        },
      });
      animate(backgroundOpacity, 0, {
        duration: 0.5,
        delay: 1,
        ease: "easeInOut",
      });
    } else {
      setRenderPlaces(false);

      animate(imgsBasketballX, valBasketball, {
        duration: 2,
        delay: 1.5,
        type: "spring",
      });
      animate(imgsSoccerX, valSoccer, {
        duration: 2,
        delay: 1.5,
        type: "spring",
      });
      animate(imgsWomanX, valWoman, {
        duration: 2,
        delay: 1.7,
        type: "spring",
      });
      animate(backgroundX, 0, {
        duration: 1,
        delay: 1,
        ease: "easeInOut",
      });
      animate(backgroundOpacity, 1, {
        duration: 1,
        delay: 1,
        ease: "easeInOut",
      });
    }
  };

  const triggerPlaceAnimation = () => {
    setRenderPlaces(true);
    if (currentPosition === "LEFT") {
      if (width > 768) {
        valImgsMarginX = width * 0.3;
      } else {
        valImgsMarginX = 0;
      }
      setValBackgroundX(0);
      renderPlacesFunc();
    }
  };

  const defineVariablesValues = () => {
    setBackgroundImg(backgroundplaces);
    setBackgroundPosition({ currentPosition: "LEFT" });
    if (goTo === "PLACES" && !renderPlaces) {
      backgroundX.set(200);
      if (width > 768) {
        imgsMarginX.set(0);
      } else {
        imgsMarginX.set(-width);
      }
    }
    triggerPlaceAnimation();
  };

  const takeOutLayout = () => {
    if (goTo !== "PLACES" && renderPlaces) {
      // Un know bug on value valImgsMarginX
      // ********************************* Check it Out ***************************************
      // setValBackgroundX(200);
      if (width > 768) {
        animate(imgsMarginX, 0, {
          duration: 1,
          ease: "easeInOut",
        });
      } else {
        valImgsMarginX = -width;
      }
      renderPlacesFunc();
    } else if (goTo !== "SPORTS" && renderSports) {
      renderSportsFunc();
    }
  };

  const triggerSportsAnimations = () => {
    renderSportsFunc();
    setRenderSports(true);
  };

  const defineSportsVariablesValues = () => {
    setBackgroundImg(footballField);

    if (
      currentPosition === "MIDDLE" &&
      prevPosition.currentPosition === "RIGHT"
    ) {
      backgroundX.set(200);
    } else if (prevPosition.currentPosition === "LEFT") {
      setBackgroundPosition({ currentPosition: "RIGHT" });
      imgsBasketballX.set(width + 100);
      imgsSoccerX.set(width + 100);
      imgsWomanX.set(width + 100);
      backgroundX.set(-200);
      if (width <= 768) {
        valBasketball = width * 0.45;
        valSoccer = width * 0.5;
        valWoman = width * 0.06;
      } else if (aspectRatio > 1.66) {
        valBasketball = width * 0.5;
        valSoccer = width * 0.5;
        valWoman = width * 0.3;
      } else {
        valBasketball = width * 0.5;
        valSoccer = width * 0.5;
        valWoman = width * 0.25;
      }
      setTriggerAnimation({ goTo: "SPORTS" });
    }
  };

  const defineStaticPositions = () => {
    if (width <= 768) {
      imgsMarginY.set(height * 0.7);
      imgsBasketballY.set(height * 0.75);
      imgsSoccerY.set(height * 0.55);
      imgsWomanY.set(height * 0.62);
    } else if (aspectRatio > 1.66) {
      imgsMarginY.set(height * 0.4);
      imgsBasketballY.set(height * 0.45);
      imgsSoccerY.set(5);
      imgsWomanY.set(10);
    } else {
      imgsMarginY.set(height * 0.5);
      imgsBasketballY.set(height * 0.55);
      imgsSoccerY.set(height * 0.06);
      imgsWomanY.set(height * 0.13);
    }
  };

  useEffect(() => {
    // if (currentPosition === "LEFT") {
    //   side.set(1);
    //   prevPosition.currentPosition = "RIGHT";
    // } else if (currentPosition === "RIGHT") {
    //   side.set(-1);
    //   prevPosition.currentPosition = "LEFT";
    // }
    if (width === 0 || height === 0) {
      const auxWidth = $(window).width();
      const auxHeight = $(window).height();
      if (auxHeight && auxWidth) {
        height = auxHeight;
        width = auxWidth;
        aspectRatio = auxWidth / auxHeight;
      }
    }
    if (!firstTimePass) {
      firstTimePass = true;
    }
    defineStaticPositions();
  }, []);

  useEffect(() => {
    // console.log("changed-position");
    if (currentPosition === "LEFT") {
      side.set(1);
      prevPosition.currentPosition = "RIGHT";
    } else if (currentPosition === "RIGHT") {
      side.set(-1);
      prevPosition.currentPosition = "LEFT";
    }
    if (currentPosition === "MIDDLE") {
      takeOutLayout();
    } else if (firstTimePass) {
      setTriggerAnimation({ goTo: "PLACES" });
      defineVariablesValues();
      firstTimePass = false;
    } else if (goTo === "PLACES") {
      defineVariablesValues();
    } else if (goTo === "SPORTS") {
      setAnimationToGo({ goTo: "SPORTS" });
      defineSportsVariablesValues();
    }
  }, [currentPosition, goTo]);

  useEffect(() => {
    if (triggerAnimation.goTo === "SPORTS") {
      triggerSportsAnimations();
    } else {
      triggerPlaceAnimation();
    }
  }, [triggerAnimation]);

  return (
    <div>
      <div className="position-absolute overflowHidden maxPageWidth overflowhiddenY">
        <motion.div
          className="row switch"
          current-position={backgroundPosition.currentPosition}
        >
          <motion.div
            style={{ x: backgroundX, opacity: backgroundOpacity, scaleX: side }}
            className="col-12 col-md-7"
          >
            <motion.img
              className="img-fluid opacity-50 backgroundImg w-100 "
              src={backgroundImg}
              alt="Imagem de Fundo"
              // height="100vh"
            />
          </motion.div>
        </motion.div>
      </div>

      {renderPlaces && (
        <div>
          <div className="position-absolute col-9 col-md-4">
            <motion.div
              className="col-12 col-xl-11"
              style={{ x: imgsMarginX, y: imgsMarginY, opacity: imgsOpacity }}
            >
              <img
                className="img-fluid"
                src={brasilmap}
                alt="Mapa do Brasil, Bahia destacado"
              />
            </motion.div>
          </div>
        </div>
      )}
      {renderSports && (
        <div className="overflowHidden maxPageWidth">
          <motion.div
            style={{ x: imgsBasketballX, y: imgsBasketballY, scaleX: side }}
            className="position-absolute col-6 col-md-3"
          >
            <div className="col-12 col-xl-11">
              <img
                className="img-fluid"
                src={basketball}
                alt="jogador de basquete"
              />
            </div>
          </motion.div>
          <motion.div
            style={{ x: imgsSoccerX, y: imgsSoccerY, scaleX: side }}
            className="position-absolute col-6 col-md-3"
          >
            <div className="col-12 col-xl-10">
              <img
                className="img-fluid"
                src={soccer}
                alt="jogador de futebol"
              />
            </div>
          </motion.div>
          <motion.div
            style={{ x: imgsWomanX, y: imgsWomanY, scaleX: side }}
            className="position-absolute col-8 col-md-5 col-xl-4"
          >
            <div className="col-11 col-xl-12">
              <img className="img-fluid" src={woman} alt="mulher atleta" />
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
};

export default React.memo(BackgroundVariations);
