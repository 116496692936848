import React, { useEffect, useState } from "react";
import { Modal } from "bootstrap";
import { pop_up } from "../../utilities/types";
import { getData } from "../../utilities/data/get_data";
import { LoadingContent } from "../animations/LoadingPage";
// import bootstrap from "bootstrap";

// import { Container } from './styles';

const PopUp: React.FC = () => {
  const [bannerData, setBannerData] = useState<pop_up[] | null | boolean>(null);
  const [firtPass, setFirtPass] = useState<boolean>(false);
  const getDataBanner = async () => {
    const auxBannerData: pop_up[] = await getData({
      dataToGet: "POP_UP",
    });
    if (auxBannerData) {
      setBannerData(auxBannerData);
    } else {
      if (bannerData === null) {
        setBannerData(false);
      } else {
        setBannerData(null);
      }
    }
  };

  useEffect(() => {
    if (
      bannerData &&
      bannerData !== true &&
      bannerData[0]?.x_metadata?.ativado == "sim" &&
      !firtPass
    ) {
      const myModal = new Modal("#id_pop_up", {
        keyboard: false,
      });
      myModal.show();
      const myModalEl = document.getElementById("id_pop_up");
      myModalEl?.addEventListener("hidden.bs.modal", () => {
        // do something...
        myModal.hide();
      });
      setFirtPass(true);
    } else if (!bannerData) {
      getDataBanner();
    }
  }, [bannerData]);

  if (!bannerData) {
    return <div></div>;
  }

  return (
    <div className="maxPageWidth">
      <div
        className="modal fade"
        id="id_pop_up"
        tabIndex={-1}
        aria-labelledby="modal_pop_up"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered maxPageWidth">
          <div className="modal-content backgroundTransparent">
            <div className="modal-body text-end">
              <button
                type="button"
                className="btn-close bg-light"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              {bannerData && bannerData !== true ? (
                <a
                  href={bannerData[0]?.x_metadata?.link_banner || "#"}
                  rel="noreferrer"
                  target={"_blank"}
                >
                  <img
                    src={bannerData[0]?.x_featured_media_original}
                    className="img-fluid w-100"
                    alt=""
                  />
                </a>
              ) : (
                <LoadingContent />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUp;
