import React, { useEffect, useState } from "react";
import {
  currentPositionAnimation,
  goToVariations,
  pageToLink,
} from "../../utilities/types";
import { variations } from "../../utilities/types";
import { animate, motion, useMotionValue } from "framer-motion";
import $ from "jquery";
import { Link } from "react-router-dom";

let width = 0;
let height = 0;
let aspectRatio = 0;
let valBtnContentY = 0;
const prevPosition: currentPositionAnimation = { currentPosition: "RIGHT" };
let firstTimePass = true;

const LINK_TO_GENERAL_NEWS: pageToLink = {
  id: "GET_GENERAL_NEWS",
  to: "categorias/NoticiarioGeral",
  name: "Noticiário Geral",
};
const LINK_TO_SPORTS_NEWS: pageToLink = {
  id: "SPORTS_NEWS_NAVIGATE",
  to: "categorias/ConteudoEsportivo",
  name: "Conteúdo Esportivo",
};

const BtnLabelContent: React.FC<variations> = ({ currentPosition, goTo }) => {
  const [animationToGo, setAnimationToGo] = useState<goToVariations>({
    goTo: "_",
  });
  const [triggerAnimation, setTriggerAnimation] = useState<boolean>(false);

  const [content, setContent] = useState<string[]>(["Noticiário", "Geral"]);
  const placesContent = ["Noticiário", "Geral"];
  const sportsContent = ["Conteúdo", "Esportivo"];
  const [currentStatus, setCurrentStatus] = useState<
    "LEAVING" | "REACHING" | null
  >(null);
  const btnContentLabelX = useMotionValue<number>(0);
  const btnContentLabelY = useMotionValue<number>(0);
  const btnContentLabelOpacity = useMotionValue<number>(0);
  const [pageToLink, setPageToLink] =
    useState<pageToLink>(LINK_TO_GENERAL_NEWS);

  const renderPlacesFunc = () => {
    if (currentStatus === "LEAVING") {
      animate(btnContentLabelOpacity, 0, {
        duration: 1,
        ease: "easeInOut",
      });
      animate(btnContentLabelY, 0, {
        duration: 1,
        ease: "easeInOut",
        onComplete: () => {
          if (goTo === "PLACES") {
            setContent(placesContent);
            setPageToLink(LINK_TO_GENERAL_NEWS);
          } else {
            setContent(sportsContent);
            setPageToLink(LINK_TO_SPORTS_NEWS);
          }
          setCurrentStatus("REACHING");
          setTriggerAnimation(true);
        },
      });
    } else {
      animate(btnContentLabelOpacity, 1, {
        duration: 0.5,
        delay: 2,
        ease: "easeInOut",
      });
      animate(btnContentLabelY, valBtnContentY, {
        duration: 1,
        delay: 2,
        ease: "easeInOut",
        onComplete: () => {
          setCurrentStatus("LEAVING");
          setTriggerAnimation(false);
          setAnimationToGo({ goTo: "_" });
        },
      });
    }
  };

  const defineVariablesValues = () => {
    if (width <= 768) {
      valBtnContentY = height * 0.08;
    } else if (aspectRatio > 1.66) {
      valBtnContentY = height * 0.15;
    } else {
      valBtnContentY = height * 0.2;
    }
    if (prevPosition.currentPosition === "RIGHT") {
      if (width > 768) {
        btnContentLabelX.set(width * 0.65);
      } else {
        btnContentLabelX.set(width * 0.05);
      }
      btnContentLabelY.set(0);
    } else if (prevPosition.currentPosition === "LEFT") {
      if (width > 768) {
        btnContentLabelX.set(width * 0.2);
      } else {
        btnContentLabelX.set(width * 0.05);
      }
    }
    if (goTo === "PLACES") {
      setContent(placesContent);
    } else {
      setContent(sportsContent);
    }
    renderPlacesFunc();
  };

  useEffect(() => {
    if (currentPosition === "LEFT") {
      prevPosition.currentPosition = "RIGHT";
    } else if (currentPosition === "RIGHT") {
      prevPosition.currentPosition = "LEFT";
    }
    if (width === 0 || height === 0) {
      const auxWidth = $(window).width();
      const auxHeight = $(window).height();
      if (auxHeight && auxWidth) {
        height = auxHeight;
        width = auxWidth;
        aspectRatio = auxWidth / auxHeight;
      }
    }
    if (firstTimePass) {
      firstTimePass = false;
      defineVariablesValues();
    } else if (currentPosition === "MIDDLE" && currentStatus === "LEAVING") {
      valBtnContentY = 0;
      renderPlacesFunc();
    } else {
      defineVariablesValues();
    }
  }, [currentPosition, goTo]);

  useEffect(() => {
    if (triggerAnimation) {
      defineVariablesValues();
    }
  }, [triggerAnimation]);

  return (
    <div>
      <motion.div
        className="col-md-2 col-12"
        style={{
          x: btnContentLabelX,
          y: btnContentLabelY,
          opacity: btnContentLabelOpacity,
        }}
      >
        <div className="row justify-content-center align-items-center">
          <div className="fontCinzel fs-3 text-light text-center">
            {content[0]}
          </div>
          <div className="fontBebasNeue fs-1 text-light text-center ">
            {content[1]}
          </div>
          <Link
            to={pageToLink.to}
            state={{ id: pageToLink.id, name: pageToLink.name }}
            className="text-decoration-none row justify-content-center align-items-center"
          >
            <button className="col-6 col-md-12 button">
              <span className="txtBtn fontCinzel text-white">Acessar</span>
            </button>
          </Link>
        </div>
      </motion.div>
    </div>
  );
};

export default React.memo(BtnLabelContent);
