import React, { useEffect, useRef, useState } from "react";
import { newsItems } from "../../utilities/types";
import {
  getAllTitlesSeparated,
  refineSubtitle,
} from "../../utilities/functions";
import $ from "jquery";
import { Link } from "react-router-dom";
import { animate, motion, useInView, useMotionValue } from "framer-motion";
import { REPLACE_URL } from "../../utilities/constraints";
import DOMPurify from "isomorphic-dompurify";
import Logo from "../../media/svgs/logoMobile.svg";

let newsHeight = 0;
let newIn = true;

const variantsItems = {
  default: {
    backgroundColor: "#027093",
    scale: 1,
    transition: { duration: 1 },
  },
  selected: {
    backgroundColor: "#076adb",
    scale: 1.1,
    transition: { duration: 1 },
  },
};

const Cover: React.FC<newsItems[]> = (props) => {
  const [data, setDataLoaded] = useState<newsItems[]>(props);
  const [titles, setTitles] = useState<string[][] | null>(null);
  const [currentNews, setCurrentNews] = useState<number>(0);
  const [animateOutTransition, setAnimateOutTransition] =
    useState<boolean>(false);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const timmer = useRef<null | NodeJS.Timeout>(null);

  const translateTransitionY = useMotionValue<number>(0);
  const translateNewsY = useMotionValue<number>(0);

  const animateOut = () => {
    animate(translateTransitionY, -newsHeight - 1, {
      delay: 0.5,
      duration: 1,
      onComplete: () => {
        setAnimateOutTransition(false);
        setTimeout(() => {
          animateTransition();
        }, 5000);
      },
    });
  };

  const animateTransition = () => {
    if (newIn) {
      animate(translateTransitionY, -1, {
        duration: 1,
        onComplete: () => {
          translateNewsY.set(0);
          setAnimateOutTransition(true);
        },
      });
      newIn = false;
    } else {
      translateTransitionY.set(newsHeight + 1);
      newIn = true;
      animate(translateNewsY, newsHeight - 1, {
        duration: 1,
        onComplete: () => {
          if (currentNews < 3) {
            setCurrentNews((prevNumber) => prevNumber + 1);
          } else {
            setCurrentNews(0);
          }
          animateTransition();
        },
      });
    }
  };

  const defineSmallCardSize = () => {
    const animatedTop = $("#id_main_cover").offset();
    const animatedDivWidth = $("#id_main_cover").width();
    const animatedDivHeight = $("#id_main_cover").height();

    if (animatedTop) $("#id_animation_div").offset(animatedTop);
    if (animatedDivHeight && animatedDivWidth) {
      $("#id_animation_div").width(animatedDivWidth);
      $("#id_animation_div").height(animatedDivHeight);
      newsHeight = animatedDivHeight;
      translateNewsY.set(animatedDivHeight + 1);
      translateTransitionY.set(animatedDivHeight + 1);
    }
    const maxHeight = $(".divCardCotent")
      .map((i, element) => {
        return $(element).height();
      })
      .get();
    $(".divCardCotent").height(Math.max.apply(null, maxHeight));
  };

  useEffect(() => {
    if (animateOutTransition) {
      animateOut();
    }
  }, [animateOutTransition]);

  useEffect(() => {
    if (isInView) {
      animateTransition();
    }
  }, [isInView]);

  useEffect(() => {
    defineSmallCardSize();
  }, [titles]);

  useEffect(() => {
    // const newData: newsItems[] | void = refineSubtitle(props);
    setDataLoaded(props);
    const newTitles: string[][] = getAllTitlesSeparated(data);
    setTitles(newTitles);
  }, []);

  return (
    <div ref={ref} className="backgroundCover pb-5">
      {titles && data && (
        <div className="container pb-5">
          <div className="row justify-content-center align-items-center">
            <h3 className="text-light fontInter mt-4 text-center">Destaques</h3>
            <div className="hrGradient mb-3" />
            <div className="containerCards">
              <Link
                to={data[currentNews].link.replace(REPLACE_URL, "")}
                state={{ data: data[currentNews] }}
                className="d-none d-md-block text-decoration-none"
              >
                <div id="id_main_cover" className="divCards overflow-hidden">
                  <motion.div style={{ y: translateNewsY }} layout>
                    <div className="divImgCard cardContentMainNews">
                      <img
                        src={data[currentNews].jetpack_featured_media_url}
                        className="cardImgFit"
                        alt="Destaque"
                      />
                    </div>
                    <div className="text-light p-3 cardContentMainNews">
                      <span
                        className="fontCinzel fw-bold"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            data[currentNews].title.rendered
                          ),
                        }}
                      />
                      {/* <span className="fontCinzel fw-bold">
                        {data[currentNews].title.rendered}
                      </span> */}
                      {/* <span className="fontInter">
                        {titles[currentNews][1]}
                      </span> */}
                    </div>
                  </motion.div>
                </div>
                <div
                  id="id_animation_div"
                  className="position-absolute overflow-hidden"
                >
                  <motion.div
                    layout
                    className="bg-primary w-100 h-100"
                    style={{ y: translateTransitionY }}
                  >
                    {/* <div className="row align-items-center h-100 text-center text-white">
                      <div>
                        <h3 className="display-4">{titles[currentNews][0]}</h3>
                      </div>
                    </div> */}
                    <div className="row align-items-center justify-content-center h-100 text-center text-white">
                      <div className="col-6 col-md-4 col-lg-4 mb-5 mt-5">
                        <img src={Logo} className="img-fluid" alt="logo LFTV" />
                      </div>
                    </div>
                  </motion.div>
                </div>
              </Link>

              {/* ************************* Bottom cards ***************************** */}

              <div className="row justify-content-between mt-3">
                {Object.keys(data).map((_, index) => (
                  <div key={index} className="divCards divSmallCard pt-3 pb-3">
                    <Link
                      to={data[index].link.replace(REPLACE_URL, "")}
                      state={{ data: data[index] }}
                      className="text-decoration-none"
                    >
                      <motion.div
                        initial="default"
                        animate={index === currentNews ? "selected" : "default"}
                        variants={variantsItems}
                        className="cardBoderCover w-100"
                      >
                        <div className="divImgSmallCard w-100">
                          <img
                            src={data[index].jetpack_featured_media_url}
                            className="cardImgFitSmalll"
                            alt="Destaque"
                          />
                        </div>
                        <div className="divCardCotent text-light  text-center pb-3 ps-1 pe-1">
                          <span
                            className="fontCinzel"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                data[index].title.rendered
                              ),
                            }}
                          />
                          {/* <span className="fontCinzel">{titles[index][0]}</span>
                          <div className="mb-3" />
                          <span className="fontInter">{titles[index][1]}</span> */}
                        </div>
                      </motion.div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cover;
