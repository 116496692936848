/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  dataToGet,
  categoriesItems,
  newsItems,
  banner,
  relatedNews,
  commentsItems,
  author,
} from "../types";
import {
  API_URL,
  COVER,
  HOME_BAHIA,
  HOME_CAMACARI,
  HOME_SPORTS,
  HOME_LAURO_DE_FREITAS,
  ITEMS_CATEGORIES,
  GENERAL_NEWS,
  RELATED_NEWS,
  GET_NEWS,
  COMMENTS,
  COMMENTS_FIELDS,
  SEARCH,
  GENERAL_NEWS_NAVIGATE,
  SEARCH_FIELDS_NAVIGATE,
  PAGE_PER_CATEGORY,
  PAGE_PER_CATEGORY_NAVIGATE,
  POP_UP,
  SPORTS_NEWS_NAVIGATE,
  AUTHOR,
  AUTHOR_FIELDS,
  FIRST_BANNER,
  SECOND_BANNER,
  THIRD_BANNER,
  FOURTH_BANNER,
} from "../constraints";

export const getData = async (
  { dataToGet }: dataToGet,
  content?: number | string,
  offset?: number | string
) => {
  if (dataToGet === "COVER") {
    try {
      const response = await fetch(API_URL + COVER);
      const json: newsItems[] | any = await response.json();
      return json;
    } catch (error) {
      console.error(error);
    }
  }
  if (dataToGet === "HOME_LAURO_DE_FREITAS") {
    try {
      const response = await fetch(API_URL + HOME_LAURO_DE_FREITAS);
      const json: newsItems[] | any = await response.json();
      return json;
    } catch (error) {
      console.error(error);
    }
  }
  if (dataToGet === "HOME_CAMACARI") {
    try {
      const response = await fetch(API_URL + HOME_CAMACARI);
      const json: newsItems[] | any = await response.json();
      return json;
    } catch (error) {
      console.error(error);
    }
  }
  if (dataToGet === "HOME_BAHIA") {
    try {
      const response = await fetch(API_URL + HOME_BAHIA);
      const json: newsItems[] | any = await response.json();
      return json;
    } catch (error) {
      console.error(error);
    }
  }
  if (dataToGet === "HOME_SPORTS") {
    try {
      const response = await fetch(API_URL + HOME_SPORTS);
      const json: newsItems[] | any = await response.json();
      return json;
    } catch (error) {
      console.error(error);
    }
  }
  if (dataToGet === "GENERAL_NEWS") {
    try {
      const response = await fetch(API_URL + GENERAL_NEWS);
      const json: newsItems[] | any = await response.json();
      return json;
    } catch (error) {
      console.error(error);
    }
  }
  if (dataToGet === "GENERAL_NEWS_NAVIGATE") {
    try {
      const response = await fetch(API_URL + GENERAL_NEWS_NAVIGATE + content);
      const json: newsItems[] | any = await response.json();
      return json;
    } catch (error) {
      console.error(error);
    }
  }
  if (dataToGet === "ITEMS_CATEGORIES") {
    try {
      const response = await fetch(API_URL + ITEMS_CATEGORIES);
      const json: categoriesItems[] | any = await response.json();
      return json;
    } catch (error) {
      console.error(error);
    }
  }

  if (dataToGet === "FIRST_BANNER") {
    try {
      const response = await fetch(API_URL + FIRST_BANNER);
      const json: banner[] | any = await response.json();
      return json;
    } catch (error) {
      console.error(error);
    }
  }
  if (dataToGet === "SECOND_BANNER") {
    try {
      const response = await fetch(API_URL + SECOND_BANNER);
      const json: banner[] | any = await response.json();
      return json;
    } catch (error) {
      console.error(error);
    }
  }
  if (dataToGet === "THIRD_BANNER") {
    try {
      const response = await fetch(API_URL + THIRD_BANNER);
      const json: banner[] | any = await response.json();
      return json;
    } catch (error) {
      console.error(error);
    }
  }
  if (dataToGet === "FOURTH_BANNER") {
    try {
      const response = await fetch(API_URL + FOURTH_BANNER);
      const json: banner[] | any = await response.json();
      return json;
    } catch (error) {
      console.error(error);
    }
  }

  if (dataToGet === "POP_UP") {
    try {
      const response = await fetch(API_URL + POP_UP);
      const json: banner[] | any = await response.json();
      return json;
    } catch (error) {
      console.error(error);
    }
  }
  if (dataToGet === "RELATED_NEWS") {
    try {
      const response = await fetch(
        API_URL + "/posts/" + content + RELATED_NEWS
      );
      const json: relatedNews | any = await response.json();
      return json;
    } catch (error) {
      console.error(error);
    }
  }
  if (dataToGet === "GET_NEWS") {
    try {
      const response = await fetch(API_URL + GET_NEWS + content);
      const json: relatedNews | any = await response.json();
      return json;
    } catch (error) {
      console.error(error);
    }
  }
  if (dataToGet === "COMMENTS") {
    try {
      const response = await fetch(
        API_URL + COMMENTS + content + COMMENTS_FIELDS
      );
      const json: commentsItems[] | any = await response.json();
      return json;
    } catch (error) {
      console.error(error);
    }
  }
  if (dataToGet === "SEARCH") {
    try {
      const response = await fetch(API_URL + SEARCH + content);
      const json: newsItems[] | any = await response.json();
      return json;
    } catch (error) {
      console.error(error);
    }
  }
  if (dataToGet === "SEARCH_FIELDS_NAVIGATE") {
    try {
      const response = await fetch(
        API_URL + SEARCH + content + SEARCH_FIELDS_NAVIGATE + offset
      );
      const json: newsItems[] | any = await response.json();
      return json;
    } catch (error) {
      console.error(error);
    }
  }
  if (dataToGet === "AUTHOR") {
    try {
      const header = {
        headers: {
          mode: "same-origin",
        },
      };
      const response = await fetch(
        API_URL + AUTHOR + 21 + AUTHOR_FIELDS,
        header
      );
      const json: author | any = await response.json();
      console.log(json);
      return json;
    } catch (error) {
      console.error(error);
    }
  }
  if (dataToGet === "PAGE_PER_CATEGORY") {
    if (content === "GET_GENERAL_NEWS") {
      try {
        const response = await fetch(API_URL + GENERAL_NEWS_NAVIGATE + offset);
        const json: newsItems[] | any = await response.json();
        return json;
      } catch (error) {
        console.error(error);
      }
    } else if (content === "SPORTS_NEWS_NAVIGATE") {
      try {
        const response = await fetch(API_URL + SPORTS_NEWS_NAVIGATE + offset);
        const json: newsItems[] | any = await response.json();
        return json;
      } catch (error) {
        console.error(error);
      }
    } else {
      try {
        const response = await fetch(
          API_URL +
            PAGE_PER_CATEGORY +
            content +
            PAGE_PER_CATEGORY_NAVIGATE +
            offset
        );
        const json: newsItems[] | any = await response.json();
        return json;
      } catch (error) {
        console.error(error);
      }
    }
  }
};
