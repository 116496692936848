import React, { useEffect } from "react";
import { relatedNews } from "../../utilities/types";
import $ from "jquery";
import { Link } from "react-router-dom";
import { REPLACE_URL } from "../../utilities/constraints";

// import { Container } from './styles';

const Related: React.FC<relatedNews> = (props) => {
  const defineSmallCardSize = () => {
    const maxHeight = $(".divCardCotent")
      .map((i, element) => {
        return $(element).height();
      })
      .get();
    $(".divCardCotent").height(Math.max.apply(null, maxHeight));
  };

  useEffect(() => {
    defineSmallCardSize();
  }, []);

  const relatedNews = props["jetpack-related-posts"];

  if (!relatedNews) return <div />;

  return (
    <div className="row justify-content-center">
      <h6 className="display-5 fs-2 text-center">Notícias relacionadas</h6>
      <div className="containerCards">
        <div className="row justify-content-between mt-3">
          {relatedNews.map((item) => (
            <div
              key={item.id}
              className="divSmallCardRelatedNews pt-3 pb-3 divCards"
            >
              <Link
                to={item.url.replace(REPLACE_URL, "")}
                reloadDocument={true}
                className="text-decoration-none"
              >
                <div className="cardBoder w-100">
                  <div className="divImgSmallCard w-100">
                    <img
                      src={item.img.src}
                      className="cardImgFitSmalll"
                      alt={item.img.alt_text}
                    />
                  </div>
                  <div className="text-light divCardCotent text-center pb-3">
                    <span
                      className="fontInter fs-6"
                      dangerouslySetInnerHTML={{
                        __html: item.title,
                      }}
                    ></span>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Related;
