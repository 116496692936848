import React, { useEffect, useState } from "react";
import AnimatedLogoBackground from "./AnimatedHeaderBackground";
import {
  banner,
  currentPositionAnimation,
  goToVariations,
} from "../../utilities/types";
import LogoAnimated from "./LogoAnimated";
import BackgroundVariations from "./BackgroundVariations";
import BtnLabelContent from "./BtnLabelContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  animate,
  AnimatePresence,
  motion,
  useMotionValue,
} from "framer-motion";
import $ from "jquery";
import { getData } from "../../utilities/data/get_data";
import { UniqueNewsBanner } from "./BannerAnimations";
import { useInRouterContext } from "react-router-dom";

let transition = false;
let width = 0;
const prevPosition: currentPositionAnimation = { currentPosition: "RIGHT" };

const MainBackgroundAnimation: React.FC = () => {
  const [currentPosition, setCurrentPosition] =
    useState<currentPositionAnimation>({ currentPosition: "MIDDLE" });
  const [goTo, setGoTo] = useState<goToVariations>({ goTo: "PLACES" });
  const [btnReady, setBtnReady] = useState<boolean>(false);
  let firstPass = true;

  const colors = ["#fff", "#767677"];
  const btnAnimateMenuX = useMotionValue<number>(-100);
  const btnAnimateMenuY = useMotionValue<number>(-100);

  const [bannerData, setBannerData] = useState<banner[] | null | boolean>(null);

  const getDataBanner = async () => {
    const auxBannerData: banner[] = await getData({
      dataToGet: "FIRST_BANNER",
    });
    if (auxBannerData && !Array.isArray(auxBannerData[0])) {
      setBannerData(auxBannerData);
    } else {
      if (bannerData === null) {
        setBannerData(false);
      } else {
        setBannerData(null);
      }
    }
  };

  useEffect(() => {
    if (!bannerData) {
      getDataBanner();
    }
  }, [bannerData]);

  const defineMenuX = () => {
    if (currentPosition.currentPosition === "LEFT") {
      if (width <= 768) {
        animate(btnAnimateMenuX, width - 50, {
          delay: 2.5,
          duration: 0.5,
          ease: "easeInOut",
          onComplete: () => {
            setBtnReady(true);
          },
        });
      } else {
        animate(btnAnimateMenuX, width - 100, {
          delay: 2.5,
          duration: 0.5,
          ease: "easeInOut",
          onComplete: () => {
            setBtnReady(true);
          },
        });
      }
    } else if (currentPosition.currentPosition === "RIGHT") {
      if (width <= 768) {
        animate(btnAnimateMenuX, 30, {
          delay: 2.5,
          duration: 0.5,
          ease: "easeInOut",
          onComplete: () => {
            setBtnReady(true);
          },
        });
      } else {
        animate(btnAnimateMenuX, 100, {
          delay: 2.5,
          duration: 0.5,
          ease: "easeInOut",
          onComplete: () => {
            setBtnReady(true);
          },
        });
      }
    }
  };

  const levingPage = () => {
    if (prevPosition.currentPosition === "RIGHT") {
      animate(btnAnimateMenuX, width + 100, {
        duration: 0.5,
        ease: "easeInOut",
        onComplete: () => {
          btnAnimateMenuX.set(-100);
          setBtnReady(false);
        },
      });
    } else if (prevPosition.currentPosition === "LEFT") {
      animate(btnAnimateMenuX, -100, {
        duration: 0.5,
        ease: "easeInOut",
        onComplete: () => {
          btnAnimateMenuX.set(width + 100);
          setBtnReady(false);
        },
      });
    }
  };

  const defineNextPosition = () => {
    if (prevPosition.currentPosition === "RIGHT") {
      transition = false;
      prevPosition.currentPosition = "LEFT";
      setCurrentPosition({ currentPosition: "RIGHT" });
    } else if (prevPosition.currentPosition === "LEFT") {
      transition = false;
      prevPosition.currentPosition = "RIGHT";
      setCurrentPosition({ currentPosition: "LEFT" });
    }
  };

  const definePosition = () => {
    if (currentPosition.currentPosition === "LEFT") {
      transition = true;
      setCurrentPosition({ currentPosition: "MIDDLE" });
    } else if (currentPosition.currentPosition === "RIGHT") {
      transition = true;
      setCurrentPosition({ currentPosition: "MIDDLE" });
    }
  };

  useEffect(() => {
    if (currentPosition.currentPosition === "LEFT" && firstPass) {
      defineMenuX();
      prevPosition.currentPosition = "RIGHT";
      firstPass = false;
    }

    if (currentPosition.currentPosition === "MIDDLE" && transition) {
      levingPage();
      setTimeout(() => {
        defineNextPosition();
      }, 1500);
    } else if (currentPosition.currentPosition !== "MIDDLE" && !transition) {
      defineMenuX();
    }
  }, [currentPosition]);

  const defineGoTo = ({ goTo }: goToVariations) => {
    if (goTo === "PLACES") {
      setGoTo({ goTo: "PLACES" });
    } else {
      setGoTo({ goTo: "SPORTS" });
    }
    definePosition();
  };

  useEffect(() => {
    const auxHeight = $(window).height();
    const auxWidth = $(window).width();
    if (auxHeight && auxWidth) {
      btnAnimateMenuX.set(auxWidth + 100);
      btnAnimateMenuY.set(auxHeight / 3);
      width = auxWidth;
      btnAnimateMenuX.set(auxWidth + 100);
    }
    setCurrentPosition({ currentPosition: "LEFT" });
  }, []);

  return (
    <div className="fillScreen ">
      <div className="w-100">
        <div className="position-absolute w-100 row align-items-end align-items-md-center h-100 ps-5 pb-5 overflowHidden">
          <LogoAnimated {...currentPosition} />
        </div>
      </div>
      <div className="position-absolute w-100 ">
        <BackgroundVariations
          currentPosition={currentPosition.currentPosition}
          goTo={goTo.goTo}
        />
      </div>
      <div className="position-absolute fillScreenLinks d-none d-md-block">
        <AnimatedLogoBackground {...currentPosition} />
      </div>

      <div
        id="animated_itens_links"
        className="row position-absolute fillScreenLinks zIndex-2 overflowHidden"
      >
        <div className="position-absolute row align-items-center ">
          <motion.div
            className="col-1 "
            style={{ x: btnAnimateMenuX, y: btnAnimateMenuY }}
          >
            <div className="text-center">
              <FontAwesomeIcon
                icon={solid("globe")}
                color={goTo.goTo === "PLACES" ? colors[0] : colors[1]}
                size="lg"
                transform={goTo.goTo === "PLACES" ? "grow-8" : ""}
                className="menuIcons"
                onClick={() => {
                  if (goTo.goTo !== "PLACES" && btnReady) {
                    defineGoTo({ goTo: "PLACES" });
                  }
                }}
              />
            </div>
            <div className="text-center mt-4">
              <a>
                <FontAwesomeIcon
                  icon={solid("running")}
                  color={goTo.goTo === "SPORTS" ? colors[0] : colors[1]}
                  transform={goTo.goTo === "SPORTS" ? "grow-8" : ""}
                  size="lg"
                  className="menuIcons"
                  onClick={() => {
                    if (goTo.goTo !== "SPORTS" && btnReady) {
                      defineGoTo({ goTo: "SPORTS" });
                    }
                  }}
                />
              </a>
            </div>
          </motion.div>
        </div>

        <BtnLabelContent
          currentPosition={currentPosition.currentPosition}
          goTo={goTo.goTo}
        />

        <div
          id="id_firt_banner"
          className="row align-items-end justify-content-center"
        >
          {bannerData && bannerData !== true && (
            <div className="col-12 col-md-10 text-center">
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="h-100 w-100"
                >
                  <UniqueNewsBanner {...bannerData} />
                </motion.div>
              </AnimatePresence>
            </div>
          )}
        </div>
      </div>

      {/* <div className="position-absolute w-100 overflowHidden"> */}
      {/* <a
            href={bannerData[0].x_metadata.link_banner}
            rel="noreferrer"
            target={"_blank"}
          > */}
      {/* {bannerData && (
          <div className="row align-items-end justify-content-center fillHeight">
            <div className="col-12 col-md-10">
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="h-100"
                >
                  <UniqueNewsBanner {...bannerData} />
                </motion.div>
              </AnimatePresence>
            </div>
          </div>
        )} */}
      {/* </a> */}
      {/* </div> */}
    </div>
  );
};

export default MainBackgroundAnimation;
