import React from "react";
import Logo from "../../media/svgs/logoMobile.svg";
import { Facebook, Instagram, Whatapp, Youtube } from "../animations/Icons";

const Footer: React.FC = () => {
  return (
    <div>
      <div className="divFooterBackground pt-5">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-6 row justify-content-center align-items-center mb-5">
              <p className="text-center text-light fs-2 fontRalewayLight">
                Mídia sociais
              </p>
              <div className="row justify-content-center">
                <div className="col-10 col-md-9 col-lg-6 ">
                  <div className="row justify-content-between">
                    <div className="col-2 col-md-1">
                      <a
                        href="https://m.facebook.com/lftvoficial"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <Facebook />
                        {/* <img src={FacebookIcon} /> */}
                      </a>
                    </div>
                    <div className="col-2 col-md-1">
                      <a
                        href="https://wa.me/5571999707734"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <Whatapp />
                      </a>
                    </div>
                    <div className="col-2 col-md-1">
                      <a
                        href="https://instagram.com/lftvoficial?igshid=YmMyMTA2M2Y="
                        rel="noreferrer"
                        target="_blank"
                      >
                        <Instagram />
                      </a>
                    </div>
                    <div className="col-2 col-md-1">
                      <a
                        href="https://youtube.com/c/LFTVOficial"
                        rel="noreferrer"
                        target="_blank"
                      >
                        <Youtube />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <p className="text-center text-light fs-2 fontRalewayLight">
                Como Chegar
              </p>
              <br />
              <div className="mb-5 text-center">
                <div className="display-5 fs-5 text-light">
                  Av. Praia de Guarujá, nº 865. Edf. Guaruja Business.
                </div>
                <div className="display-5 fs-5 text-light">
                  Bairro: Vilas do Atlântico, cidade: Lauro de Freitas - Bahia.
                </div>
                <div className="display-5 fs-5 text-light">CEP:42707-650</div>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center ">
          <div className="col-6 col-md-4 col-lg-2 mb-5 mt-5">
            <img src={Logo} className="img-fluid" alt="logo LFTV" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
