/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React from "react";

// import { Container } from './styles';

const PostComment: React.FC = () => {
  (() => {
    //   "use strict";

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.querySelectorAll(".needs-validation");

    // Loop over them and prevent

    Array.from(forms).forEach((form: Element) => {
      form.addEventListener(
        "submit",
        (event) => {
          if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
          }

          form.classList.add("was-validated");
        },
        false
      );
    });
  })();
  return (
    <div className="container mt-5 mb-5">
      <div className="row justify-content-center">
        <div className="col-10">
          <div className="display-5 mb-2">Deixe seu comentário</div>
          <form className="row g-3 needs-validation" noValidate>
            <div className="col-12 col-lg-8 position-relative">
              <label htmlFor="validationTooltip01" className="form-label">
                Comentário
              </label>
              <textarea
                className="form-control w-100"
                id="validationTooltip01"
                rows={3}
                required
              />
              <div className="invalid-tooltip">Preencha este campo</div>
            </div>
            <div className="col-12 col-lg-8 position-relative row align-items-center mt-1">
              <div className="col-12 col-md-2 col-lg-1">
                <label htmlFor="validationTooltip02" className="form-label">
                  Nome
                </label>
              </div>
              <div className="col-12 col-md-10 col-lg-11">
                <input
                  type="text"
                  className="form-control w-100"
                  id="validationTooltip02"
                  // value="Otto"
                  required
                />
                <div className="invalid-tooltip">Preencha este campo</div>
              </div>
            </div>
            <div className="col-12 col-lg-8 position-relative row align-items-center mt-1">
              <div className="col-12 col-md-2 col-lg-1">
                <label htmlFor="validationTooltip02" className="form-label">
                  Email
                </label>
              </div>
              <div className="col-12 col-md-10 col-lg-11">
                <input
                  type="email"
                  className="form-control "
                  id="validationTooltip02"
                  // value="Otto"
                  required
                />
                <div className="text-center fontRalewayRegular">
                  O seu endereço de e-mail não será publicado
                </div>
                <div className="invalid-tooltip">Preencha este campo</div>
              </div>
            </div>

            <div className="col-12 col-lg-8 position-relative row align-items-center mt-1">
              <div className="col-12 col-md-2 col-lg-1">
                <label htmlFor="validationTooltip02" className="form-label">
                  Site
                </label>
              </div>
              <div className="col-12 col-md-10 col-lg-11">
                <input
                  type="text"
                  className="form-control "
                  id="validationTooltip02"
                  // value="Otto"
                />
              </div>
            </div>

            <div className="col-12">
              <button className="btn btn-primary" type="submit">
                Publicar Comentário
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PostComment;
