import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LineBottom } from "../components/animations/Icons";
import Footer from "../components/geral/Footer";
import Navbar from "../components/geral/NavBar";
import SearchContent from "../components/geral/SearchContent";
import { getData } from "../utilities/data/get_data";
import { defineSearch } from "../utilities/functions";
import { newsItems } from "../utilities/types";
import Logo from "../media/svgs/logoDesktop.svg";
import { LoadingPage } from "../components/animations/LoadingPage";
import PureFooter from "../components/geral/PureFooter";
import PopUp from "../components/geral/PopUp";
import { Helmet } from "react-helmet";
import GoogleAds from "../components/geral/GoogleAds";

const Search: React.FC = () => {
  const location = useLocation();
  const slug = location.pathname.replace("/pesquisa/", "");
  const [data, setData] = useState<newsItems[] | null>(null);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const search = defineSearch(slug);

  const getDataState = async () => {
    const auxData: newsItems[] = await getData(
      {
        dataToGet: "SEARCH",
      },
      search
    );
    if (auxData) {
      setData(auxData);
      setDataLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getDataState();
  }, []);

  if (dataLoading) {
    return (
      <div>
        <LoadingPage />
      </div>
    );
  }

  if (data && data.length > 0) {
    return (
      <div>
        <Helmet>
          <title>Busca por {search}</title>
          <meta property="og:image" content={Logo} />
          <meta
            property="og:image:width"
            content="450"
            className="yoast-seo-meta-tag"
          />
          <meta
            property="og:image:height"
            content="450"
            className="yoast-seo-meta-tag"
          />
          <meta property="og:image:type" content="image/jpeg" />
          <meta name="description" content={search} />
        </Helmet>
        <Navbar />
        <div className="row justify-content-center ">
          <div className="col-2 mt-2">
            <img src={Logo} className="img-fluid" alt="logo LFTV" />
          </div>
        </div>
        <div className="text-center display-5 fs-3 mt-2 container">
          Resultado da pesquisa
          <p className="text-center display-4">{search}</p>
          <LineBottom />
        </div>
        {data && <SearchContent {...data} />}
        <GoogleAds />
        <Footer />
        <PopUp />
      </div>
    );
  } else {
    return (
      <div>
        <div className="fullHeight">
          <Navbar />
          <div className="row justify-content-center ">
            <div className="col-2 mt-2">
              <img src={Logo} className="img-fluid" alt="logo LFTV" />
            </div>
          </div>

          <div className="text-center display-5 fs-3 mt-2 container ">
            Nenhum resultado encontrado para
            <p className="text-center display-4">{search}</p>
            <p className="text-center fs-5 mt-5">
              Verifique sua busca e tente novamente
            </p>
          </div>
        </div>
        <GoogleAds />
        <PureFooter />
        {/* <PopUp /> */}
      </div>
    );
  }
};

export default Search;
