import * as React from "react";

export const LineTop = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 1032 52"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="#052A8A" d="M0 0h1032v52H0z" />
      {/* M1 0H0v52h1z */}
    </svg>
  );
};

export const LineBottom = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 1032 27"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="#052A8A" d="M0 0h1032v27H0z" />
      {/* M0 0h1v27H0z */}
    </svg>
  );
};

export const PrevPage = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 16 22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14 2L4 11.887 14 20" stroke="#024469" strokeWidth={2} />
    </svg>
  );
};

export const NextPage = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill="none"
      viewBox="0 0 16 22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M2 20l10-9.887L2 2" stroke="#024469" strokeWidth={2} />
    </svg>
  );
};

export const Facebook = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 13.229166 13.229167"
      version="1.1"
      id="svg5"
      xmlSpace="preserve"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs id="defs2">
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath68">
          <path
            d="m 643.816,859.25 c -115.98,0 -210,94.02 -210,210 0,115.98 94.02,210 210,210 115.977,0 210,-94.02 210,-210 0,-115.98 -94.023,-210 -210,-210"
            id="path66"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath80">
          <path
            d="m 733.5,1154.7 c 0,-80.75 -65.457,-146.21 -146.211,-146.21 -80.75,0 -146.215,65.46 -146.215,146.21 0,80.76 65.465,146.21 146.215,146.21 80.754,0 146.211,-65.45 146.211,-146.21 z"
            id="path78"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,690.677,1258.09)"
          spreadMethod="pad"
          id="linearGradient96"
        >
          <stop stopOpacity={1} stopColor="#f9d76c" offset="0" id="stop82" />
          <stop
            stopOpacity={1}
            stopColor="#f9d76c"
            offset="0.0011048"
            id="stop84"
          />
          <stop
            stopOpacity={1}
            stopColor="#f9a837"
            offset="0.209357"
            id="stop86"
          />
          <stop
            stopOpacity={1}
            stopColor="#f03d74"
            offset="0.37646"
            id="stop88"
          />
          <stop
            stopOpacity={1}
            stopColor="#e62299"
            offset="0.523815"
            id="stop90"
          />
          <stop
            stopOpacity={1}
            stopColor="#6f3ea2"
            offset="0.739443"
            id="stop92"
          />
          <stop stopOpacity={1} stopColor="#4850ab" offset="1" id="stop94" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath106">
          <path
            d="m 215,432.121 c -118.7422,0 -215,96.258 -215,215 0,29.215 5.82813,57.07 16.3828,82.461 0.1485,-80.621 65.5508,-145.934 146.2112,-145.934 80.75,0 146.215,65.461 146.215,146.219 0,53.348 -28.571,100.02 -71.246,125.555 6.285,0.652 12.484,1.582 18.597,2.766 C 355.215,838.984 430,751.789 430,647.121 c 0,-118.742 -96.262,-215 -215,-215"
            id="path104"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath118">
          <path
            d="m 162.594,583.648 c -80.6604,0 -146.0627,65.313 -146.2112,145.934 0,0.098 0,0.191 0,0.285 0,69.16 48.0156,127.102 112.5272,142.313 26.363,-11.532 55.477,-17.93 86.09,-17.93 7.617,0 15.152,0.398 22.563,1.172 42.675,-25.535 71.246,-72.207 71.246,-125.555 0,-80.758 -65.465,-146.219 -146.215,-146.219"
            id="path116"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,265.983,833.253)"
          spreadMethod="pad"
          id="linearGradient124"
        >
          <stop stopOpacity={1} stopColor="#dfea1f" offset="0" id="stop120" />
          <stop stopOpacity={1} stopColor="#f9a837" offset="1" id="stop122" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath136">
          <path
            d="m 1072.63,859.25 c -115.978,0 -209.997,94.02 -209.997,210 0,115.98 94.019,210 209.997,210 115.98,0 210,-94.02 210,-210 0,-115.98 -94.02,-210 -210,-210"
            id="path134"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath148">
          <path
            d="m 1158.34,1154.7 c 0,-80.75 -65.46,-146.21 -146.21,-146.21 -80.755,0 -146.212,65.46 -146.212,146.21 0,80.76 65.457,146.21 146.212,146.21 80.75,0 146.21,-65.45 146.21,-146.21 z"
            id="path146"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,1115.52,1258.09)"
          spreadMethod="pad"
          id="linearGradient154"
        >
          <stop stopOpacity={1} stopColor="#2fbbe1" offset="0" id="stop150" />
          <stop stopOpacity={1} stopColor="#3970c0" offset="1" id="stop152" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath166">
          <path
            d="m 1072.63,437.121 c -115.978,0 -209.997,94.027 -209.997,210 0,115.977 94.019,210 209.997,210 115.98,0 210,-94.023 210,-210 0,-115.973 -94.02,-210 -210,-210"
            id="path164"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath178">
          <path
            d="m 1158.34,729.867 c 0,-80.758 -65.46,-146.219 -146.21,-146.219 -80.755,0 -146.216,65.461 -146.216,146.219 0,80.75 65.461,146.211 146.216,146.211 80.75,0 146.21,-65.461 146.21,-146.211 z"
            id="path176"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,1115.52,833.252)"
          spreadMethod="pad"
          id="linearGradient184"
        >
          <stop stopOpacity={1} stopColor="#3cba64" offset="0" id="stop180" />
          <stop stopOpacity={1} stopColor="#24b263" offset="1" id="stop182" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath198">
          <path
            d="m 643.816,437.121 c -115.98,0 -210,94.027 -210,210 0,115.977 94.02,210 210,210 115.977,0 210,-94.023 210,-210 0,-115.973 -94.023,-210 -210,-210"
            id="path196"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath210">
          <path
            d="m 733.5,729.867 c 0,-80.758 -65.457,-146.219 -146.211,-146.219 -80.75,0 -146.215,65.461 -146.215,146.219 0,80.75 65.465,146.211 146.215,146.211 80.754,0 146.211,-65.461 146.211,-146.211 z"
            id="path208"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,690.677,833.253)"
          spreadMethod="pad"
          id="linearGradient216"
        >
          <stop stopOpacity={1} stopColor="#ef2f36" offset="0" id="stop212" />
          <stop stopOpacity={1} stopColor="#90191e" offset="1" id="stop214" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath234">
          <path
            d="m 256.16,858.188 c -9.972,1.929 -20.199,3.175 -30.613,3.679 -19.067,9.11 -40.414,14.211 -62.953,14.211 -11.594,0 -22.867,-1.351 -33.684,-3.898 C 53.0273,905.379 0,981.121 0,1069.25 c 0,118.74 96.2578,215 215,215 118.738,0 215,-96.26 215,-215 0,-104.664 -74.785,-191.863 -173.84,-211.062"
            id="path232"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath246">
          <path
            d="m 237.563,855.422 c -3.891,2.328 -7.899,4.48 -12.016,6.445 10.414,-0.504 20.641,-1.75 30.613,-3.679 -6.113,-1.184 -12.312,-2.114 -18.597,-2.766"
            id="path244"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath258">
          <path
            d="m 215,854.25 c -30.613,0 -59.727,6.398 -86.09,17.93 10.817,2.547 22.09,3.898 33.684,3.898 22.539,0 43.886,-5.101 62.953,-14.211 4.117,-1.965 8.125,-4.117 12.016,-6.445 -7.411,-0.774 -14.946,-1.172 -22.563,-1.172"
            id="path256"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath270">
          <path
            d="m 308.809,1154.9 c 0,80.75 -65.465,146.22 -146.215,146.22 -80.7502,0 -146.2112,-65.47 -146.2112,-146.22 0,-72.98 53.4649,-133.47 123.3672,-144.43 v 102.16 h -37.129 v 42.27 h 37.129 v 32.22 c 0,36.64 21.828,56.88 55.227,56.88 15.996,0 32.726,-2.86 32.726,-2.86 v -35.98 h -18.437 c -18.164,0 -23.828,-11.27 -23.828,-22.83 v -27.43 h 40.554 l -6.48,-42.27 h -34.074 v -102.16 c 69.902,10.96 123.371,71.45 123.371,144.43 z"
            id="path268"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(206.777,-206.777,-206.777,-206.777,59.2062,1258.29)"
          spreadMethod="pad"
          id="linearGradient276"
        >
          <stop stopOpacity={1} stopColor="#3f6abc" offset="0" id="stop272" />
          <stop stopOpacity={1} stopColor="#2d368c" offset="1" id="stop274" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath288">
          <path d="M 0,0 H 3459 V 1672 H 0 Z" id="path286" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath294">
          <path
            d="M 215,-0.0117188 C 96.2578,-0.0117188 0,96.25 0,214.988 c 0,118.742 96.2578,215 215,215 118.738,0 215,-96.258 215,-215 C 430,96.25 333.738,-0.0117188 215,-0.0117188"
            id="path292"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath306">
          <path
            d="m 308.809,304.809 c 0,-80.75 -65.465,-146.207 -146.215,-146.207 -80.7542,0 -146.2112,65.457 -146.2112,146.207 0,80.75 65.457,146.222 146.2112,146.222 80.75,0 146.215,-65.472 146.215,-146.222 z"
            id="path304"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,265.983,408.2)"
          spreadMethod="pad"
          id="linearGradient312"
        >
          <stop stopOpacity={1} stopColor="#1679b3" offset="0" id="stop308" />
          <stop stopOpacity={1} stopColor="#2c54ad" offset="1" id="stop310" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath326">
          <path
            d="m 1477.69,855.793 c -106.77,9.906 -190.35,99.742 -190.35,209.097 0,115.98 94.02,210 210,210 115.98,0 210,-94.02 210,-210 0,-107.949 -81.45,-196.874 -186.24,-208.675 -6.47,0.601 -13.02,0.906 -19.65,0.906 -8.04,0 -15.96,-0.449 -23.76,-1.328"
            id="path324"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath338">
          <path
            d="m 1583.05,1154.7 c 0,-80.75 -65.46,-146.21 -146.21,-146.21 -80.75,0 -146.22,65.46 -146.22,146.21 0,80.76 65.47,146.21 146.22,146.21 80.75,0 146.21,-65.45 146.21,-146.21 z"
            id="path336"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(206.778,-206.778,-206.778,-206.778,1333.45,1258.09)"
          spreadMethod="pad"
          id="linearGradient344"
        >
          <stop stopOpacity={1} stopColor="#1b1b3f" offset="0" id="stop340" />
          <stop stopOpacity={1} stopColor="#040606" offset="1" id="stop342" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath360">
          <path
            d="m 643.816,4.98828 c -115.98,0 -210,94.03122 -210,209.99972 0,115.992 94.02,210 210,210 115.977,0 210,-94.008 210,-210 0,-115.9685 -94.023,-209.99972 -210,-209.99972"
            id="path358"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath372">
          <path
            d="m 733.5,304.809 c 0,-80.739 -65.457,-146.207 -146.211,-146.207 -80.75,0 -146.215,65.468 -146.215,146.207 0,80.75 65.465,146.222 146.215,146.222 80.754,0 146.211,-65.472 146.211,-146.222 z"
            id="path370"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,690.677,408.2)"
          spreadMethod="pad"
          id="linearGradient378"
        >
          <stop stopOpacity={1} stopColor="#f14895" offset="0" id="stop374" />
          <stop stopOpacity={1} stopColor="#c91894" offset="1" id="stop376" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath390">
          <path
            d="m 1501.45,437.121 c -115.98,0 -210,94.027 -210,210 0,107.942 81.45,196.867 186.24,208.672 6.47,-0.602 13.03,-0.906 19.65,-0.906 8.04,0 15.96,0.449 23.76,1.328 106.77,-9.906 190.35,-99.742 190.35,-209.094 0,-115.973 -94.02,-210 -210,-210"
            id="path388"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath402">
          <path
            d="m 1497.34,854.887 c -6.62,0 -13.18,0.304 -19.65,0.906 7.8,0.879 15.72,1.328 23.76,1.328 6.63,0 13.18,-0.305 19.65,-0.906 -7.8,-0.879 -15.72,-1.328 -23.76,-1.328"
            id="path400"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath414">
          <path
            d="m 1583.18,729.867 c 0,-80.758 -65.46,-146.219 -146.21,-146.219 -80.75,0 -146.21,65.461 -146.21,146.219 0,80.75 65.46,146.211 146.21,146.211 80.75,0 146.21,-65.461 146.21,-146.211 z"
            id="path412"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,1540.36,833.253)"
          spreadMethod="pad"
          id="linearGradient420"
        >
          <stop stopOpacity={1} stopColor="#2e68bb" offset="0" id="stop416" />
          <stop stopOpacity={1} stopColor="#2d368c" offset="1" id="stop418" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath436">
          <path
            d="m 1501.45,4.98828 c -115.98,0 -210,94.03122 -210,209.99972 0,115.992 94.02,210 210,210 115.98,0 210,-94.008 210,-210 0,-115.9685 -94.02,-209.99972 -210,-209.99972"
            id="path434"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath448">
          <path
            d="m 1583.18,304.809 c 0,-80.75 -65.46,-146.207 -146.21,-146.207 -80.75,0 -146.21,65.457 -146.21,146.207 0,80.75 65.46,146.222 146.21,146.222 80.75,0 146.21,-65.472 146.21,-146.222 z"
            id="path446"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,1540.36,408.2)"
          spreadMethod="pad"
          id="linearGradient454"
        >
          <stop stopOpacity={1} stopColor="#19ae5b" offset="0" id="stop450" />
          <stop stopOpacity={1} stopColor="#069757" offset="1" id="stop452" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath470">
          <path
            d="m 1076.61,9.32031 c -115.981,0 -210.001,94.00769 -210.001,209.99969 0,115.981 94.02,210 210.001,210 115.98,0 210,-94.019 210,-210 0,-115.992 -94.02,-209.99969 -210,-209.99969"
            id="path468"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath482">
          <path
            d="m 1158.34,304.77 c 0,-80.75 -65.46,-146.211 -146.21,-146.211 -80.755,0 -146.212,65.461 -146.212,146.211 0,80.75 65.457,146.21 146.212,146.21 80.75,0 146.21,-65.46 146.21,-146.21 z"
            id="path480"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,1115.52,408.16)"
          spreadMethod="pad"
          id="linearGradient488"
        >
          <stop stopOpacity={1} stopColor="#f04148" offset="0" id="stop484" />
          <stop stopOpacity={1} stopColor="#e51a46" offset="1" id="stop486" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath500">
          <path
            d="m 2391.45,859.25 c -115.97,0 -210,94.02 -210,210 0,115.98 94.03,210 210,210 115.99,0 210,-94.02 210,-210 0,-115.98 -94.01,-210 -210,-210"
            id="path498"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath514">
          <path
            d="m 1962.65,432.121 c -118.75,0 -215,96.258 -215,215 0,29.215 5.82,57.059 16.37,82.453 0.16,-80.613 65.56,-145.926 146.21,-145.926 80.76,0 146.21,65.461 146.21,146.219 0,53.348 -28.56,100.02 -71.24,125.555 6.29,0.652 12.49,1.582 18.61,2.766 99.05,-19.204 173.84,-106.399 173.84,-211.067 0,-118.742 -96.26,-215 -215,-215"
            id="path512"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath530">
          <path
            d="m 2820.27,859.25 c -115.97,0 -210,94.02 -210,210 0,115.98 94.03,210 210,210 115.98,0 210,-94.02 210,-210 0,-115.98 -94.02,-210 -210,-210"
            id="path528"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath546">
          <path
            d="m 2820.27,437.121 c -115.97,0 -210,94.027 -210,210 0,115.977 94.03,210 210,210 115.98,0 210,-94.023 210,-210 0,-115.973 -94.02,-210 -210,-210"
            id="path544"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath564">
          <path
            d="m 2391.45,437.121 c -115.97,0 -210,94.027 -210,210 0,115.977 94.03,210 210,210 115.99,0 210,-94.023 210,-210 0,-115.973 -94.01,-210 -210,-210"
            id="path562"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath586">
          <path
            d="m 2003.81,858.188 c -9.98,1.929 -20.21,3.175 -30.62,3.679 -19.07,9.11 -40.42,14.211 -62.96,14.211 -11.59,0 -22.86,-1.348 -33.68,-3.898 -75.88,33.199 -128.9,108.941 -128.9,197.07 0,118.74 96.25,215 215,215 118.74,0 215,-96.26 215,-215 0,-104.664 -74.79,-191.863 -173.84,-211.062"
            id="path584"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath598">
          <path
            d="m 1985.2,855.422 c -3.89,2.324 -7.9,4.48 -12.01,6.445 10.41,-0.504 20.64,-1.75 30.62,-3.679 -6.12,-1.184 -12.32,-2.114 -18.61,-2.766"
            id="path596"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath610">
          <path
            d="m 1962.65,854.25 c -30.62,0 -59.74,6.398 -86.1,17.93 10.82,2.55 22.09,3.898 33.68,3.898 22.54,0 43.89,-5.101 62.96,-14.211 4.11,-1.965 8.12,-4.121 12.01,-6.445 -7.41,-0.774 -14.94,-1.172 -22.55,-1.172"
            id="path608"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath626">
          <path d="M 0,0 H 3459 V 1672 H 0 Z" id="path624" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath632">
          <path
            d="m 1962.65,-0.0117188 c -118.75,0 -215,96.2617188 -215,214.9997188 0,118.742 96.25,215 215,215 118.74,0 215,-96.258 215,-215 0,-118.738 -96.26,-214.9997188 -215,-214.9997188"
            id="path630"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath650">
          <path
            d="m 3225.33,855.793 c -106.76,9.906 -190.35,99.742 -190.35,209.097 0,115.98 94.02,210 210,210 115.99,0 210,-94.02 210,-210 0,-107.949 -81.44,-196.874 -186.24,-208.675 -6.47,0.601 -13.02,0.906 -19.65,0.906 -8.03,0 -15.96,-0.449 -23.76,-1.328"
            id="path648"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath666">
          <path
            d="m 2391.45,4.98828 c -115.97,0 -210,94.03122 -210,209.99972 0,115.992 94.03,210 210,210 115.99,0 210,-94.008 210,-210 0,-115.9685 -94.01,-209.99972 -210,-209.99972"
            id="path664"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath682">
          <path d="M 0,0 H 3459 V 1672 H 0 Z" id="path680" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath688">
          <path
            d="m 3249.09,437.121 c -115.98,0 -210,94.027 -210,210 0,107.942 81.45,196.867 186.24,208.672 6.47,-0.602 13.02,-0.906 19.65,-0.906 8.03,0 15.96,0.449 23.76,1.328 106.77,-9.906 190.35,-99.742 190.35,-209.094 0,-115.973 -94.02,-210 -210,-210"
            id="path686"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath700">
          <path
            d="m 3244.98,854.887 c -6.63,0 -13.18,0.304 -19.65,0.906 7.8,0.879 15.73,1.328 23.76,1.328 6.63,0 13.18,-0.305 19.65,-0.906 -7.8,-0.879 -15.73,-1.328 -23.76,-1.328"
            id="path698"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath720">
          <path d="M 0,0 H 3459 V 1672 H 0 Z" id="path718" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath726">
          <path
            d="m 3249.09,4.98828 c -115.98,0 -210,94.03122 -210,209.99972 0,115.992 94.02,210 210,210 115.98,0 210,-94.008 210,-210 0,-115.9685 -94.02,-209.99972 -210,-209.99972"
            id="path724"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath746">
          <path
            d="m 2824.25,9.32031 c -115.98,0 -210,94.00769 -210,209.99969 0,115.981 94.02,210 210,210 115.98,0 210,-94.019 210,-210 0,-115.992 -94.02,-209.99969 -210,-209.99969"
            id="path744"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath80-6">
          <path
            d="m 733.5,1154.7 c 0,-80.75 -65.457,-146.21 -146.211,-146.21 -80.75,0 -146.215,65.46 -146.215,146.21 0,80.76 65.465,146.21 146.215,146.21 80.754,0 146.211,-65.45 146.211,-146.21 z"
            id="path78-2"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath270-0">
          <path
            d="m 308.809,1154.9 c 0,80.75 -65.465,146.22 -146.215,146.22 -80.7502,0 -146.2112,-65.47 -146.2112,-146.22 0,-72.98 53.4649,-133.47 123.3672,-144.43 v 102.16 h -37.129 v 42.27 h 37.129 v 32.22 c 0,36.64 21.828,56.88 55.227,56.88 15.996,0 32.726,-2.86 32.726,-2.86 v -35.98 h -18.437 c -18.164,0 -23.828,-11.27 -23.828,-22.83 v -27.43 h 40.554 l -6.48,-42.27 h -34.074 v -102.16 c 69.902,10.96 123.371,71.45 123.371,144.43 z"
            id="path268-7"
          />
        </clipPath>
        <linearGradient
          xlinkHref="#linearGradient276"
          id="linearGradient1437"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(206.777,-206.777,-206.777,-206.777,59.2062,1258.29)"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
        />
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath210-0">
          <path
            d="m 733.5,729.867 c 0,-80.758 -65.457,-146.219 -146.211,-146.219 -80.75,0 -146.215,65.461 -146.215,146.219 0,80.75 65.465,146.211 146.215,146.211 80.754,0 146.211,-65.461 146.211,-146.211 z"
            id="path208-9"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath178-9">
          <path
            d="m 1158.34,729.867 c 0,-80.758 -65.46,-146.219 -146.21,-146.219 -80.755,0 -146.216,65.461 -146.216,146.219 0,80.75 65.461,146.211 146.216,146.211 80.75,0 146.21,-65.461 146.21,-146.211 z"
            id="path176-3"
          />
        </clipPath>
      </defs>
      <g id="layer1">
        <g
          id="g10"
          transform="matrix(0.03527778,0,0,-0.03527778,7.675884,64.985998)"
        >
          <g
            id="g264-7"
            transform="matrix(1.1698007,0,0,1.1698007,-203.56365,286.88844)"
          >
            <g
              id="g266-6"
              clipPath="url(#clipPath270-0)"
              transform="matrix(1.0936487,0,0,1.0936487,-28.919557,-94.462498)"
            >
              <path
                d="m 308.809,1154.9 c 0,80.75 -65.465,146.22 -146.215,146.22 -80.7502,0 -146.2112,-65.47 -146.2112,-146.22 0,-72.98 53.4649,-133.47 123.3672,-144.43 v 102.16 h -37.129 v 42.27 h 37.129 v 32.22 c 0,36.64 21.828,56.88 55.227,56.88 15.996,0 32.726,-2.86 32.726,-2.86 v -35.98 h -18.437 c -18.164,0 -23.828,-11.27 -23.828,-22.83 v -27.43 h 40.554 l -6.48,-42.27 h -34.074 v -102.16 c 69.902,10.96 123.371,71.45 123.371,144.43"
                fill="url(#linearGradient1437)"
                fillOpacity={1}
                fillRule="nonzero"
                stroke="none"
                id="path278-4"
              />
            </g>
          </g>
          <path
            d="m 43.439136,1599.8306 8.290195,54.078 H -0.15347104 v 35.0927 c 0,14.7893 7.24624354,29.2075 30.48437704,29.2075 h 23.587395 v 46.0311 c 0,0 -21.403543,3.659 -41.868042,3.659 -42.729046,0 -70.654721,-25.8941 -70.654721,-72.7695 v -41.2208 h -47.501028 v -54.078 h 47.501028 V 1469.132 c 9.519651,-1.4969 19.284938,-2.2772 29.225496,-2.2772 9.944395,0 19.7045639,0.7803 29.22549496,2.2772 v 130.6986 H 43.439136"
            fill="#ffffff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            strokeWidth={1.27935}
            id="path280-3"
          />
        </g>
      </g>
    </svg>

    // <svg
    //   width={41}
    //   height={42}
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   {...props}
    // >
    //   <g filter="url(#prefix__filter0_bi_256_16)">
    //     <circle
    //       cx={20.5}
    //       cy={20.5}
    //       r={20.5}
    //       fill="url(#prefix__paint0_linear_256_16)"
    //     />
    //   </g>
    //   <g filter="url(#prefix__filter1_i_256_16)">
    //     <path
    //       fillRule="evenodd"
    //       clipRule="evenodd"
    //       d="M23.83 12.422c-3.615.52-5.432 2.08-6.19 5.32-.133.569-.192 1.441-.233 3.412l-.054 2.648H12.67v1.031c0 .568-.03 1.819-.069 2.78l-.068 1.746H17.348l.069 3.478c.038 1.913.07 4.532.07 5.82v2.127s1.634.28 2.69.28 2.789-.194 2.789-.194v-3.567c.038-2.033.07-4.651.07-5.818v-2.122l2.276-.028 2.276-.028.373-2.7.372-2.7-1.785-.001c-.982 0-2.174-.031-2.649-.068l-.864-.068v-2.242c0-2.78.098-3.102 1.1-3.634.372-.197.629-.226 2.33-.26l1.91-.04.001-.697c0-.384.031-1.528.068-2.543l.067-1.845-.409-.071c-.89-.155-3.244-.164-4.273-.016z"
    //       fill="url(#prefix__paint1_linear_256_16)"
    //     />
    //   </g>
    //   <defs>
    //     <linearGradient
    //       id="prefix__paint0_linear_256_16"
    //       x1={11.549}
    //       y1={0}
    //       x2={25.444}
    //       y2={40.893}
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stopColor="#fff" stopOpacity={0.15} />
    //       <stop offset={1} stopColor="#fff" stopOpacity={0.05} />
    //     </linearGradient>
    //     <linearGradient
    //       id="prefix__paint1_linear_256_16"
    //       x1={12.533}
    //       y1={15.951}
    //       x2={27.679}
    //       y2={46}
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stopColor="#003840" />
    //       <stop offset={0.902} stopColor="#4CB1BF" />
    //     </linearGradient>
    //     <filter
    //       id="prefix__filter0_bi_256_16"
    //       x={-5}
    //       y={-5}
    //       width={51}
    //       height={51}
    //       filterUnits="userSpaceOnUse"
    //       colorInterpolationFilters="sRGB"
    //     >
    //       <feFlood floodOpacity={0} result="BackgroundImageFix" />
    //       <feGaussianBlur in="BackgroundImage" stdDeviation={2.5} />
    //       <feComposite
    //         in2="SourceAlpha"
    //         operator="in"
    //         result="effect1_backgroundBlur_256_16"
    //       />
    //       <feBlend
    //         in="SourceGraphic"
    //         in2="effect1_backgroundBlur_256_16"
    //         result="shape"
    //       />
    //       <feColorMatrix
    //         in="SourceAlpha"
    //         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
    //         result="hardAlpha"
    //       />
    //       <feOffset dy={2} />
    //       <feGaussianBlur stdDeviation={2} />
    //       <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
    //       <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.75 0" />
    //       <feBlend in2="shape" result="effect2_innerShadow_256_16" />
    //     </filter>
    //     <filter
    //       id="prefix__filter1_i_256_16"
    //       x={12.533}
    //       y={12.316}
    //       width={15.978}
    //       height={28.748}
    //       filterUnits="userSpaceOnUse"
    //       colorInterpolationFilters="sRGB"
    //     >
    //       <feFlood floodOpacity={0} result="BackgroundImageFix" />
    //       <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
    //       <feColorMatrix
    //         in="SourceAlpha"
    //         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
    //         result="hardAlpha"
    //       />
    //       <feOffset />
    //       <feGaussianBlur stdDeviation={2.5} />
    //       <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
    //       <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.85 0" />
    //       <feBlend in2="shape" result="effect1_innerShadow_256_16" />
    //     </filter>
    //   </defs>
    // </svg>
  );
};

export const Whatapp = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 13.229166 13.229167"
      version="1.1"
      id="svg5"
      xmlSpace="preserve"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs id="defs2">
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath68">
          <path
            d="m 643.816,859.25 c -115.98,0 -210,94.02 -210,210 0,115.98 94.02,210 210,210 115.977,0 210,-94.02 210,-210 0,-115.98 -94.023,-210 -210,-210"
            id="path66"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath80">
          <path
            d="m 733.5,1154.7 c 0,-80.75 -65.457,-146.21 -146.211,-146.21 -80.75,0 -146.215,65.46 -146.215,146.21 0,80.76 65.465,146.21 146.215,146.21 80.754,0 146.211,-65.45 146.211,-146.21 z"
            id="path78"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,690.677,1258.09)"
          spreadMethod="pad"
          id="linearGradient96"
        >
          <stop stopOpacity={1} stopColor="#f9d76c" offset="0" id="stop82" />
          <stop
            stopOpacity={1}
            stopColor="#f9d76c"
            offset="0.0011048"
            id="stop84"
          />
          <stop
            stopOpacity={1}
            stopColor="#f9a837"
            offset="0.209357"
            id="stop86"
          />
          <stop
            stopOpacity={1}
            stopColor="#f03d74"
            offset="0.37646"
            id="stop88"
          />
          <stop
            stopOpacity={1}
            stopColor="#e62299"
            offset="0.523815"
            id="stop90"
          />
          <stop
            stopOpacity={1}
            stopColor="#6f3ea2"
            offset="0.739443"
            id="stop92"
          />
          <stop stopOpacity={1} stopColor="#4850ab" offset="1" id="stop94" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath106">
          <path
            d="m 215,432.121 c -118.7422,0 -215,96.258 -215,215 0,29.215 5.82813,57.07 16.3828,82.461 0.1485,-80.621 65.5508,-145.934 146.2112,-145.934 80.75,0 146.215,65.461 146.215,146.219 0,53.348 -28.571,100.02 -71.246,125.555 6.285,0.652 12.484,1.582 18.597,2.766 C 355.215,838.984 430,751.789 430,647.121 c 0,-118.742 -96.262,-215 -215,-215"
            id="path104"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath118">
          <path
            d="m 162.594,583.648 c -80.6604,0 -146.0627,65.313 -146.2112,145.934 0,0.098 0,0.191 0,0.285 0,69.16 48.0156,127.102 112.5272,142.313 26.363,-11.532 55.477,-17.93 86.09,-17.93 7.617,0 15.152,0.398 22.563,1.172 42.675,-25.535 71.246,-72.207 71.246,-125.555 0,-80.758 -65.465,-146.219 -146.215,-146.219"
            id="path116"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,265.983,833.253)"
          spreadMethod="pad"
          id="linearGradient124"
        >
          <stop stopOpacity={1} stopColor="#dfea1f" offset="0" id="stop120" />
          <stop stopOpacity={1} stopColor="#f9a837" offset="1" id="stop122" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath136">
          <path
            d="m 1072.63,859.25 c -115.978,0 -209.997,94.02 -209.997,210 0,115.98 94.019,210 209.997,210 115.98,0 210,-94.02 210,-210 0,-115.98 -94.02,-210 -210,-210"
            id="path134"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath148">
          <path
            d="m 1158.34,1154.7 c 0,-80.75 -65.46,-146.21 -146.21,-146.21 -80.755,0 -146.212,65.46 -146.212,146.21 0,80.76 65.457,146.21 146.212,146.21 80.75,0 146.21,-65.45 146.21,-146.21 z"
            id="path146"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,1115.52,1258.09)"
          spreadMethod="pad"
          id="linearGradient154"
        >
          <stop stopOpacity={1} stopColor="#2fbbe1" offset="0" id="stop150" />
          <stop stopOpacity={1} stopColor="#3970c0" offset="1" id="stop152" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath166">
          <path
            d="m 1072.63,437.121 c -115.978,0 -209.997,94.027 -209.997,210 0,115.977 94.019,210 209.997,210 115.98,0 210,-94.023 210,-210 0,-115.973 -94.02,-210 -210,-210"
            id="path164"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath178">
          <path
            d="m 1158.34,729.867 c 0,-80.758 -65.46,-146.219 -146.21,-146.219 -80.755,0 -146.216,65.461 -146.216,146.219 0,80.75 65.461,146.211 146.216,146.211 80.75,0 146.21,-65.461 146.21,-146.211 z"
            id="path176"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,1115.52,833.252)"
          spreadMethod="pad"
          id="linearGradient184"
        >
          <stop stopOpacity={1} stopColor="#3cba64" offset="0" id="stop180" />
          <stop stopOpacity={1} stopColor="#24b263" offset="1" id="stop182" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath198">
          <path
            d="m 643.816,437.121 c -115.98,0 -210,94.027 -210,210 0,115.977 94.02,210 210,210 115.977,0 210,-94.023 210,-210 0,-115.973 -94.023,-210 -210,-210"
            id="path196"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath210">
          <path
            d="m 733.5,729.867 c 0,-80.758 -65.457,-146.219 -146.211,-146.219 -80.75,0 -146.215,65.461 -146.215,146.219 0,80.75 65.465,146.211 146.215,146.211 80.754,0 146.211,-65.461 146.211,-146.211 z"
            id="path208"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,690.677,833.253)"
          spreadMethod="pad"
          id="linearGradient216"
        >
          <stop stopOpacity={1} stopColor="#ef2f36" offset="0" id="stop212" />
          <stop stopOpacity={1} stopColor="#90191e" offset="1" id="stop214" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath234">
          <path
            d="m 256.16,858.188 c -9.972,1.929 -20.199,3.175 -30.613,3.679 -19.067,9.11 -40.414,14.211 -62.953,14.211 -11.594,0 -22.867,-1.351 -33.684,-3.898 C 53.0273,905.379 0,981.121 0,1069.25 c 0,118.74 96.2578,215 215,215 118.738,0 215,-96.26 215,-215 0,-104.664 -74.785,-191.863 -173.84,-211.062"
            id="path232"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath246">
          <path
            d="m 237.563,855.422 c -3.891,2.328 -7.899,4.48 -12.016,6.445 10.414,-0.504 20.641,-1.75 30.613,-3.679 -6.113,-1.184 -12.312,-2.114 -18.597,-2.766"
            id="path244"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath258">
          <path
            d="m 215,854.25 c -30.613,0 -59.727,6.398 -86.09,17.93 10.817,2.547 22.09,3.898 33.684,3.898 22.539,0 43.886,-5.101 62.953,-14.211 4.117,-1.965 8.125,-4.117 12.016,-6.445 -7.411,-0.774 -14.946,-1.172 -22.563,-1.172"
            id="path256"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath270">
          <path
            d="m 308.809,1154.9 c 0,80.75 -65.465,146.22 -146.215,146.22 -80.7502,0 -146.2112,-65.47 -146.2112,-146.22 0,-72.98 53.4649,-133.47 123.3672,-144.43 v 102.16 h -37.129 v 42.27 h 37.129 v 32.22 c 0,36.64 21.828,56.88 55.227,56.88 15.996,0 32.726,-2.86 32.726,-2.86 v -35.98 h -18.437 c -18.164,0 -23.828,-11.27 -23.828,-22.83 v -27.43 h 40.554 l -6.48,-42.27 h -34.074 v -102.16 c 69.902,10.96 123.371,71.45 123.371,144.43 z"
            id="path268"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(206.777,-206.777,-206.777,-206.777,59.2062,1258.29)"
          spreadMethod="pad"
          id="linearGradient276"
        >
          <stop stopOpacity={1} stopColor="#3f6abc" offset="0" id="stop272" />
          <stop stopOpacity={1} stopColor="#2d368c" offset="1" id="stop274" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath288">
          <path d="M 0,0 H 3459 V 1672 H 0 Z" id="path286" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath294">
          <path
            d="M 215,-0.0117188 C 96.2578,-0.0117188 0,96.25 0,214.988 c 0,118.742 96.2578,215 215,215 118.738,0 215,-96.258 215,-215 C 430,96.25 333.738,-0.0117188 215,-0.0117188"
            id="path292"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath306">
          <path
            d="m 308.809,304.809 c 0,-80.75 -65.465,-146.207 -146.215,-146.207 -80.7542,0 -146.2112,65.457 -146.2112,146.207 0,80.75 65.457,146.222 146.2112,146.222 80.75,0 146.215,-65.472 146.215,-146.222 z"
            id="path304"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,265.983,408.2)"
          spreadMethod="pad"
          id="linearGradient312"
        >
          <stop stopOpacity={1} stopColor="#1679b3" offset="0" id="stop308" />
          <stop stopOpacity={1} stopColor="#2c54ad" offset="1" id="stop310" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath326">
          <path
            d="m 1477.69,855.793 c -106.77,9.906 -190.35,99.742 -190.35,209.097 0,115.98 94.02,210 210,210 115.98,0 210,-94.02 210,-210 0,-107.949 -81.45,-196.874 -186.24,-208.675 -6.47,0.601 -13.02,0.906 -19.65,0.906 -8.04,0 -15.96,-0.449 -23.76,-1.328"
            id="path324"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath338">
          <path
            d="m 1583.05,1154.7 c 0,-80.75 -65.46,-146.21 -146.21,-146.21 -80.75,0 -146.22,65.46 -146.22,146.21 0,80.76 65.47,146.21 146.22,146.21 80.75,0 146.21,-65.45 146.21,-146.21 z"
            id="path336"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(206.778,-206.778,-206.778,-206.778,1333.45,1258.09)"
          spreadMethod="pad"
          id="linearGradient344"
        >
          <stop stopOpacity={1} stopColor="#1b1b3f" offset="0" id="stop340" />
          <stop stopOpacity={1} stopColor="#040606" offset="1" id="stop342" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath360">
          <path
            d="m 643.816,4.98828 c -115.98,0 -210,94.03122 -210,209.99972 0,115.992 94.02,210 210,210 115.977,0 210,-94.008 210,-210 0,-115.9685 -94.023,-209.99972 -210,-209.99972"
            id="path358"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath372">
          <path
            d="m 733.5,304.809 c 0,-80.739 -65.457,-146.207 -146.211,-146.207 -80.75,0 -146.215,65.468 -146.215,146.207 0,80.75 65.465,146.222 146.215,146.222 80.754,0 146.211,-65.472 146.211,-146.222 z"
            id="path370"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,690.677,408.2)"
          spreadMethod="pad"
          id="linearGradient378"
        >
          <stop stopOpacity={1} stopColor="#f14895" offset="0" id="stop374" />
          <stop stopOpacity={1} stopColor="#c91894" offset="1" id="stop376" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath390">
          <path
            d="m 1501.45,437.121 c -115.98,0 -210,94.027 -210,210 0,107.942 81.45,196.867 186.24,208.672 6.47,-0.602 13.03,-0.906 19.65,-0.906 8.04,0 15.96,0.449 23.76,1.328 106.77,-9.906 190.35,-99.742 190.35,-209.094 0,-115.973 -94.02,-210 -210,-210"
            id="path388"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath402">
          <path
            d="m 1497.34,854.887 c -6.62,0 -13.18,0.304 -19.65,0.906 7.8,0.879 15.72,1.328 23.76,1.328 6.63,0 13.18,-0.305 19.65,-0.906 -7.8,-0.879 -15.72,-1.328 -23.76,-1.328"
            id="path400"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath414">
          <path
            d="m 1583.18,729.867 c 0,-80.758 -65.46,-146.219 -146.21,-146.219 -80.75,0 -146.21,65.461 -146.21,146.219 0,80.75 65.46,146.211 146.21,146.211 80.75,0 146.21,-65.461 146.21,-146.211 z"
            id="path412"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,1540.36,833.253)"
          spreadMethod="pad"
          id="linearGradient420"
        >
          <stop stopOpacity={1} stopColor="#2e68bb" offset="0" id="stop416" />
          <stop stopOpacity={1} stopColor="#2d368c" offset="1" id="stop418" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath436">
          <path
            d="m 1501.45,4.98828 c -115.98,0 -210,94.03122 -210,209.99972 0,115.992 94.02,210 210,210 115.98,0 210,-94.008 210,-210 0,-115.9685 -94.02,-209.99972 -210,-209.99972"
            id="path434"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath448">
          <path
            d="m 1583.18,304.809 c 0,-80.75 -65.46,-146.207 -146.21,-146.207 -80.75,0 -146.21,65.457 -146.21,146.207 0,80.75 65.46,146.222 146.21,146.222 80.75,0 146.21,-65.472 146.21,-146.222 z"
            id="path446"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,1540.36,408.2)"
          spreadMethod="pad"
          id="linearGradient454"
        >
          <stop stopOpacity={1} stopColor="#19ae5b" offset="0" id="stop450" />
          <stop stopOpacity={1} stopColor="#069757" offset="1" id="stop452" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath470">
          <path
            d="m 1076.61,9.32031 c -115.981,0 -210.001,94.00769 -210.001,209.99969 0,115.981 94.02,210 210.001,210 115.98,0 210,-94.019 210,-210 0,-115.992 -94.02,-209.99969 -210,-209.99969"
            id="path468"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath482">
          <path
            d="m 1158.34,304.77 c 0,-80.75 -65.46,-146.211 -146.21,-146.211 -80.755,0 -146.212,65.461 -146.212,146.211 0,80.75 65.457,146.21 146.212,146.21 80.75,0 146.21,-65.46 146.21,-146.21 z"
            id="path480"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,1115.52,408.16)"
          spreadMethod="pad"
          id="linearGradient488"
        >
          <stop stopOpacity={1} stopColor="#f04148" offset="0" id="stop484" />
          <stop stopOpacity={1} stopColor="#e51a46" offset="1" id="stop486" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath500">
          <path
            d="m 2391.45,859.25 c -115.97,0 -210,94.02 -210,210 0,115.98 94.03,210 210,210 115.99,0 210,-94.02 210,-210 0,-115.98 -94.01,-210 -210,-210"
            id="path498"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath514">
          <path
            d="m 1962.65,432.121 c -118.75,0 -215,96.258 -215,215 0,29.215 5.82,57.059 16.37,82.453 0.16,-80.613 65.56,-145.926 146.21,-145.926 80.76,0 146.21,65.461 146.21,146.219 0,53.348 -28.56,100.02 -71.24,125.555 6.29,0.652 12.49,1.582 18.61,2.766 99.05,-19.204 173.84,-106.399 173.84,-211.067 0,-118.742 -96.26,-215 -215,-215"
            id="path512"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath530">
          <path
            d="m 2820.27,859.25 c -115.97,0 -210,94.02 -210,210 0,115.98 94.03,210 210,210 115.98,0 210,-94.02 210,-210 0,-115.98 -94.02,-210 -210,-210"
            id="path528"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath546">
          <path
            d="m 2820.27,437.121 c -115.97,0 -210,94.027 -210,210 0,115.977 94.03,210 210,210 115.98,0 210,-94.023 210,-210 0,-115.973 -94.02,-210 -210,-210"
            id="path544"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath564">
          <path
            d="m 2391.45,437.121 c -115.97,0 -210,94.027 -210,210 0,115.977 94.03,210 210,210 115.99,0 210,-94.023 210,-210 0,-115.973 -94.01,-210 -210,-210"
            id="path562"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath586">
          <path
            d="m 2003.81,858.188 c -9.98,1.929 -20.21,3.175 -30.62,3.679 -19.07,9.11 -40.42,14.211 -62.96,14.211 -11.59,0 -22.86,-1.348 -33.68,-3.898 -75.88,33.199 -128.9,108.941 -128.9,197.07 0,118.74 96.25,215 215,215 118.74,0 215,-96.26 215,-215 0,-104.664 -74.79,-191.863 -173.84,-211.062"
            id="path584"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath598">
          <path
            d="m 1985.2,855.422 c -3.89,2.324 -7.9,4.48 -12.01,6.445 10.41,-0.504 20.64,-1.75 30.62,-3.679 -6.12,-1.184 -12.32,-2.114 -18.61,-2.766"
            id="path596"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath610">
          <path
            d="m 1962.65,854.25 c -30.62,0 -59.74,6.398 -86.1,17.93 10.82,2.55 22.09,3.898 33.68,3.898 22.54,0 43.89,-5.101 62.96,-14.211 4.11,-1.965 8.12,-4.121 12.01,-6.445 -7.41,-0.774 -14.94,-1.172 -22.55,-1.172"
            id="path608"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath626">
          <path d="M 0,0 H 3459 V 1672 H 0 Z" id="path624" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath632">
          <path
            d="m 1962.65,-0.0117188 c -118.75,0 -215,96.2617188 -215,214.9997188 0,118.742 96.25,215 215,215 118.74,0 215,-96.258 215,-215 0,-118.738 -96.26,-214.9997188 -215,-214.9997188"
            id="path630"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath650">
          <path
            d="m 3225.33,855.793 c -106.76,9.906 -190.35,99.742 -190.35,209.097 0,115.98 94.02,210 210,210 115.99,0 210,-94.02 210,-210 0,-107.949 -81.44,-196.874 -186.24,-208.675 -6.47,0.601 -13.02,0.906 -19.65,0.906 -8.03,0 -15.96,-0.449 -23.76,-1.328"
            id="path648"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath666">
          <path
            d="m 2391.45,4.98828 c -115.97,0 -210,94.03122 -210,209.99972 0,115.992 94.03,210 210,210 115.99,0 210,-94.008 210,-210 0,-115.9685 -94.01,-209.99972 -210,-209.99972"
            id="path664"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath682">
          <path d="M 0,0 H 3459 V 1672 H 0 Z" id="path680" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath688">
          <path
            d="m 3249.09,437.121 c -115.98,0 -210,94.027 -210,210 0,107.942 81.45,196.867 186.24,208.672 6.47,-0.602 13.02,-0.906 19.65,-0.906 8.03,0 15.96,0.449 23.76,1.328 106.77,-9.906 190.35,-99.742 190.35,-209.094 0,-115.973 -94.02,-210 -210,-210"
            id="path686"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath700">
          <path
            d="m 3244.98,854.887 c -6.63,0 -13.18,0.304 -19.65,0.906 7.8,0.879 15.73,1.328 23.76,1.328 6.63,0 13.18,-0.305 19.65,-0.906 -7.8,-0.879 -15.73,-1.328 -23.76,-1.328"
            id="path698"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath720">
          <path d="M 0,0 H 3459 V 1672 H 0 Z" id="path718" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath726">
          <path
            d="m 3249.09,4.98828 c -115.98,0 -210,94.03122 -210,209.99972 0,115.992 94.02,210 210,210 115.98,0 210,-94.008 210,-210 0,-115.9685 -94.02,-209.99972 -210,-209.99972"
            id="path724"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath746">
          <path
            d="m 2824.25,9.32031 c -115.98,0 -210,94.00769 -210,209.99969 0,115.981 94.02,210 210,210 115.98,0 210,-94.019 210,-210 0,-115.992 -94.02,-209.99969 -210,-209.99969"
            id="path744"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath80-6">
          <path
            d="m 733.5,1154.7 c 0,-80.75 -65.457,-146.21 -146.211,-146.21 -80.75,0 -146.215,65.46 -146.215,146.21 0,80.76 65.465,146.21 146.215,146.21 80.754,0 146.211,-65.45 146.211,-146.21 z"
            id="path78-2"
          />
        </clipPath>
        <linearGradient
          xlinkHref="#linearGradient96"
          id="linearGradient1377"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,690.677,1258.09)"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
        />
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath270-0">
          <path
            d="m 308.809,1154.9 c 0,80.75 -65.465,146.22 -146.215,146.22 -80.7502,0 -146.2112,-65.47 -146.2112,-146.22 0,-72.98 53.4649,-133.47 123.3672,-144.43 v 102.16 h -37.129 v 42.27 h 37.129 v 32.22 c 0,36.64 21.828,56.88 55.227,56.88 15.996,0 32.726,-2.86 32.726,-2.86 v -35.98 h -18.437 c -18.164,0 -23.828,-11.27 -23.828,-22.83 v -27.43 h 40.554 l -6.48,-42.27 h -34.074 v -102.16 c 69.902,10.96 123.371,71.45 123.371,144.43 z"
            id="path268-7"
          />
        </clipPath>
        <linearGradient
          xlinkHref="#linearGradient276"
          id="linearGradient1437"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(206.777,-206.777,-206.777,-206.777,59.2062,1258.29)"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
        />
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath210-0">
          <path
            d="m 733.5,729.867 c 0,-80.758 -65.457,-146.219 -146.211,-146.219 -80.75,0 -146.215,65.461 -146.215,146.219 0,80.75 65.465,146.211 146.215,146.211 80.754,0 146.211,-65.461 146.211,-146.211 z"
            id="path208-9"
          />
        </clipPath>
        <linearGradient
          xlinkHref="#linearGradient216"
          id="linearGradient2132"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,690.677,833.253)"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
        />
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath178-9">
          <path
            d="m 1158.34,729.867 c 0,-80.758 -65.46,-146.219 -146.21,-146.219 -80.755,0 -146.216,65.461 -146.216,146.219 0,80.75 65.461,146.211 146.216,146.211 80.75,0 146.21,-65.461 146.21,-146.211 z"
            id="path176-3"
          />
        </clipPath>
        <linearGradient
          xlinkHref="#linearGradient184"
          id="linearGradient2265"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,1115.52,833.252)"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
        />
      </defs>
      <g id="layer1">
        <g
          id="g10"
          transform="matrix(0.03527778,0,0,-0.03527778,7.675884,64.985998)"
        >
          <g
            id="g172-2"
            transform="matrix(1.2556271,0,0,1.2556271,-1298.9252,739.30343)"
          >
            <g id="g174-4" clipPath="url(#clipPath178-9)">
              <path
                d="m 1158.34,729.867 c 0,-80.758 -65.46,-146.219 -146.21,-146.219 -80.755,0 -146.216,65.461 -146.216,146.219 0,80.75 65.461,146.211 146.216,146.211 80.75,0 146.21,-65.461 146.21,-146.211"
                fill="url(#linearGradient2265)"
                fillOpacity={1}
                fillRule="nonzero"
                stroke="none"
                id="path186-0"
              />
            </g>
          </g>
          <path
            d="m -67.003077,1588.6322 3.619973,-2.1584 c 13.271978,-7.9117 28.372149,-12.0904 43.653131,-12.0904 47.161353,0 85.52076,38.3707 85.52076,85.5157 0,47.1589 -38.359407,85.5296 -85.52076,85.5296 -47.150053,0 -85.515747,-38.3707 -85.515747,-85.5296 0,-15.5735 4.32062,-30.901 12.507304,-44.3399 l 2.217437,-3.6389 -8.544542,-31.8866 z m -60.255043,-36.7861 15.30233,57.116 c -8.66131,15.6376 -13.22301,33.1573 -13.22301,50.937 0,58.1456 47.307009,105.4526 105.448827,105.4526 58.14809,0 105.460118,-47.307 105.460118,-105.4526 0,-58.1468 -47.312028,-105.4538 -105.460118,-105.4538 -17.453217,0 -34.691721,4.4198 -50.122122,12.7823 l -57.406025,-15.3815"
            fill="#ffffff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            strokeWidth={1.25563}
            id="path188-8"
          />
          <path
            d="m 19.495817,1645.2334 c -4.331914,2.5991 -9.9822355,5.4934 -15.0926377,3.409 -3.93011277,-1.6034 -6.4288107,-7.7497 -8.9777336,-10.8888 -1.3058522,-1.6034 -2.8628298,-1.8495 -4.8592768,-1.0547 -14.7285059,5.8763 -26.0165929,15.7104 -34.1342219,29.2473 -1.377423,2.1095 -1.127553,3.7619 0.521085,5.7144 2.461029,2.8942 5.537315,6.1802 6.215354,10.0651 0.652926,3.8987 -1.167733,8.4504 -2.787492,11.9184 -2.046672,4.4386 -4.345725,10.7557 -8.784367,13.2669 -4.085811,2.3054 -9.462406,1.0058 -13.096191,-1.9474 -6.273113,-5.1155 -9.304196,-13.1201 -9.216302,-21.0556 0.03014,-2.2564 0.303861,-4.5128 0.833736,-6.68 1.265672,-5.2385 3.68401,-10.1291 6.406209,-14.7787 2.054206,-3.507 4.286711,-6.9009 6.695004,-10.1769 7.871526,-10.6929 17.676718,-19.987 29.01503,-26.9382 5.675435,-3.4718 11.777783,-6.523 18.106143,-8.6073 7.094293,-2.3443 13.4100972,-4.7827 21.056866,-3.3249 8.023457,1.5193 15.921352,6.474 19.110644,14.1986 0.941721,2.2953 1.418859,4.8467 0.891496,7.2588 -1.092396,4.9936 -7.860226,7.9607 -11.903345,10.374"
            fill="#ffffff"
            fillOpacity={1}
            fillRule="evenodd"
            stroke="none"
            strokeWidth={1.25563}
            id="path190-6"
          />
          <g id="g204-9" transform="translate(-753.61447,1471.0558)">
            <g id="g206-9" clipPath="url(#clipPath210-0)">
              <path
                d="m 733.5,729.867 c 0,-80.758 -65.457,-146.219 -146.211,-146.219 -80.75,0 -146.215,65.461 -146.215,146.219 0,80.75 65.465,146.211 146.215,146.211 80.754,0 146.211,-65.461 146.211,-146.211"
                fill="url(#linearGradient2132)"
                fillOpacity={1}
                fillRule="nonzero"
                stroke="none"
                id="path218-0"
              />
            </g>
          </g>
          <path
            d="m -140.84447,2198.1578 -40.262,-19.911 c -1.574,-0.859 -6.938,0.282 -6.938,2.079 v 40.89 c 0,1.817 5.407,2.957 6.985,2.051 l 38.539,-20.969 c 1.617,-0.918 3.308,-3.25 1.676,-4.14 z m 52.799995,24.023 c 0,19.387 -15.721995,35.109 -35.112995,35.109 h -89.777 c -19.391,0 -35.11,-15.722 -35.11,-35.109 v -41.777 c 0,-19.395 15.719,-35.118 35.11,-35.118 h 89.777 c 19.391,0 35.112995,15.723 35.112995,35.118 v 41.777"
            fill="#ffffff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            id="path220-5"
          />
          <g
            id="g264-7"
            transform="matrix(1.1698007,0,0,1.1698007,587.00624,737.67185)"
          >
            <g
              id="g266-6"
              clipPath="url(#clipPath270-0)"
              transform="matrix(1.0936487,0,0,1.0936487,-28.919557,-94.462498)"
            >
              <path
                d="m 308.809,1154.9 c 0,80.75 -65.465,146.22 -146.215,146.22 -80.7502,0 -146.2112,-65.47 -146.2112,-146.22 0,-72.98 53.4649,-133.47 123.3672,-144.43 v 102.16 h -37.129 v 42.27 h 37.129 v 32.22 c 0,36.64 21.828,56.88 55.227,56.88 15.996,0 32.726,-2.86 32.726,-2.86 v -35.98 h -18.437 c -18.164,0 -23.828,-11.27 -23.828,-22.83 v -27.43 h 40.554 l -6.48,-42.27 h -34.074 v -102.16 c 69.902,10.96 123.371,71.45 123.371,144.43"
                fill="url(#linearGradient1437)"
                fillOpacity={1}
                fillRule="nonzero"
                stroke="none"
                id="path278-4"
              />
            </g>
          </g>
          <path
            d="m 834.00903,2050.614 8.29019,54.078 h -51.8828 v 35.0927 c 0,14.7893 7.24624,29.2075 30.48438,29.2075 h 23.58739 v 46.0311 c 0,0 -21.40354,3.659 -41.86804,3.659 -42.72905,0 -70.65472,-25.8941 -70.65472,-72.7695 V 2104.692 H 684.4644 v -54.078 h 47.50103 v -130.6986 c 9.51965,-1.4969 19.28494,-2.2772 29.2255,-2.2772 9.94439,0 19.70456,0.7803 29.22549,2.2772 v 130.6986 h 43.59261"
            fill="#ffffff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none;"
            strokeWidth={1.27935}
            id="path280-3"
          />
          <g id="g74-1" transform="translate(-1368.6938,1026.9976)">
            <g id="g76-3" clipPath="url(#clipPath80-6)">
              <path
                id="path98-5"
                fill="url(#linearGradient1377)"
                fillOpacity={1}
                fillRule="nonzero"
                stroke="none"
                d="m 733.5,1154.7 c 0,-80.75 -65.457,-146.21 -146.211,-146.21 -80.75,0 -146.215,65.46 -146.215,146.21 0,80.76 65.465,146.21 146.215,146.21 80.754,0 146.211,-65.45 146.211,-146.21"
              />
            </g>
          </g>
          <path
            d="m -736.01777,2237.0176 c -5.551,0 -10.051,-4.5 -10.051,-10.05 0,-5.55 4.5,-10.06 10.051,-10.06 5.551,0 10.047,4.51 10.047,10.06 0,5.55 -4.496,10.05 -10.047,10.05"
            fill="#ffffff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            id="path222-5"
          />
          <path
            d="m -780.70177,2154.8576 c -14.91,0 -27.047,12.14 -27.047,27.05 0,14.91 12.137,27.04 27.047,27.04 14.914,0 27.043,-12.13 27.043,-27.04 0,-14.91 -12.129,-27.05 -27.043,-27.05 z m 0,69.27 c -23.281,0 -42.222,-18.94 -42.222,-42.22 0,-23.28 18.941,-42.22 42.222,-42.22 23.281,0 42.219,18.94 42.219,42.22 0,23.28 -18.938,42.22 -42.219,42.22"
            fill="#ffffff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            id="path224-2"
          />
          <path
            d="m -815.62777,2251.4976 c -19.503,0 -35.367,-15.86 -35.367,-35.37 v -68.44 c 0,-19.51 15.864,-35.37 35.367,-35.37 h 68.442 c 19.504,0 35.371,15.86 35.371,35.37 v 68.44 c 0,19.51 -15.867,35.37 -35.371,35.37 z m 68.442,-155.31 h -68.442 c -28.394,0 -51.496,23.1 -51.496,51.5 v 68.44 c 0,28.4 23.102,51.5 51.496,51.5 h 68.442 c 28.394,0 51.5,-23.1 51.5,-51.5 v -68.44 c 0,-28.4 -23.106,-51.5 -51.5,-51.5"
            fill="#ffffff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            id="path226-0"
          />
        </g>
      </g>
    </svg>

    // <svg
    //   width={40}
    //   height={41}
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   {...props}
    // >
    //   <g filter="url(#prefix__filter0_i_256_11)">
    //     <path
    //       fillRule="evenodd"
    //       clipRule="evenodd"
    //       d="M11.248 10.517a.986.986 0 00-.66.544l-.084.178-.01 1.352c-.005.841.003 1.498.022 1.736.203 2.519 1.396 5.201 3.46 7.774.553.69.95 1.132 1.623 1.805a22.168 22.168 0 004.319 3.393c1.802 1.075 3.59 1.749 5.26 1.98.308.044.559.05 1.886.05h1.53l.177-.084a.934.934 0 00.476-.476l.085-.178V23.81l-.085-.178a.938.938 0 00-.476-.475l-.177-.085-2.24-.007-.338-.001A4.57 4.57 0 0022.77 24.4l-1.345 1.345-.405-.244c-.404-.243-.884-.676-1.313-1.107-.814-.817-1.572-1.687-2.385-2.505-.556-.56-1.133-1.099-1.704-1.643-.594-.566-1.307-1.287-1.503-1.657l-.072-.137 1.367-1.367a4.646 4.646 0 001.361-3.3l-.001-.307-.008-2.24-.085-.178a.939.939 0 00-.475-.475l-.178-.085-2.336-.005c-1.305-.002-2.382.007-2.439.02z"
    //       fill="url(#prefix__paint0_linear_256_11)"
    //     />
    //   </g>
    //   <g filter="url(#prefix__filter1_bi_256_11)">
    //     <path
    //       fillRule="evenodd"
    //       clipRule="evenodd"
    //       d="M19.912 39.824c10.997 0 19.912-8.915 19.912-19.912S30.91 0 19.912 0 0 8.915 0 19.912C0 25.7 2.47 30.911 6.412 34.55l-.65 6.45 5.865-2.975a19.838 19.838 0 008.285 1.8zm0-3.99c-8.793 0-15.922-7.128-15.922-15.922C3.99 11.12 11.12 3.99 19.912 3.99c8.794 0 15.922 7.129 15.922 15.922 0 8.794-7.128 15.922-15.922 15.922z"
    //       fill="url(#prefix__paint1_linear_256_11)"
    //     />
    //   </g>
    //   <defs>
    //     <linearGradient
    //       id="prefix__paint0_linear_256_11"
    //       x1={8.255}
    //       y1={11.717}
    //       x2={28.204}
    //       y2={31.092}
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stopColor="#4CB1BF" />
    //       <stop offset={1} stopColor="#003840" />
    //     </linearGradient>
    //     <linearGradient
    //       id="prefix__paint1_linear_256_11"
    //       x1={19.912}
    //       y1={0}
    //       x2={19.912}
    //       y2={41}
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stopColor="#fff" stopOpacity={0.15} />
    //       <stop offset={1} stopColor="#fff" stopOpacity={0.05} />
    //     </linearGradient>
    //     <filter
    //       id="prefix__filter0_i_256_11"
    //       x={10.493}
    //       y={10.496}
    //       width={18.839}
    //       height={20.834}
    //       filterUnits="userSpaceOnUse"
    //       colorInterpolationFilters="sRGB"
    //     >
    //       <feFlood floodOpacity={0} result="BackgroundImageFix" />
    //       <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
    //       <feColorMatrix
    //         in="SourceAlpha"
    //         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
    //         result="hardAlpha"
    //       />
    //       <feOffset dy={2} />
    //       <feGaussianBlur stdDeviation={1.5} />
    //       <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
    //       <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.75 0" />
    //       <feBlend in2="shape" result="effect1_innerShadow_256_11" />
    //     </filter>
    //     <filter
    //       id="prefix__filter1_bi_256_11"
    //       x={-24}
    //       y={-24}
    //       width={87.824}
    //       height={89}
    //       filterUnits="userSpaceOnUse"
    //       colorInterpolationFilters="sRGB"
    //     >
    //       <feFlood floodOpacity={0} result="BackgroundImageFix" />
    //       <feGaussianBlur in="BackgroundImage" stdDeviation={12} />
    //       <feComposite
    //         in2="SourceAlpha"
    //         operator="in"
    //         result="effect1_backgroundBlur_256_11"
    //       />
    //       <feBlend
    //         in="SourceGraphic"
    //         in2="effect1_backgroundBlur_256_11"
    //         result="shape"
    //       />
    //       <feColorMatrix
    //         in="SourceAlpha"
    //         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
    //         result="hardAlpha"
    //       />
    //       <feOffset dy={2} />
    //       <feGaussianBlur stdDeviation={1.5} />
    //       <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
    //       <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.75 0" />
    //       <feBlend in2="shape" result="effect2_innerShadow_256_11" />
    //     </filter>
    //   </defs>
    // </svg>
  );
};

export const Instagram = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 13.229166 13.229167"
      version="1.1"
      id="svg5"
      xmlSpace="preserve"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs id="defs2">
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath68">
          <path
            d="m 643.816,859.25 c -115.98,0 -210,94.02 -210,210 0,115.98 94.02,210 210,210 115.977,0 210,-94.02 210,-210 0,-115.98 -94.023,-210 -210,-210"
            id="path66"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath80">
          <path
            d="m 733.5,1154.7 c 0,-80.75 -65.457,-146.21 -146.211,-146.21 -80.75,0 -146.215,65.46 -146.215,146.21 0,80.76 65.465,146.21 146.215,146.21 80.754,0 146.211,-65.45 146.211,-146.21 z"
            id="path78"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,690.677,1258.09)"
          spreadMethod="pad"
          id="linearGradient96"
        >
          <stop stopOpacity={1} stopColor="#f9d76c" offset="0" id="stop82" />
          <stop
            stopOpacity={1}
            stopColor="#f9d76c"
            offset="0.0011048"
            id="stop84"
          />
          <stop
            stopOpacity={1}
            stopColor="#f9a837"
            offset="0.209357"
            id="stop86"
          />
          <stop
            stopOpacity={1}
            stopColor="#f03d74"
            offset="0.37646"
            id="stop88"
          />
          <stop
            stopOpacity={1}
            stopColor="#e62299"
            offset="0.523815"
            id="stop90"
          />
          <stop
            stopOpacity={1}
            stopColor="#6f3ea2"
            offset="0.739443"
            id="stop92"
          />
          <stop stopOpacity={1} stopColor="#4850ab" offset="1" id="stop94" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath106">
          <path
            d="m 215,432.121 c -118.7422,0 -215,96.258 -215,215 0,29.215 5.82813,57.07 16.3828,82.461 0.1485,-80.621 65.5508,-145.934 146.2112,-145.934 80.75,0 146.215,65.461 146.215,146.219 0,53.348 -28.571,100.02 -71.246,125.555 6.285,0.652 12.484,1.582 18.597,2.766 C 355.215,838.984 430,751.789 430,647.121 c 0,-118.742 -96.262,-215 -215,-215"
            id="path104"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath118">
          <path
            d="m 162.594,583.648 c -80.6604,0 -146.0627,65.313 -146.2112,145.934 0,0.098 0,0.191 0,0.285 0,69.16 48.0156,127.102 112.5272,142.313 26.363,-11.532 55.477,-17.93 86.09,-17.93 7.617,0 15.152,0.398 22.563,1.172 42.675,-25.535 71.246,-72.207 71.246,-125.555 0,-80.758 -65.465,-146.219 -146.215,-146.219"
            id="path116"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,265.983,833.253)"
          spreadMethod="pad"
          id="linearGradient124"
        >
          <stop stopOpacity={1} stopColor="#dfea1f" offset="0" id="stop120" />
          <stop stopOpacity={1} stopColor="#f9a837" offset="1" id="stop122" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath136">
          <path
            d="m 1072.63,859.25 c -115.978,0 -209.997,94.02 -209.997,210 0,115.98 94.019,210 209.997,210 115.98,0 210,-94.02 210,-210 0,-115.98 -94.02,-210 -210,-210"
            id="path134"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath148">
          <path
            d="m 1158.34,1154.7 c 0,-80.75 -65.46,-146.21 -146.21,-146.21 -80.755,0 -146.212,65.46 -146.212,146.21 0,80.76 65.457,146.21 146.212,146.21 80.75,0 146.21,-65.45 146.21,-146.21 z"
            id="path146"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,1115.52,1258.09)"
          spreadMethod="pad"
          id="linearGradient154"
        >
          <stop stopOpacity={1} stopColor="#2fbbe1" offset="0" id="stop150" />
          <stop stopOpacity={1} stopColor="#3970c0" offset="1" id="stop152" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath166">
          <path
            d="m 1072.63,437.121 c -115.978,0 -209.997,94.027 -209.997,210 0,115.977 94.019,210 209.997,210 115.98,0 210,-94.023 210,-210 0,-115.973 -94.02,-210 -210,-210"
            id="path164"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath178">
          <path
            d="m 1158.34,729.867 c 0,-80.758 -65.46,-146.219 -146.21,-146.219 -80.755,0 -146.216,65.461 -146.216,146.219 0,80.75 65.461,146.211 146.216,146.211 80.75,0 146.21,-65.461 146.21,-146.211 z"
            id="path176"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,1115.52,833.252)"
          spreadMethod="pad"
          id="linearGradient184"
        >
          <stop stopOpacity={1} stopColor="#3cba64" offset="0" id="stop180" />
          <stop stopOpacity={1} stopColor="#24b263" offset="1" id="stop182" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath198">
          <path
            d="m 643.816,437.121 c -115.98,0 -210,94.027 -210,210 0,115.977 94.02,210 210,210 115.977,0 210,-94.023 210,-210 0,-115.973 -94.023,-210 -210,-210"
            id="path196"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath210">
          <path
            d="m 733.5,729.867 c 0,-80.758 -65.457,-146.219 -146.211,-146.219 -80.75,0 -146.215,65.461 -146.215,146.219 0,80.75 65.465,146.211 146.215,146.211 80.754,0 146.211,-65.461 146.211,-146.211 z"
            id="path208"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,690.677,833.253)"
          spreadMethod="pad"
          id="linearGradient216"
        >
          <stop stopOpacity={1} stopColor="#ef2f36" offset="0" id="stop212" />
          <stop stopOpacity={1} stopColor="#90191e" offset="1" id="stop214" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath234">
          <path
            d="m 256.16,858.188 c -9.972,1.929 -20.199,3.175 -30.613,3.679 -19.067,9.11 -40.414,14.211 -62.953,14.211 -11.594,0 -22.867,-1.351 -33.684,-3.898 C 53.0273,905.379 0,981.121 0,1069.25 c 0,118.74 96.2578,215 215,215 118.738,0 215,-96.26 215,-215 0,-104.664 -74.785,-191.863 -173.84,-211.062"
            id="path232"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath246">
          <path
            d="m 237.563,855.422 c -3.891,2.328 -7.899,4.48 -12.016,6.445 10.414,-0.504 20.641,-1.75 30.613,-3.679 -6.113,-1.184 -12.312,-2.114 -18.597,-2.766"
            id="path244"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath258">
          <path
            d="m 215,854.25 c -30.613,0 -59.727,6.398 -86.09,17.93 10.817,2.547 22.09,3.898 33.684,3.898 22.539,0 43.886,-5.101 62.953,-14.211 4.117,-1.965 8.125,-4.117 12.016,-6.445 -7.411,-0.774 -14.946,-1.172 -22.563,-1.172"
            id="path256"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath270">
          <path
            d="m 308.809,1154.9 c 0,80.75 -65.465,146.22 -146.215,146.22 -80.7502,0 -146.2112,-65.47 -146.2112,-146.22 0,-72.98 53.4649,-133.47 123.3672,-144.43 v 102.16 h -37.129 v 42.27 h 37.129 v 32.22 c 0,36.64 21.828,56.88 55.227,56.88 15.996,0 32.726,-2.86 32.726,-2.86 v -35.98 h -18.437 c -18.164,0 -23.828,-11.27 -23.828,-22.83 v -27.43 h 40.554 l -6.48,-42.27 h -34.074 v -102.16 c 69.902,10.96 123.371,71.45 123.371,144.43 z"
            id="path268"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(206.777,-206.777,-206.777,-206.777,59.2062,1258.29)"
          spreadMethod="pad"
          id="linearGradient276"
        >
          <stop stopOpacity={1} stopColor="#3f6abc" offset="0" id="stop272" />
          <stop stopOpacity={1} stopColor="#2d368c" offset="1" id="stop274" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath288">
          <path d="M 0,0 H 3459 V 1672 H 0 Z" id="path286" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath294">
          <path
            d="M 215,-0.0117188 C 96.2578,-0.0117188 0,96.25 0,214.988 c 0,118.742 96.2578,215 215,215 118.738,0 215,-96.258 215,-215 C 430,96.25 333.738,-0.0117188 215,-0.0117188"
            id="path292"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath306">
          <path
            d="m 308.809,304.809 c 0,-80.75 -65.465,-146.207 -146.215,-146.207 -80.7542,0 -146.2112,65.457 -146.2112,146.207 0,80.75 65.457,146.222 146.2112,146.222 80.75,0 146.215,-65.472 146.215,-146.222 z"
            id="path304"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,265.983,408.2)"
          spreadMethod="pad"
          id="linearGradient312"
        >
          <stop stopOpacity={1} stopColor="#1679b3" offset="0" id="stop308" />
          <stop stopOpacity={1} stopColor="#2c54ad" offset="1" id="stop310" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath326">
          <path
            d="m 1477.69,855.793 c -106.77,9.906 -190.35,99.742 -190.35,209.097 0,115.98 94.02,210 210,210 115.98,0 210,-94.02 210,-210 0,-107.949 -81.45,-196.874 -186.24,-208.675 -6.47,0.601 -13.02,0.906 -19.65,0.906 -8.04,0 -15.96,-0.449 -23.76,-1.328"
            id="path324"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath338">
          <path
            d="m 1583.05,1154.7 c 0,-80.75 -65.46,-146.21 -146.21,-146.21 -80.75,0 -146.22,65.46 -146.22,146.21 0,80.76 65.47,146.21 146.22,146.21 80.75,0 146.21,-65.45 146.21,-146.21 z"
            id="path336"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(206.778,-206.778,-206.778,-206.778,1333.45,1258.09)"
          spreadMethod="pad"
          id="linearGradient344"
        >
          <stop stopOpacity={1} stopColor="#1b1b3f" offset="0" id="stop340" />
          <stop stopOpacity={1} stopColor="#040606" offset="1" id="stop342" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath360">
          <path
            d="m 643.816,4.98828 c -115.98,0 -210,94.03122 -210,209.99972 0,115.992 94.02,210 210,210 115.977,0 210,-94.008 210,-210 0,-115.9685 -94.023,-209.99972 -210,-209.99972"
            id="path358"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath372">
          <path
            d="m 733.5,304.809 c 0,-80.739 -65.457,-146.207 -146.211,-146.207 -80.75,0 -146.215,65.468 -146.215,146.207 0,80.75 65.465,146.222 146.215,146.222 80.754,0 146.211,-65.472 146.211,-146.222 z"
            id="path370"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,690.677,408.2)"
          spreadMethod="pad"
          id="linearGradient378"
        >
          <stop stopOpacity={1} stopColor="#f14895" offset="0" id="stop374" />
          <stop stopOpacity={1} stopColor="#c91894" offset="1" id="stop376" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath390">
          <path
            d="m 1501.45,437.121 c -115.98,0 -210,94.027 -210,210 0,107.942 81.45,196.867 186.24,208.672 6.47,-0.602 13.03,-0.906 19.65,-0.906 8.04,0 15.96,0.449 23.76,1.328 106.77,-9.906 190.35,-99.742 190.35,-209.094 0,-115.973 -94.02,-210 -210,-210"
            id="path388"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath402">
          <path
            d="m 1497.34,854.887 c -6.62,0 -13.18,0.304 -19.65,0.906 7.8,0.879 15.72,1.328 23.76,1.328 6.63,0 13.18,-0.305 19.65,-0.906 -7.8,-0.879 -15.72,-1.328 -23.76,-1.328"
            id="path400"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath414">
          <path
            d="m 1583.18,729.867 c 0,-80.758 -65.46,-146.219 -146.21,-146.219 -80.75,0 -146.21,65.461 -146.21,146.219 0,80.75 65.46,146.211 146.21,146.211 80.75,0 146.21,-65.461 146.21,-146.211 z"
            id="path412"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,1540.36,833.253)"
          spreadMethod="pad"
          id="linearGradient420"
        >
          <stop stopOpacity={1} stopColor="#2e68bb" offset="0" id="stop416" />
          <stop stopOpacity={1} stopColor="#2d368c" offset="1" id="stop418" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath436">
          <path
            d="m 1501.45,4.98828 c -115.98,0 -210,94.03122 -210,209.99972 0,115.992 94.02,210 210,210 115.98,0 210,-94.008 210,-210 0,-115.9685 -94.02,-209.99972 -210,-209.99972"
            id="path434"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath448">
          <path
            d="m 1583.18,304.809 c 0,-80.75 -65.46,-146.207 -146.21,-146.207 -80.75,0 -146.21,65.457 -146.21,146.207 0,80.75 65.46,146.222 146.21,146.222 80.75,0 146.21,-65.472 146.21,-146.222 z"
            id="path446"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,1540.36,408.2)"
          spreadMethod="pad"
          id="linearGradient454"
        >
          <stop stopOpacity={1} stopColor="#19ae5b" offset="0" id="stop450" />
          <stop stopOpacity={1} stopColor="#069757" offset="1" id="stop452" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath470">
          <path
            d="m 1076.61,9.32031 c -115.981,0 -210.001,94.00769 -210.001,209.99969 0,115.981 94.02,210 210.001,210 115.98,0 210,-94.019 210,-210 0,-115.992 -94.02,-209.99969 -210,-209.99969"
            id="path468"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath482">
          <path
            d="m 1158.34,304.77 c 0,-80.75 -65.46,-146.211 -146.21,-146.211 -80.755,0 -146.212,65.461 -146.212,146.211 0,80.75 65.457,146.21 146.212,146.21 80.75,0 146.21,-65.46 146.21,-146.21 z"
            id="path480"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,1115.52,408.16)"
          spreadMethod="pad"
          id="linearGradient488"
        >
          <stop stopOpacity={1} stopColor="#f04148" offset="0" id="stop484" />
          <stop stopOpacity={1} stopColor="#e51a46" offset="1" id="stop486" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath500">
          <path
            d="m 2391.45,859.25 c -115.97,0 -210,94.02 -210,210 0,115.98 94.03,210 210,210 115.99,0 210,-94.02 210,-210 0,-115.98 -94.01,-210 -210,-210"
            id="path498"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath514">
          <path
            d="m 1962.65,432.121 c -118.75,0 -215,96.258 -215,215 0,29.215 5.82,57.059 16.37,82.453 0.16,-80.613 65.56,-145.926 146.21,-145.926 80.76,0 146.21,65.461 146.21,146.219 0,53.348 -28.56,100.02 -71.24,125.555 6.29,0.652 12.49,1.582 18.61,2.766 99.05,-19.204 173.84,-106.399 173.84,-211.067 0,-118.742 -96.26,-215 -215,-215"
            id="path512"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath530">
          <path
            d="m 2820.27,859.25 c -115.97,0 -210,94.02 -210,210 0,115.98 94.03,210 210,210 115.98,0 210,-94.02 210,-210 0,-115.98 -94.02,-210 -210,-210"
            id="path528"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath546">
          <path
            d="m 2820.27,437.121 c -115.97,0 -210,94.027 -210,210 0,115.977 94.03,210 210,210 115.98,0 210,-94.023 210,-210 0,-115.973 -94.02,-210 -210,-210"
            id="path544"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath564">
          <path
            d="m 2391.45,437.121 c -115.97,0 -210,94.027 -210,210 0,115.977 94.03,210 210,210 115.99,0 210,-94.023 210,-210 0,-115.973 -94.01,-210 -210,-210"
            id="path562"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath586">
          <path
            d="m 2003.81,858.188 c -9.98,1.929 -20.21,3.175 -30.62,3.679 -19.07,9.11 -40.42,14.211 -62.96,14.211 -11.59,0 -22.86,-1.348 -33.68,-3.898 -75.88,33.199 -128.9,108.941 -128.9,197.07 0,118.74 96.25,215 215,215 118.74,0 215,-96.26 215,-215 0,-104.664 -74.79,-191.863 -173.84,-211.062"
            id="path584"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath598">
          <path
            d="m 1985.2,855.422 c -3.89,2.324 -7.9,4.48 -12.01,6.445 10.41,-0.504 20.64,-1.75 30.62,-3.679 -6.12,-1.184 -12.32,-2.114 -18.61,-2.766"
            id="path596"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath610">
          <path
            d="m 1962.65,854.25 c -30.62,0 -59.74,6.398 -86.1,17.93 10.82,2.55 22.09,3.898 33.68,3.898 22.54,0 43.89,-5.101 62.96,-14.211 4.11,-1.965 8.12,-4.121 12.01,-6.445 -7.41,-0.774 -14.94,-1.172 -22.55,-1.172"
            id="path608"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath626">
          <path d="M 0,0 H 3459 V 1672 H 0 Z" id="path624" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath632">
          <path
            d="m 1962.65,-0.0117188 c -118.75,0 -215,96.2617188 -215,214.9997188 0,118.742 96.25,215 215,215 118.74,0 215,-96.258 215,-215 0,-118.738 -96.26,-214.9997188 -215,-214.9997188"
            id="path630"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath650">
          <path
            d="m 3225.33,855.793 c -106.76,9.906 -190.35,99.742 -190.35,209.097 0,115.98 94.02,210 210,210 115.99,0 210,-94.02 210,-210 0,-107.949 -81.44,-196.874 -186.24,-208.675 -6.47,0.601 -13.02,0.906 -19.65,0.906 -8.03,0 -15.96,-0.449 -23.76,-1.328"
            id="path648"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath666">
          <path
            d="m 2391.45,4.98828 c -115.97,0 -210,94.03122 -210,209.99972 0,115.992 94.03,210 210,210 115.99,0 210,-94.008 210,-210 0,-115.9685 -94.01,-209.99972 -210,-209.99972"
            id="path664"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath682">
          <path d="M 0,0 H 3459 V 1672 H 0 Z" id="path680" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath688">
          <path
            d="m 3249.09,437.121 c -115.98,0 -210,94.027 -210,210 0,107.942 81.45,196.867 186.24,208.672 6.47,-0.602 13.02,-0.906 19.65,-0.906 8.03,0 15.96,0.449 23.76,1.328 106.77,-9.906 190.35,-99.742 190.35,-209.094 0,-115.973 -94.02,-210 -210,-210"
            id="path686"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath700">
          <path
            d="m 3244.98,854.887 c -6.63,0 -13.18,0.304 -19.65,0.906 7.8,0.879 15.73,1.328 23.76,1.328 6.63,0 13.18,-0.305 19.65,-0.906 -7.8,-0.879 -15.73,-1.328 -23.76,-1.328"
            id="path698"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath720">
          <path d="M 0,0 H 3459 V 1672 H 0 Z" id="path718" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath726">
          <path
            d="m 3249.09,4.98828 c -115.98,0 -210,94.03122 -210,209.99972 0,115.992 94.02,210 210,210 115.98,0 210,-94.008 210,-210 0,-115.9685 -94.02,-209.99972 -210,-209.99972"
            id="path724"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath746">
          <path
            d="m 2824.25,9.32031 c -115.98,0 -210,94.00769 -210,209.99969 0,115.981 94.02,210 210,210 115.98,0 210,-94.019 210,-210 0,-115.992 -94.02,-209.99969 -210,-209.99969"
            id="path744"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath80-6">
          <path
            d="m 733.5,1154.7 c 0,-80.75 -65.457,-146.21 -146.211,-146.21 -80.75,0 -146.215,65.46 -146.215,146.21 0,80.76 65.465,146.21 146.215,146.21 80.754,0 146.211,-65.45 146.211,-146.21 z"
            id="path78-2"
          />
        </clipPath>
        <linearGradient
          xlinkHref="#linearGradient96"
          id="linearGradient1377"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,690.677,1258.09)"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
        />
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath270-0">
          <path
            d="m 308.809,1154.9 c 0,80.75 -65.465,146.22 -146.215,146.22 -80.7502,0 -146.2112,-65.47 -146.2112,-146.22 0,-72.98 53.4649,-133.47 123.3672,-144.43 v 102.16 h -37.129 v 42.27 h 37.129 v 32.22 c 0,36.64 21.828,56.88 55.227,56.88 15.996,0 32.726,-2.86 32.726,-2.86 v -35.98 h -18.437 c -18.164,0 -23.828,-11.27 -23.828,-22.83 v -27.43 h 40.554 l -6.48,-42.27 h -34.074 v -102.16 c 69.902,10.96 123.371,71.45 123.371,144.43 z"
            id="path268-7"
          />
        </clipPath>
        <linearGradient
          xlinkHref="#linearGradient276"
          id="linearGradient1437"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(206.777,-206.777,-206.777,-206.777,59.2062,1258.29)"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
        />
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath210-0">
          <path
            d="m 733.5,729.867 c 0,-80.758 -65.457,-146.219 -146.211,-146.219 -80.75,0 -146.215,65.461 -146.215,146.219 0,80.75 65.465,146.211 146.215,146.211 80.754,0 146.211,-65.461 146.211,-146.211 z"
            id="path208-9"
          />
        </clipPath>
        <linearGradient
          xlinkHref="#linearGradient216"
          id="linearGradient2132"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,690.677,833.253)"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
        />
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath178-9">
          <path
            d="m 1158.34,729.867 c 0,-80.758 -65.46,-146.219 -146.21,-146.219 -80.755,0 -146.216,65.461 -146.216,146.219 0,80.75 65.461,146.211 146.216,146.211 80.75,0 146.21,-65.461 146.21,-146.211 z"
            id="path176-3"
          />
        </clipPath>
        <linearGradient
          xlinkHref="#linearGradient184"
          id="linearGradient2265"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,1115.52,833.252)"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
        />
      </defs>
      <g id="layer1">
        <g
          id="g10"
          transform="matrix(0.03527778,0,0,-0.03527778,7.675884,64.985998)"
        >
          <g
            id="g172-2"
            transform="matrix(1.2556271,0,0,1.2556271,-1298.9252,739.30343)"
          >
            <g
              id="g174-4"
              clipPath="url(#clipPath178-9)"
              transform="translate(277.82728,375.24723)"
            >
              <path
                d="m 1158.34,729.867 c 0,-80.758 -65.46,-146.219 -146.21,-146.219 -80.755,0 -146.216,65.461 -146.216,146.219 0,80.75 65.461,146.211 146.216,146.211 80.75,0 146.21,-65.461 146.21,-146.211"
                fill="url(#linearGradient2265)"
                fillOpacity={1}
                fillRule="nonzero"
                stroke="none"
                id="path186-0"
              />
            </g>
          </g>
          <path
            d="m 281.84438,2059.8028 3.61998,-2.1584 c 13.27197,-7.9117 28.37214,-12.0904 43.65313,-12.0904 47.16135,0 85.52076,38.3707 85.52076,85.5157 0,47.1589 -38.35941,85.5296 -85.52076,85.5296 -47.15006,0 -85.51575,-38.3707 -85.51575,-85.5296 0,-15.5735 4.32062,-30.901 12.5073,-44.3399 l 2.21744,-3.6389 -8.54454,-31.8866 z m -60.25504,-36.7861 15.30233,57.116 c -8.66131,15.6376 -13.22301,33.1573 -13.22301,50.937 0,58.1456 47.30701,105.4526 105.44883,105.4526 58.14809,0 105.46011,-47.307 105.46011,-105.4526 0,-58.1468 -47.31202,-105.4538 -105.46011,-105.4538 -17.45322,0 -34.69172,4.4198 -50.12213,12.7823 l -57.40602,-15.3815"
            fill="#ffffff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            strokeWidth={1.25563}
            id="path188-8"
          />
          <path
            d="m 368.34328,2116.404 c -4.33192,2.5991 -9.98224,5.4934 -15.09264,3.409 -3.93011,-1.6034 -6.42881,-7.7497 -8.97773,-10.8888 -1.30586,-1.6034 -2.86283,-1.8495 -4.85928,-1.0547 -14.72851,5.8763 -26.01659,15.7104 -34.13422,29.2473 -1.37743,2.1095 -1.12756,3.7619 0.52108,5.7144 2.46103,2.8942 5.53732,6.1802 6.21536,10.0651 0.65292,3.8987 -1.16774,8.4504 -2.7875,11.9184 -2.04667,4.4386 -4.34572,10.7557 -8.78436,13.2669 -4.08581,2.3054 -9.46241,1.0058 -13.09619,-1.9474 -6.27312,-5.1155 -9.3042,-13.1201 -9.21631,-21.0556 0.0301,-2.2564 0.30386,-4.5128 0.83374,-6.68 1.26567,-5.2385 3.68401,-10.1291 6.40621,-14.7787 2.0542,-3.507 4.28671,-6.9009 6.695,-10.1769 7.87153,-10.6929 17.67672,-19.987 29.01503,-26.9382 5.67544,-3.4718 11.77779,-6.523 18.10615,-8.6073 7.09429,-2.3443 13.41009,-4.7827 21.05686,-3.3249 8.02346,1.5193 15.92135,6.474 19.11065,14.1986 0.94172,2.2953 1.41885,4.8467 0.89149,7.2588 -1.09239,4.9936 -7.86022,7.9607 -11.90334,10.374"
            fill="#ffffff"
            fillOpacity={1}
            fillRule="evenodd"
            stroke="none"
            strokeWidth={1.25563}
            id="path190-6"
          />
          <g id="g204-9" transform="translate(-753.61447,1471.0558)">
            <g id="g206-9" clipPath="url(#clipPath210-0)">
              <path
                d="m 733.5,729.867 c 0,-80.758 -65.457,-146.219 -146.211,-146.219 -80.75,0 -146.215,65.461 -146.215,146.219 0,80.75 65.465,146.211 146.215,146.211 80.754,0 146.211,-65.461 146.211,-146.211"
                fill="url(#linearGradient2132)"
                fillOpacity={1}
                fillRule="nonzero"
                stroke="none"
                id="path218-0"
              />
            </g>
          </g>
          <path
            d="m -140.84447,2198.1578 -40.262,-19.911 c -1.574,-0.859 -6.938,0.282 -6.938,2.079 v 40.89 c 0,1.817 5.407,2.957 6.985,2.051 l 38.539,-20.969 c 1.617,-0.918 3.308,-3.25 1.676,-4.14 z m 52.799995,24.023 c 0,19.387 -15.721995,35.109 -35.112995,35.109 h -89.777 c -19.391,0 -35.11,-15.722 -35.11,-35.109 v -41.777 c 0,-19.395 15.719,-35.118 35.11,-35.118 h 89.777 c 19.391,0 35.112995,15.723 35.112995,35.118 v 41.777"
            fill="#ffffff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            id="path220-5"
          />
          <g
            id="g264-7"
            transform="matrix(1.1698007,0,0,1.1698007,587.00624,737.67185)"
          >
            <g
              id="g266-6"
              clipPath="url(#clipPath270-0)"
              transform="matrix(1.0936487,0,0,1.0936487,-28.919557,-94.462498)"
            >
              <path
                d="m 308.809,1154.9 c 0,80.75 -65.465,146.22 -146.215,146.22 -80.7502,0 -146.2112,-65.47 -146.2112,-146.22 0,-72.98 53.4649,-133.47 123.3672,-144.43 v 102.16 h -37.129 v 42.27 h 37.129 v 32.22 c 0,36.64 21.828,56.88 55.227,56.88 15.996,0 32.726,-2.86 32.726,-2.86 v -35.98 h -18.437 c -18.164,0 -23.828,-11.27 -23.828,-22.83 v -27.43 h 40.554 l -6.48,-42.27 h -34.074 v -102.16 c 69.902,10.96 123.371,71.45 123.371,144.43"
                fill="url(#linearGradient1437)"
                fillOpacity={1}
                fillRule="nonzero"
                stroke="none"
                id="path278-4"
              />
            </g>
          </g>
          <path
            d="m 834.00903,2050.614 8.29019,54.078 h -51.8828 v 35.0927 c 0,14.7893 7.24624,29.2075 30.48438,29.2075 h 23.58739 v 46.0311 c 0,0 -21.40354,3.659 -41.86804,3.659 -42.72905,0 -70.65472,-25.8941 -70.65472,-72.7695 V 2104.692 H 684.4644 v -54.078 h 47.50103 v -130.6986 c 9.51965,-1.4969 19.28494,-2.2772 29.2255,-2.2772 9.94439,0 19.70456,0.7803 29.22549,2.2772 v 130.6986 h 43.59261"
            fill="#ffffff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            strokeWidth={1.27935}
            id="path280-3"
          />
          <g
            id="g74-1"
            transform="matrix(1.2323955,0,0,1.2323955,-753.11533,228.58343)"
          >
            <g id="g76-3" clipPath="url(#clipPath80-6)">
              <path
                id="path98-5"
                fill="url(#linearGradient1377)"
                fillOpacity={1}
                fillRule="nonzero"
                stroke="none"
                d="m 733.5,1154.7 c 0,-80.75 -65.457,-146.21 -146.211,-146.21 -80.75,0 -146.215,65.46 -146.215,146.21 0,80.76 65.465,146.21 146.215,146.21 80.754,0 146.211,-65.45 146.211,-146.21"
              />
            </g>
          </g>
          <path
            d="m 19.495102,1712.3423 c -6.635426,0 -12.0145313,-5.3791 -12.0145313,-12.0134 0,-6.6342 5.3791053,-12.0253 12.0145313,-12.0253 6.635426,0 12.00975,5.3911 12.00975,12.0253 0,6.6343 -5.374324,12.0134 -12.00975,12.0134"
            fill="#ffffff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            strokeWidth={1.19536}
            id="path222-5"
          />
          <path
            d="m -33.918222,1614.1317 c -17.82277,0 -32.330816,14.5117 -32.330816,32.3344 0,17.8228 14.508046,32.3225 32.330816,32.3225 17.827552,0 32.3260345,-14.4997 32.3260345,-32.3225 0,-17.8227 -14.4984825,-32.3344 -32.3260345,-32.3344 z m 0,82.8024 c -27.829102,0 -50.470353,-22.6401 -50.470353,-50.468 0,-27.8279 22.641251,-50.4679 50.470353,-50.4679 27.8291021,0 50.466769,22.64 50.466769,50.4679 0,27.8279 -22.6376669,50.468 -50.466769,50.468"
            fill="#ffffff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            strokeWidth={1.19536}
            id="path224-2"
          />
          <path
            d="m -75.667254,1729.651 c -23.313042,0 -42.276186,-18.9583 -42.276186,-42.2797 v -81.8103 c 0,-23.3214 18.963144,-42.2797 42.276186,-42.2797 H 6.1453571 c 23.3142389,0 42.2809659,18.9583 42.2809659,42.2797 v 81.8103 c 0,23.3214 -18.966727,42.2797 -42.2809659,42.2797 z M 6.1453571,1544.0002 H -75.667254 c -33.940956,0 -61.556096,27.6127 -61.556096,61.5608 v 81.8103 c 0,33.9481 27.61514,61.5608 61.556096,61.5608 H 6.1453571 c 33.9409619,0 61.5608759,-27.6127 61.5608759,-61.5608 v -81.8103 c 0,-33.9481 -27.619914,-61.5608 -61.5608759,-61.5608"
            fill="#ffffff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            strokeWidth={1.19536}
            id="path226-0"
          />
        </g>
      </g>
    </svg>

    // <svg
    //   width={41}
    //   height={41}
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   {...props}
    // >
    //   <g filter="url(#prefix__filter0_i_256_7)">
    //     <rect
    //       width={41}
    //       height={41}
    //       rx={10}
    //       fill="url(#prefix__paint0_linear_256_7)"
    //     />
    //   </g>
    //   <g filter="url(#prefix__filter1_bi_256_7)">
    //     <circle
    //       cx={20.5}
    //       cy={20.499}
    //       r={6.887}
    //       fill="url(#prefix__paint1_linear_256_7)"
    //     />
    //   </g>
    //   <g filter="url(#prefix__filter2_bi_256_7)">
    //     <circle
    //       cx={31.682}
    //       cy={8.589}
    //       r={2.351}
    //       fill="url(#prefix__paint2_linear_256_7)"
    //     />
    //   </g>
    //   <defs>
    //     <linearGradient
    //       id="prefix__paint0_linear_256_7"
    //       x1={0}
    //       y1={0}
    //       x2={41}
    //       y2={41}
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stopColor="#fff" stopOpacity={0.15} />
    //       <stop offset={1} stopColor="#fff" stopOpacity={0.05} />
    //     </linearGradient>
    //     <linearGradient
    //       id="prefix__paint1_linear_256_7"
    //       x1={15.885}
    //       y1={15.21}
    //       x2={30.142}
    //       y2={20.665}
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stopColor="#006674" />
    //       <stop offset={1} stopColor="#003F6C" />
    //     </linearGradient>
    //     <linearGradient
    //       id="prefix__paint2_linear_256_7"
    //       x1={30.107}
    //       y1={6.784}
    //       x2={34.973}
    //       y2={8.646}
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stopColor="#006674" />
    //       <stop offset={1} stopColor="#003F6C" />
    //     </linearGradient>
    //     <filter
    //       id="prefix__filter0_i_256_7"
    //       x={0}
    //       y={0}
    //       width={41}
    //       height={43}
    //       filterUnits="userSpaceOnUse"
    //       colorInterpolationFilters="sRGB"
    //     >
    //       <feFlood floodOpacity={0} result="BackgroundImageFix" />
    //       <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
    //       <feColorMatrix
    //         in="SourceAlpha"
    //         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
    //         result="hardAlpha"
    //       />
    //       <feOffset dy={2} />
    //       <feGaussianBlur stdDeviation={1.5} />
    //       <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
    //       <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.85 0" />
    //       <feBlend in2="shape" result="effect1_innerShadow_256_7" />
    //     </filter>
    //     <filter
    //       id="prefix__filter1_bi_256_7"
    //       x={10.613}
    //       y={10.611}
    //       width={19.775}
    //       height={19.775}
    //       filterUnits="userSpaceOnUse"
    //       colorInterpolationFilters="sRGB"
    //     >
    //       <feFlood floodOpacity={0} result="BackgroundImageFix" />
    //       <feGaussianBlur in="BackgroundImage" stdDeviation={1.5} />
    //       <feComposite
    //         in2="SourceAlpha"
    //         operator="in"
    //         result="effect1_backgroundBlur_256_7"
    //       />
    //       <feBlend
    //         in="SourceGraphic"
    //         in2="effect1_backgroundBlur_256_7"
    //         result="shape"
    //       />
    //       <feColorMatrix
    //         in="SourceAlpha"
    //         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
    //         result="hardAlpha"
    //       />
    //       <feOffset />
    //       <feGaussianBlur stdDeviation={1.5} />
    //       <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
    //       <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.75 0" />
    //       <feBlend in2="shape" result="effect2_innerShadow_256_7" />
    //     </filter>
    //     <filter
    //       id="prefix__filter2_bi_256_7"
    //       x={26.331}
    //       y={3.238}
    //       width={10.702}
    //       height={10.701}
    //       filterUnits="userSpaceOnUse"
    //       colorInterpolationFilters="sRGB"
    //     >
    //       <feFlood floodOpacity={0} result="BackgroundImageFix" />
    //       <feGaussianBlur in="BackgroundImage" stdDeviation={1.5} />
    //       <feComposite
    //         in2="SourceAlpha"
    //         operator="in"
    //         result="effect1_backgroundBlur_256_7"
    //       />
    //       <feBlend
    //         in="SourceGraphic"
    //         in2="effect1_backgroundBlur_256_7"
    //         result="shape"
    //       />
    //       <feColorMatrix
    //         in="SourceAlpha"
    //         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
    //         result="hardAlpha"
    //       />
    //       <feOffset />
    //       <feGaussianBlur stdDeviation={1.5} />
    //       <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
    //       <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.65 0" />
    //       <feBlend in2="shape" result="effect2_innerShadow_256_7" />
    //     </filter>
    //   </defs>
    // </svg>
  );
};

export const Youtube = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 13.229166 13.229167"
      version="1.1"
      id="svg5"
      xmlSpace="preserve"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs id="defs2">
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath68">
          <path
            d="m 643.816,859.25 c -115.98,0 -210,94.02 -210,210 0,115.98 94.02,210 210,210 115.977,0 210,-94.02 210,-210 0,-115.98 -94.023,-210 -210,-210"
            id="path66"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath80">
          <path
            d="m 733.5,1154.7 c 0,-80.75 -65.457,-146.21 -146.211,-146.21 -80.75,0 -146.215,65.46 -146.215,146.21 0,80.76 65.465,146.21 146.215,146.21 80.754,0 146.211,-65.45 146.211,-146.21 z"
            id="path78"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,690.677,1258.09)"
          spreadMethod="pad"
          id="linearGradient96"
        >
          <stop stopOpacity={1} stopColor="#f9d76c" offset="0" id="stop82" />
          <stop
            stopOpacity={1}
            stopColor="#f9d76c"
            offset="0.0011048"
            id="stop84"
          />
          <stop
            stopOpacity={1}
            stopColor="#f9a837"
            offset="0.209357"
            id="stop86"
          />
          <stop
            stopOpacity={1}
            stopColor="#f03d74"
            offset="0.37646"
            id="stop88"
          />
          <stop
            stopOpacity={1}
            stopColor="#e62299"
            offset="0.523815"
            id="stop90"
          />
          <stop
            stopOpacity={1}
            stopColor="#6f3ea2"
            offset="0.739443"
            id="stop92"
          />
          <stop stopOpacity={1} stopColor="#4850ab" offset="1" id="stop94" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath106">
          <path
            d="m 215,432.121 c -118.7422,0 -215,96.258 -215,215 0,29.215 5.82813,57.07 16.3828,82.461 0.1485,-80.621 65.5508,-145.934 146.2112,-145.934 80.75,0 146.215,65.461 146.215,146.219 0,53.348 -28.571,100.02 -71.246,125.555 6.285,0.652 12.484,1.582 18.597,2.766 C 355.215,838.984 430,751.789 430,647.121 c 0,-118.742 -96.262,-215 -215,-215"
            id="path104"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath118">
          <path
            d="m 162.594,583.648 c -80.6604,0 -146.0627,65.313 -146.2112,145.934 0,0.098 0,0.191 0,0.285 0,69.16 48.0156,127.102 112.5272,142.313 26.363,-11.532 55.477,-17.93 86.09,-17.93 7.617,0 15.152,0.398 22.563,1.172 42.675,-25.535 71.246,-72.207 71.246,-125.555 0,-80.758 -65.465,-146.219 -146.215,-146.219"
            id="path116"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,265.983,833.253)"
          spreadMethod="pad"
          id="linearGradient124"
        >
          <stop stopOpacity={1} stopColor="#dfea1f" offset="0" id="stop120" />
          <stop stopOpacity={1} stopColor="#f9a837" offset="1" id="stop122" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath136">
          <path
            d="m 1072.63,859.25 c -115.978,0 -209.997,94.02 -209.997,210 0,115.98 94.019,210 209.997,210 115.98,0 210,-94.02 210,-210 0,-115.98 -94.02,-210 -210,-210"
            id="path134"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath148">
          <path
            d="m 1158.34,1154.7 c 0,-80.75 -65.46,-146.21 -146.21,-146.21 -80.755,0 -146.212,65.46 -146.212,146.21 0,80.76 65.457,146.21 146.212,146.21 80.75,0 146.21,-65.45 146.21,-146.21 z"
            id="path146"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,1115.52,1258.09)"
          spreadMethod="pad"
          id="linearGradient154"
        >
          <stop stopOpacity={1} stopColor="#2fbbe1" offset="0" id="stop150" />
          <stop stopOpacity={1} stopColor="#3970c0" offset="1" id="stop152" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath166">
          <path
            d="m 1072.63,437.121 c -115.978,0 -209.997,94.027 -209.997,210 0,115.977 94.019,210 209.997,210 115.98,0 210,-94.023 210,-210 0,-115.973 -94.02,-210 -210,-210"
            id="path164"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath178">
          <path
            d="m 1158.34,729.867 c 0,-80.758 -65.46,-146.219 -146.21,-146.219 -80.755,0 -146.216,65.461 -146.216,146.219 0,80.75 65.461,146.211 146.216,146.211 80.75,0 146.21,-65.461 146.21,-146.211 z"
            id="path176"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,1115.52,833.252)"
          spreadMethod="pad"
          id="linearGradient184"
        >
          <stop stopOpacity={1} stopColor="#3cba64" offset="0" id="stop180" />
          <stop stopOpacity={1} stopColor="#24b263" offset="1" id="stop182" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath198">
          <path
            d="m 643.816,437.121 c -115.98,0 -210,94.027 -210,210 0,115.977 94.02,210 210,210 115.977,0 210,-94.023 210,-210 0,-115.973 -94.023,-210 -210,-210"
            id="path196"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath210">
          <path
            d="m 733.5,729.867 c 0,-80.758 -65.457,-146.219 -146.211,-146.219 -80.75,0 -146.215,65.461 -146.215,146.219 0,80.75 65.465,146.211 146.215,146.211 80.754,0 146.211,-65.461 146.211,-146.211 z"
            id="path208"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,690.677,833.253)"
          spreadMethod="pad"
          id="linearGradient216"
        >
          <stop stopOpacity={1} stopColor="#ef2f36" offset="0" id="stop212" />
          <stop stopOpacity={1} stopColor="#90191e" offset="1" id="stop214" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath234">
          <path
            d="m 256.16,858.188 c -9.972,1.929 -20.199,3.175 -30.613,3.679 -19.067,9.11 -40.414,14.211 -62.953,14.211 -11.594,0 -22.867,-1.351 -33.684,-3.898 C 53.0273,905.379 0,981.121 0,1069.25 c 0,118.74 96.2578,215 215,215 118.738,0 215,-96.26 215,-215 0,-104.664 -74.785,-191.863 -173.84,-211.062"
            id="path232"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath246">
          <path
            d="m 237.563,855.422 c -3.891,2.328 -7.899,4.48 -12.016,6.445 10.414,-0.504 20.641,-1.75 30.613,-3.679 -6.113,-1.184 -12.312,-2.114 -18.597,-2.766"
            id="path244"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath258">
          <path
            d="m 215,854.25 c -30.613,0 -59.727,6.398 -86.09,17.93 10.817,2.547 22.09,3.898 33.684,3.898 22.539,0 43.886,-5.101 62.953,-14.211 4.117,-1.965 8.125,-4.117 12.016,-6.445 -7.411,-0.774 -14.946,-1.172 -22.563,-1.172"
            id="path256"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath270">
          <path
            d="m 308.809,1154.9 c 0,80.75 -65.465,146.22 -146.215,146.22 -80.7502,0 -146.2112,-65.47 -146.2112,-146.22 0,-72.98 53.4649,-133.47 123.3672,-144.43 v 102.16 h -37.129 v 42.27 h 37.129 v 32.22 c 0,36.64 21.828,56.88 55.227,56.88 15.996,0 32.726,-2.86 32.726,-2.86 v -35.98 h -18.437 c -18.164,0 -23.828,-11.27 -23.828,-22.83 v -27.43 h 40.554 l -6.48,-42.27 h -34.074 v -102.16 c 69.902,10.96 123.371,71.45 123.371,144.43 z"
            id="path268"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(206.777,-206.777,-206.777,-206.777,59.2062,1258.29)"
          spreadMethod="pad"
          id="linearGradient276"
        >
          <stop stopOpacity={1} stopColor="#3f6abc" offset="0" id="stop272" />
          <stop stopOpacity={1} stopColor="#2d368c" offset="1" id="stop274" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath288">
          <path d="M 0,0 H 3459 V 1672 H 0 Z" id="path286" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath294">
          <path
            d="M 215,-0.0117188 C 96.2578,-0.0117188 0,96.25 0,214.988 c 0,118.742 96.2578,215 215,215 118.738,0 215,-96.258 215,-215 C 430,96.25 333.738,-0.0117188 215,-0.0117188"
            id="path292"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath306">
          <path
            d="m 308.809,304.809 c 0,-80.75 -65.465,-146.207 -146.215,-146.207 -80.7542,0 -146.2112,65.457 -146.2112,146.207 0,80.75 65.457,146.222 146.2112,146.222 80.75,0 146.215,-65.472 146.215,-146.222 z"
            id="path304"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,265.983,408.2)"
          spreadMethod="pad"
          id="linearGradient312"
        >
          <stop stopOpacity={1} stopColor="#1679b3" offset="0" id="stop308" />
          <stop stopOpacity={1} stopColor="#2c54ad" offset="1" id="stop310" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath326">
          <path
            d="m 1477.69,855.793 c -106.77,9.906 -190.35,99.742 -190.35,209.097 0,115.98 94.02,210 210,210 115.98,0 210,-94.02 210,-210 0,-107.949 -81.45,-196.874 -186.24,-208.675 -6.47,0.601 -13.02,0.906 -19.65,0.906 -8.04,0 -15.96,-0.449 -23.76,-1.328"
            id="path324"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath338">
          <path
            d="m 1583.05,1154.7 c 0,-80.75 -65.46,-146.21 -146.21,-146.21 -80.75,0 -146.22,65.46 -146.22,146.21 0,80.76 65.47,146.21 146.22,146.21 80.75,0 146.21,-65.45 146.21,-146.21 z"
            id="path336"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(206.778,-206.778,-206.778,-206.778,1333.45,1258.09)"
          spreadMethod="pad"
          id="linearGradient344"
        >
          <stop stopOpacity={1} stopColor="#1b1b3f" offset="0" id="stop340" />
          <stop stopOpacity={1} stopColor="#040606" offset="1" id="stop342" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath360">
          <path
            d="m 643.816,4.98828 c -115.98,0 -210,94.03122 -210,209.99972 0,115.992 94.02,210 210,210 115.977,0 210,-94.008 210,-210 0,-115.9685 -94.023,-209.99972 -210,-209.99972"
            id="path358"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath372">
          <path
            d="m 733.5,304.809 c 0,-80.739 -65.457,-146.207 -146.211,-146.207 -80.75,0 -146.215,65.468 -146.215,146.207 0,80.75 65.465,146.222 146.215,146.222 80.754,0 146.211,-65.472 146.211,-146.222 z"
            id="path370"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,690.677,408.2)"
          spreadMethod="pad"
          id="linearGradient378"
        >
          <stop stopOpacity={1} stopColor="#f14895" offset="0" id="stop374" />
          <stop stopOpacity={1} stopColor="#c91894" offset="1" id="stop376" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath390">
          <path
            d="m 1501.45,437.121 c -115.98,0 -210,94.027 -210,210 0,107.942 81.45,196.867 186.24,208.672 6.47,-0.602 13.03,-0.906 19.65,-0.906 8.04,0 15.96,0.449 23.76,1.328 106.77,-9.906 190.35,-99.742 190.35,-209.094 0,-115.973 -94.02,-210 -210,-210"
            id="path388"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath402">
          <path
            d="m 1497.34,854.887 c -6.62,0 -13.18,0.304 -19.65,0.906 7.8,0.879 15.72,1.328 23.76,1.328 6.63,0 13.18,-0.305 19.65,-0.906 -7.8,-0.879 -15.72,-1.328 -23.76,-1.328"
            id="path400"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath414">
          <path
            d="m 1583.18,729.867 c 0,-80.758 -65.46,-146.219 -146.21,-146.219 -80.75,0 -146.21,65.461 -146.21,146.219 0,80.75 65.46,146.211 146.21,146.211 80.75,0 146.21,-65.461 146.21,-146.211 z"
            id="path412"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,1540.36,833.253)"
          spreadMethod="pad"
          id="linearGradient420"
        >
          <stop stopOpacity={1} stopColor="#2e68bb" offset="0" id="stop416" />
          <stop stopOpacity={1} stopColor="#2d368c" offset="1" id="stop418" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath436">
          <path
            d="m 1501.45,4.98828 c -115.98,0 -210,94.03122 -210,209.99972 0,115.992 94.02,210 210,210 115.98,0 210,-94.008 210,-210 0,-115.9685 -94.02,-209.99972 -210,-209.99972"
            id="path434"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath448">
          <path
            d="m 1583.18,304.809 c 0,-80.75 -65.46,-146.207 -146.21,-146.207 -80.75,0 -146.21,65.457 -146.21,146.207 0,80.75 65.46,146.222 146.21,146.222 80.75,0 146.21,-65.472 146.21,-146.222 z"
            id="path446"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,1540.36,408.2)"
          spreadMethod="pad"
          id="linearGradient454"
        >
          <stop stopOpacity={1} stopColor="#19ae5b" offset="0" id="stop450" />
          <stop stopOpacity={1} stopColor="#069757" offset="1" id="stop452" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath470">
          <path
            d="m 1076.61,9.32031 c -115.981,0 -210.001,94.00769 -210.001,209.99969 0,115.981 94.02,210 210.001,210 115.98,0 210,-94.019 210,-210 0,-115.992 -94.02,-209.99969 -210,-209.99969"
            id="path468"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath482">
          <path
            d="m 1158.34,304.77 c 0,-80.75 -65.46,-146.211 -146.21,-146.211 -80.755,0 -146.212,65.461 -146.212,146.211 0,80.75 65.457,146.21 146.212,146.21 80.75,0 146.21,-65.46 146.21,-146.21 z"
            id="path480"
          />
        </clipPath>
        <linearGradient
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,1115.52,408.16)"
          spreadMethod="pad"
          id="linearGradient488"
        >
          <stop stopOpacity={1} stopColor="#f04148" offset="0" id="stop484" />
          <stop stopOpacity={1} stopColor="#e51a46" offset="1" id="stop486" />
        </linearGradient>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath500">
          <path
            d="m 2391.45,859.25 c -115.97,0 -210,94.02 -210,210 0,115.98 94.03,210 210,210 115.99,0 210,-94.02 210,-210 0,-115.98 -94.01,-210 -210,-210"
            id="path498"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath514">
          <path
            d="m 1962.65,432.121 c -118.75,0 -215,96.258 -215,215 0,29.215 5.82,57.059 16.37,82.453 0.16,-80.613 65.56,-145.926 146.21,-145.926 80.76,0 146.21,65.461 146.21,146.219 0,53.348 -28.56,100.02 -71.24,125.555 6.29,0.652 12.49,1.582 18.61,2.766 99.05,-19.204 173.84,-106.399 173.84,-211.067 0,-118.742 -96.26,-215 -215,-215"
            id="path512"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath530">
          <path
            d="m 2820.27,859.25 c -115.97,0 -210,94.02 -210,210 0,115.98 94.03,210 210,210 115.98,0 210,-94.02 210,-210 0,-115.98 -94.02,-210 -210,-210"
            id="path528"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath546">
          <path
            d="m 2820.27,437.121 c -115.97,0 -210,94.027 -210,210 0,115.977 94.03,210 210,210 115.98,0 210,-94.023 210,-210 0,-115.973 -94.02,-210 -210,-210"
            id="path544"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath564">
          <path
            d="m 2391.45,437.121 c -115.97,0 -210,94.027 -210,210 0,115.977 94.03,210 210,210 115.99,0 210,-94.023 210,-210 0,-115.973 -94.01,-210 -210,-210"
            id="path562"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath586">
          <path
            d="m 2003.81,858.188 c -9.98,1.929 -20.21,3.175 -30.62,3.679 -19.07,9.11 -40.42,14.211 -62.96,14.211 -11.59,0 -22.86,-1.348 -33.68,-3.898 -75.88,33.199 -128.9,108.941 -128.9,197.07 0,118.74 96.25,215 215,215 118.74,0 215,-96.26 215,-215 0,-104.664 -74.79,-191.863 -173.84,-211.062"
            id="path584"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath598">
          <path
            d="m 1985.2,855.422 c -3.89,2.324 -7.9,4.48 -12.01,6.445 10.41,-0.504 20.64,-1.75 30.62,-3.679 -6.12,-1.184 -12.32,-2.114 -18.61,-2.766"
            id="path596"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath610">
          <path
            d="m 1962.65,854.25 c -30.62,0 -59.74,6.398 -86.1,17.93 10.82,2.55 22.09,3.898 33.68,3.898 22.54,0 43.89,-5.101 62.96,-14.211 4.11,-1.965 8.12,-4.121 12.01,-6.445 -7.41,-0.774 -14.94,-1.172 -22.55,-1.172"
            id="path608"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath626">
          <path d="M 0,0 H 3459 V 1672 H 0 Z" id="path624" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath632">
          <path
            d="m 1962.65,-0.0117188 c -118.75,0 -215,96.2617188 -215,214.9997188 0,118.742 96.25,215 215,215 118.74,0 215,-96.258 215,-215 0,-118.738 -96.26,-214.9997188 -215,-214.9997188"
            id="path630"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath650">
          <path
            d="m 3225.33,855.793 c -106.76,9.906 -190.35,99.742 -190.35,209.097 0,115.98 94.02,210 210,210 115.99,0 210,-94.02 210,-210 0,-107.949 -81.44,-196.874 -186.24,-208.675 -6.47,0.601 -13.02,0.906 -19.65,0.906 -8.03,0 -15.96,-0.449 -23.76,-1.328"
            id="path648"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath666">
          <path
            d="m 2391.45,4.98828 c -115.97,0 -210,94.03122 -210,209.99972 0,115.992 94.03,210 210,210 115.99,0 210,-94.008 210,-210 0,-115.9685 -94.01,-209.99972 -210,-209.99972"
            id="path664"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath682">
          <path d="M 0,0 H 3459 V 1672 H 0 Z" id="path680" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath688">
          <path
            d="m 3249.09,437.121 c -115.98,0 -210,94.027 -210,210 0,107.942 81.45,196.867 186.24,208.672 6.47,-0.602 13.02,-0.906 19.65,-0.906 8.03,0 15.96,0.449 23.76,1.328 106.77,-9.906 190.35,-99.742 190.35,-209.094 0,-115.973 -94.02,-210 -210,-210"
            id="path686"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath700">
          <path
            d="m 3244.98,854.887 c -6.63,0 -13.18,0.304 -19.65,0.906 7.8,0.879 15.73,1.328 23.76,1.328 6.63,0 13.18,-0.305 19.65,-0.906 -7.8,-0.879 -15.73,-1.328 -23.76,-1.328"
            id="path698"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath720">
          <path d="M 0,0 H 3459 V 1672 H 0 Z" id="path718" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath726">
          <path
            d="m 3249.09,4.98828 c -115.98,0 -210,94.03122 -210,209.99972 0,115.992 94.02,210 210,210 115.98,0 210,-94.008 210,-210 0,-115.9685 -94.02,-209.99972 -210,-209.99972"
            id="path724"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath746">
          <path
            d="m 2824.25,9.32031 c -115.98,0 -210,94.00769 -210,209.99969 0,115.981 94.02,210 210,210 115.98,0 210,-94.019 210,-210 0,-115.992 -94.02,-209.99969 -210,-209.99969"
            id="path744"
          />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath80-6">
          <path
            d="m 733.5,1154.7 c 0,-80.75 -65.457,-146.21 -146.211,-146.21 -80.75,0 -146.215,65.46 -146.215,146.21 0,80.76 65.465,146.21 146.215,146.21 80.754,0 146.211,-65.45 146.211,-146.21 z"
            id="path78-2"
          />
        </clipPath>
        <linearGradient
          xlinkHref="#linearGradient96"
          id="linearGradient1377"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,690.677,1258.09)"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
        />
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath270-0">
          <path
            d="m 308.809,1154.9 c 0,80.75 -65.465,146.22 -146.215,146.22 -80.7502,0 -146.2112,-65.47 -146.2112,-146.22 0,-72.98 53.4649,-133.47 123.3672,-144.43 v 102.16 h -37.129 v 42.27 h 37.129 v 32.22 c 0,36.64 21.828,56.88 55.227,56.88 15.996,0 32.726,-2.86 32.726,-2.86 v -35.98 h -18.437 c -18.164,0 -23.828,-11.27 -23.828,-22.83 v -27.43 h 40.554 l -6.48,-42.27 h -34.074 v -102.16 c 69.902,10.96 123.371,71.45 123.371,144.43 z"
            id="path268-7"
          />
        </clipPath>
        <linearGradient
          xlinkHref="#linearGradient276"
          id="linearGradient1437"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(206.777,-206.777,-206.777,-206.777,59.2062,1258.29)"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
        />
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath210-0">
          <path
            d="m 733.5,729.867 c 0,-80.758 -65.457,-146.219 -146.211,-146.219 -80.75,0 -146.215,65.461 -146.215,146.219 0,80.75 65.465,146.211 146.215,146.211 80.754,0 146.211,-65.461 146.211,-146.211 z"
            id="path208-9"
          />
        </clipPath>
        <linearGradient
          xlinkHref="#linearGradient216"
          id="linearGradient2132"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,690.677,833.253)"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
        />
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath178-9">
          <path
            d="m 1158.34,729.867 c 0,-80.758 -65.46,-146.219 -146.21,-146.219 -80.755,0 -146.216,65.461 -146.216,146.219 0,80.75 65.461,146.211 146.216,146.211 80.75,0 146.21,-65.461 146.21,-146.211 z"
            id="path176-3"
          />
        </clipPath>
        <linearGradient
          xlinkHref="#linearGradient184"
          id="linearGradient2265"
          gradientUnits="userSpaceOnUse"
          gradientTransform="matrix(-206.778,-206.778,-206.778,206.778,1115.52,833.252)"
          x1="0"
          y1="0"
          x2="1"
          y2="0"
          spreadMethod="pad"
        />
      </defs>
      <g id="layer1">
        <g
          id="g10"
          transform="matrix(0.03527778,0,0,-0.03527778,7.675884,64.985998)"
        >
          <g
            id="g172-2"
            transform="matrix(1.2556271,0,0,1.2556271,-1298.9252,739.30343)"
          >
            <g
              id="g174-4"
              clipPath="url(#clipPath178-9)"
              transform="translate(277.82728,375.24723)"
            >
              <path
                d="m 1158.34,729.867 c 0,-80.758 -65.46,-146.219 -146.21,-146.219 -80.755,0 -146.216,65.461 -146.216,146.219 0,80.75 65.461,146.211 146.216,146.211 80.75,0 146.21,-65.461 146.21,-146.211"
                fill="url(#linearGradient2265)"
                fillOpacity={1}
                fillRule="nonzero"
                stroke="none"
                id="path186-0"
              />
            </g>
          </g>
          <path
            d="m 281.84438,2059.8028 3.61998,-2.1584 c 13.27197,-7.9117 28.37214,-12.0904 43.65313,-12.0904 47.16135,0 85.52076,38.3707 85.52076,85.5157 0,47.1589 -38.35941,85.5296 -85.52076,85.5296 -47.15006,0 -85.51575,-38.3707 -85.51575,-85.5296 0,-15.5735 4.32062,-30.901 12.5073,-44.3399 l 2.21744,-3.6389 -8.54454,-31.8866 z m -60.25504,-36.7861 15.30233,57.116 c -8.66131,15.6376 -13.22301,33.1573 -13.22301,50.937 0,58.1456 47.30701,105.4526 105.44883,105.4526 58.14809,0 105.46011,-47.307 105.46011,-105.4526 0,-58.1468 -47.31202,-105.4538 -105.46011,-105.4538 -17.45322,0 -34.69172,4.4198 -50.12213,12.7823 l -57.40602,-15.3815"
            fill="#ffffff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            strokeWidth={1.25563}
            id="path188-8"
          />
          <path
            d="m 368.34328,2116.404 c -4.33192,2.5991 -9.98224,5.4934 -15.09264,3.409 -3.93011,-1.6034 -6.42881,-7.7497 -8.97773,-10.8888 -1.30586,-1.6034 -2.86283,-1.8495 -4.85928,-1.0547 -14.72851,5.8763 -26.01659,15.7104 -34.13422,29.2473 -1.37743,2.1095 -1.12756,3.7619 0.52108,5.7144 2.46103,2.8942 5.53732,6.1802 6.21536,10.0651 0.65292,3.8987 -1.16774,8.4504 -2.7875,11.9184 -2.04667,4.4386 -4.34572,10.7557 -8.78436,13.2669 -4.08581,2.3054 -9.46241,1.0058 -13.09619,-1.9474 -6.27312,-5.1155 -9.3042,-13.1201 -9.21631,-21.0556 0.0301,-2.2564 0.30386,-4.5128 0.83374,-6.68 1.26567,-5.2385 3.68401,-10.1291 6.40621,-14.7787 2.0542,-3.507 4.28671,-6.9009 6.695,-10.1769 7.87153,-10.6929 17.67672,-19.987 29.01503,-26.9382 5.67544,-3.4718 11.77779,-6.523 18.10615,-8.6073 7.09429,-2.3443 13.41009,-4.7827 21.05686,-3.3249 8.02346,1.5193 15.92135,6.474 19.11065,14.1986 0.94172,2.2953 1.41885,4.8467 0.89149,7.2588 -1.09239,4.9936 -7.86022,7.9607 -11.90334,10.374"
            fill="#ffffff"
            fillOpacity={1}
            fillRule="evenodd"
            stroke="none"
            strokeWidth={1.25563}
            id="path190-6"
          />
          <g
            id="g204-9"
            transform="matrix(1.2556271,0,0,1.2556271,-766.69423,737.42681)"
          >
            <g id="g206-9" clipPath="url(#clipPath210-0)">
              <path
                d="m 733.5,729.867 c 0,-80.758 -65.457,-146.219 -146.211,-146.219 -80.75,0 -146.215,65.461 -146.215,146.219 0,80.75 65.465,146.211 146.215,146.211 80.754,0 146.211,-65.461 146.211,-146.211"
                fill="url(#linearGradient2132)"
                fillOpacity={1}
                fillRule="nonzero"
                stroke="none"
                id="path218-0"
              />
            </g>
          </g>
          <path
            d="m 2.7163805,1650.3957 -50.5540575,-25.0008 c -1.976357,-1.0786 -8.711541,0.3541 -8.711541,2.6105 v 51.3426 c 0,2.2814 6.789176,3.7129 8.770555,2.5753 L 0.61194949,1655.594 c 2.03034901,-1.1527 4.15361441,-4.0808 2.10443101,-5.1983 z m 66.2971035,30.1639 c 0,24.3429 -19.740963,44.0839 -44.088827,44.0839 H -87.80178 c -24.34786,0 -44.08507,-19.741 -44.08507,-44.0839 v -52.4563 c 0,-24.3529 19.73721,-44.0951 44.08507,-44.0951 H 24.924657 c 24.347864,0 44.088827,19.7422 44.088827,44.0951 v 52.4563"
            fill="#ffffff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            strokeWidth={1.25563}
            id="path220-5"
          />
          <g
            id="g264-7"
            transform="matrix(1.1698007,0,0,1.1698007,587.00624,737.67185)"
          >
            <g
              id="g266-6"
              clipPath="url(#clipPath270-0)"
              transform="matrix(1.0936487,0,0,1.0936487,-28.919557,-94.462498)"
            >
              <path
                d="m 308.809,1154.9 c 0,80.75 -65.465,146.22 -146.215,146.22 -80.7502,0 -146.2112,-65.47 -146.2112,-146.22 0,-72.98 53.4649,-133.47 123.3672,-144.43 v 102.16 h -37.129 v 42.27 h 37.129 v 32.22 c 0,36.64 21.828,56.88 55.227,56.88 15.996,0 32.726,-2.86 32.726,-2.86 v -35.98 h -18.437 c -18.164,0 -23.828,-11.27 -23.828,-22.83 v -27.43 h 40.554 l -6.48,-42.27 h -34.074 v -102.16 c 69.902,10.96 123.371,71.45 123.371,144.43"
                fill="url(#linearGradient1437)"
                fillOpacity={1}
                fillRule="nonzero"
                stroke="none"
                id="path278-4"
              />
            </g>
          </g>
          <path
            d="m 834.00903,2050.614 8.29019,54.078 h -51.8828 v 35.0927 c 0,14.7893 7.24624,29.2075 30.48438,29.2075 h 23.58739 v 46.0311 c 0,0 -21.40354,3.659 -41.86804,3.659 -42.72905,0 -70.65472,-25.8941 -70.65472,-72.7695 V 2104.692 H 684.4644 v -54.078 h 47.50103 v -130.6986 c 9.51965,-1.4969 19.28494,-2.2772 29.2255,-2.2772 9.94439,0 19.70456,0.7803 29.22549,2.2772 v 130.6986 h 43.59261"
            fill="#ffffff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            strokeWidth={1.27935}
            id="path280-3"
          />
          <g
            id="g74-1"
            transform="matrix(1.2323955,0,0,1.2323955,-1400.9749,681.63208)"
          >
            <g id="g76-3" clipPath="url(#clipPath80-6)">
              <path
                id="path98-5"
                fill="url(#linearGradient1377)"
                fillOpacity={1}
                fillRule="nonzero"
                stroke="none"
                d="m 733.5,1154.7 c 0,-80.75 -65.457,-146.21 -146.211,-146.21 -80.75,0 -146.215,65.46 -146.215,146.21 0,80.76 65.465,146.21 146.215,146.21 80.754,0 146.211,-65.45 146.211,-146.21"
              />
            </g>
          </g>
          <path
            d="m -628.36447,2165.3909 c -6.63542,0 -12.01453,-5.3791 -12.01453,-12.0134 0,-6.6342 5.37911,-12.0253 12.01453,-12.0253 6.63543,0 12.00975,5.3911 12.00975,12.0253 0,6.6343 -5.37432,12.0134 -12.00975,12.0134"
            fill="#ffffff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            strokeWidth={1.19536}
            id="path222-5"
          />
          <path
            d="m -681.77779,2067.1803 c -17.82277,0 -32.33082,14.5117 -32.33082,32.3344 0,17.8228 14.50805,32.3225 32.33082,32.3225 17.82755,0 32.32603,-14.4997 32.32603,-32.3225 0,-17.8227 -14.49848,-32.3344 -32.32603,-32.3344 z m 0,82.8024 c -27.8291,0 -50.47035,-22.6401 -50.47035,-50.468 0,-27.8279 22.64125,-50.4679 50.47035,-50.4679 27.8291,0 50.46677,22.64 50.46677,50.4679 0,27.8279 -22.63767,50.468 -50.46677,50.468"
            fill="#ffffff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            strokeWidth={1.19536}
            id="path224-2"
          />
          <path
            d="m -723.52682,2182.6996 c -23.31304,0 -42.27619,-18.9583 -42.27619,-42.2797 v -81.8103 c 0,-23.3214 18.96315,-42.2797 42.27619,-42.2797 h 81.81261 c 23.31424,0 42.28097,18.9583 42.28097,42.2797 v 81.8103 c 0,23.3214 -18.96673,42.2797 -42.28097,42.2797 z m 81.81261,-185.6508 h -81.81261 c -33.94096,0 -61.5561,27.6127 -61.5561,61.5608 v 81.8103 c 0,33.9481 27.61514,61.5608 61.5561,61.5608 h 81.81261 c 33.94096,0 61.56088,-27.6127 61.56088,-61.5608 v -81.8103 c 0,-33.9481 -27.61992,-61.5608 -61.56088,-61.5608"
            fill="#ffffff"
            fillOpacity={1}
            fillRule="nonzero"
            stroke="none"
            strokeWidth={1.19536}
            id="path226-0"
          />
        </g>
      </g>
    </svg>

    // <svg
    //   width={61}
    //   height={43}
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    //   {...props}
    // >
    //   <g filter="url(#prefix__filter0_i_256_4)">
    //     <path
    //       d="M0 10.98c0-5.038 3.75-9.314 8.764-9.824 15.44-1.571 27.082-1.529 43.382.055C57.197 1.7 61 5.99 61 11.067v20.56c0 4.908-3.56 9.118-8.426 9.765-16.07 2.138-27.818 2.11-44.106-.022C3.584 40.73 0 36.514 0 31.59V10.98z"
    //       fill="url(#prefix__paint0_linear_256_4)"
    //     />
    //   </g>
    //   <g filter="url(#prefix__filter1_i_256_4)">
    //     <path
    //       d="M36.987 19.733c1.396.756 1.396 2.76 0 3.517l-10.492 5.683c-1.333.722-2.953-.243-2.953-1.758V15.808c0-1.516 1.62-2.48 2.953-1.759l10.492 5.684z"
    //       fill="url(#prefix__paint1_linear_256_4)"
    //     />
    //   </g>
    //   <defs>
    //     <linearGradient
    //       id="prefix__paint0_linear_256_4"
    //       x1={30.5}
    //       y1={0}
    //       x2={30.5}
    //       y2={42.983}
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stopColor="#fff" stopOpacity={0.15} />
    //       <stop offset={1} stopColor="#fff" stopOpacity={0.05} />
    //     </linearGradient>
    //     <linearGradient
    //       id="prefix__paint1_linear_256_4"
    //       x1={35.47}
    //       y1={27.487}
    //       x2={23.279}
    //       y2={9.302}
    //       gradientUnits="userSpaceOnUse"
    //     >
    //       <stop stopColor="#003840" />
    //       <stop offset={1} stopColor="#4CB1BF" />
    //     </linearGradient>
    //     <filter
    //       id="prefix__filter0_i_256_4"
    //       x={0}
    //       y={0}
    //       width={61}
    //       height={44.982}
    //       filterUnits="userSpaceOnUse"
    //       colorInterpolationFilters="sRGB"
    //     >
    //       <feFlood floodOpacity={0} result="BackgroundImageFix" />
    //       <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
    //       <feColorMatrix
    //         in="SourceAlpha"
    //         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
    //         result="hardAlpha"
    //       />
    //       <feOffset dy={2} />
    //       <feGaussianBlur stdDeviation={1.5} />
    //       <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
    //       <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.9 0" />
    //       <feBlend in2="shape" result="effect1_innerShadow_256_4" />
    //     </filter>
    //     <filter
    //       id="prefix__filter1_i_256_4"
    //       x={23.542}
    //       y={13.805}
    //       width={14.492}
    //       height={15.373}
    //       filterUnits="userSpaceOnUse"
    //       colorInterpolationFilters="sRGB"
    //     >
    //       <feFlood floodOpacity={0} result="BackgroundImageFix" />
    //       <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
    //       <feColorMatrix
    //         in="SourceAlpha"
    //         values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
    //         result="hardAlpha"
    //       />
    //       <feOffset />
    //       <feGaussianBlur stdDeviation={1.5} />
    //       <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
    //       <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
    //       <feBlend in2="shape" result="effect1_innerShadow_256_4" />
    //     </filter>
    //   </defs>
    // </svg>
  );
};
