import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LogoDesktop from "../../media/svgs/logoMobile.svg";
import { defineSearchSlug } from "../../utilities/functions";

const Navbar: React.FC = () => {
  const [inputValue, setInputValue] = useState<string>("");

  const navigate = useNavigate();

  const handleChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const searchString = (e: React.MouseEvent) => {
    // $(document.body).append('form');
    e.preventDefault;
    if (inputValue !== "") {
      const slug = defineSearchSlug(inputValue);
      navigate("../pesquisa/" + slug, { replace: true });
    }
  };

  return (
    <nav className="navbar navbar-dark navbarBackground navbar-expand-lg ">
      <div className="container">
        <div className="col-10 col-lg-1">
          <div className="row justify-content-between align-items-center">
            <div className="col-1 navbar-brand">
              <Link to={"/"}>
                <img src={LogoDesktop} width="50px" alt="logotipo LFTV" />
              </Link>
            </div>
            <div className="col-1">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#id_navbar"
                aria-controls="id_navbar"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
          </div>
        </div>

        <div className="col-lg-12 col-xl-10 ">
          <div className="collapse navbar-collapse" id="id_navbar">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item dropdown ">
                <a
                  className="nav-link text-white dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Municipios
                </a>
                <ul className="dropdown-menu navbarBackgroundDropdown dropdown-menu-dark">
                  <li>
                    <Link
                      to="../categorias/LauroDeFreitas"
                      state={{ id: 10, name: "Lauro de Freitas" }}
                      className="text-decoration-none"
                    >
                      <div className="dropdown-item">Lauro de Freitas</div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="../categorias/Camacari"
                      state={{ id: 4, name: "Camaçari" }}
                      className="text-decoration-none"
                    >
                      <div className="dropdown-item">Camaçari</div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="../categorias/FeiraDeSantana"
                      state={{ id: 50, name: "Feira De Santana" }}
                      className="text-decoration-none"
                    >
                      <div className="dropdown-item">Feira De Santana</div>
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item ms-3">
                <Link
                  to="../categorias/Bahia"
                  state={{ id: 2, name: "Bahia" }}
                  className="text-decoration-none"
                >
                  <div className="nav-link text-white">Bahia</div>
                </Link>
              </li>
              <li className="nav-item ms-3">
                <Link
                  to="../categorias/Esportes"
                  state={{ id: 9, name: "Esportes" }}
                  className="text-decoration-none"
                >
                  <div className="nav-link text-white">Esportes</div>
                </Link>
              </li>
              <li className="nav-item ms-3">
                <Link
                  to="../categorias/Brasil"
                  state={{ id: 3, name: "Brasil" }}
                  className="text-decoration-none"
                >
                  <div className="nav-link text-white">Brasil</div>
                </Link>
              </li>
              <li className="nav-item ms-3">
                <Link
                  to="../categorias/Justica"
                  state={{ id: 34, name: "Justiça" }}
                  className="text-decoration-none"
                >
                  <div className="nav-link text-white">Justiça</div>
                </Link>
              </li>
              <li className="nav-item ms-3">
                <Link
                  to="../categorias/Economia"
                  state={{ id: 20, name: "Economia" }}
                  className="text-decoration-none"
                >
                  <div className="nav-link text-white">Economia</div>
                </Link>
              </li>
              {/* <li className="nav-item ms-3">
                <a className="nav-link text-white">Contato</a>
              </li> */}
            </ul>
            <form className="d-flex" role="search">
              <input
                className="form-control me-1"
                type="search"
                placeholder="Buscar"
                aria-label="Buscar"
                value={inputValue}
                onChange={(e) => handleChange(e)}
              />

              <button
                className="btn btn-outline-light"
                type="submit"
                onClick={(e: React.MouseEvent) => searchString(e)}
              >
                Buscar
              </button>
            </form>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
