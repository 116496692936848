/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  newsItems,
  pageCategory,
  categoriesItems,
  commentsItems,
} from "./types";

const correction: any = {
  "<p>": "",
  "</p>": "",
  "<em>": "",
  "</em>": "",
  'class="has-drop-cap"': "",
  '<div class="wp-block-image">': "",
  '<figure class="alignleft size-large is-resized">': "",
  "&#8220;": "",
  "&#038;": "&",
  'alt=""': "",
  'class="wp-image-17126" width="267" height="326"': "",
  "</figure>": "",
  "</div>": "",
  "\n": "",
  "&#8216;": "'",
  "&#8217;": "'",
  ' class="has-drop-cap has-text-align-justify">': "",
  ' class="has-text-align-justify">': "",
  ' class="has-text-align-center">': "",
  "&nbsp;": " ",
  "&#8221;": "-",
  'rel="noreferrer noopener"': "",
  'target="_blank"': "",
};

export const refineSubtitle = (data: newsItems[]) => {
  const auxData: newsItems[] | void = data;
  Object.keys(data).forEach((datakey: any) => {
    Object.keys(correction).forEach((key) => {
      auxData[datakey].excerpt.rendered = data[
        datakey
      ].excerpt.rendered.replaceAll(key, correction[key]);
      auxData[datakey].title.rendered = data[datakey].title.rendered.replaceAll(
        key,
        correction[key]
      );
    });
  });
  return auxData;
};

const correctionComments: any = {
  "</p>": "",
  "\n": "<p>",
};
export const refineContentComments = (data: commentsItems[]) => {
  const auxData = data;
  auxData.map((item, index) => {
    Object.keys(correctionComments).forEach((key) => {
      item.content.rendered = data[index].content.rendered.replaceAll(
        key,
        correctionComments[key]
      );
    });
  });
  return auxData;
};

export const getTitleSeparated = (title: string) => {
  return title.split(":");
};

export const getAllTitlesSeparated = (data: newsItems[]) => {
  let listTitles: string[][] = [];
  Object.keys(data).forEach((datakey: any) => {
    const auxData = getTitleSeparated(data[datakey].title.rendered);
    listTitles = [...listTitles, auxData];
  });
  return listTitles;
};

export const pageCategories = ({ layout, data }: pageCategory) => {
  let listCategories: categoriesItems[][] = [];
  let auxCategory: categoriesItems[] = [];
  let index = 0;
  const dataLength = Object.keys(data).length;
  if (layout === "DESKTOP") {
    Object.keys(data).forEach((datakey: any) => {
      if (dataLength - 1 === index) {
        auxCategory = [...auxCategory, data[datakey]];
        listCategories = [...listCategories, auxCategory];
      } else if (auxCategory.length < 14) {
        auxCategory = [...auxCategory, data[datakey]];
      } else {
        auxCategory = [...auxCategory, data[datakey]];
        listCategories = [...listCategories, auxCategory];
        auxCategory = [];
      }
      index++;
    });
  } else {
    Object.keys(data).forEach((datakey: any) => {
      if (dataLength - 1 === index) {
        auxCategory = [...auxCategory, data[datakey]];
        listCategories = [...listCategories, auxCategory];
      } else if (auxCategory.length < 8) {
        auxCategory = [...auxCategory, data[datakey]];
      } else {
        auxCategory = [...auxCategory, data[datakey]];
        listCategories = [...listCategories, auxCategory];
        auxCategory = [];
      }
      index++;
    });
  }
  return listCategories;
};

export const redifineContent = (text: string) => {
  const auxText = text.split("<p");

  let arrayData: string[] = [];
  auxText.map((item) => {
    let newData = item;
    Object.keys(correction).forEach((key) => {
      newData = newData.replaceAll(key, correction[key]);
    });
    arrayData = [...arrayData, newData];
  });

  const newText = arrayData.map((item) => {
    return item.split("<strong>");
  });
  console.log(newText);
  let newTextSplit: string[][] = [];
  newText.map((item) => {
    let auxNewText: string[][] = [];
    item.map((newItem) => {
      const auxNewString = newItem.split("</a>");
      auxNewText = [...auxNewText, auxNewString];
    });
    newTextSplit = [...newTextSplit, auxNewText[0]];
  });

  let newTextSplitInitalTag: string[][] = [];
  newTextSplit.map((item) => {
    let auxNewText: string[] = [];
    item.map((newItem) => {
      const auxNewString = newItem.split("<a ");
      auxNewString.map((_item) => {
        auxNewText = [...auxNewText, _item];
      });
    });
    newTextSplitInitalTag = [...newTextSplitInitalTag, auxNewText];
  });
  let finalText: string[][] = [];
  let auxNewText: string[] = [];
  newTextSplitInitalTag.map((item) => {
    auxNewText = [];
    item.map((newItem) => {
      if (newItem !== "") {
        auxNewText = [...auxNewText, newItem.replace(">", "")];
      }
    });
    if (auxNewText.length > 0) {
      finalText = [...finalText, auxNewText];
    }
  });
  return finalText;
};

const redifineSlug: any = {
  "+": "%2B",
  " ": "+",
};

export const defineSearchSlug = (slug: string) => {
  let newString: string = slug;

  Object.keys(redifineSlug).forEach((key) => {
    newString = newString.replaceAll(key, redifineSlug[key]);
  });
  return newString;
};

const difineSlug: any = {
  "+": " ",
  "%2B": "+",
  "%C3%A1": "á",
  "%C3%A9": "é",
  "%C3%AD": "í",
  "%C3%B3": "ó",
  "%C3%BA": "ú",
  "%C3%A0": "à",
  "%C3%A8": "è",
  "%C3%AC": "ì",
  "%C3%B2": "ò",
  "%C3%B9": "ù",
  "%C3%A3": "ã",
  "%C3%B5": "õ",
  "%C3%B1": "ñ",
  "%C3%A7": "ç",
  "%C2%AA": "ª",
  "%C2%BA": "º",
  "%22": '"',
  "%3F": "?",
  "%7B": "{",
  "%7D": "}",
  "%25": "%",
  "%26": "&",
  "%3D": "=",
};

export const defineSearch = (slug: string) => {
  let newString: string = slug;

  Object.keys(difineSlug).forEach((key) => {
    newString = newString.replaceAll(key, difineSlug[key]);
  });
  return newString;
};
