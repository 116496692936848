import React, { useEffect } from "react";
import GoogleAds from "../components/geral/GoogleAds";
import Navbar from "../components/geral/NavBar";
import PureFooter from "../components/geral/PureFooter";
import Logo from "../media/svgs/logoDesktop.svg";

const PageNotFound: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className="fullHeight">
        <Navbar />
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-3 mt-5">
              <img src={Logo} className="img-fluid" alt="logo LFTV" />
            </div>
            <div className="text-center">
              <p className="display-5">Página não encontrada</p>
              <p className="display-5 fs-4">verifique o endereço digitado</p>
            </div>
          </div>
        </div>
      </div>
      <GoogleAds />
      <PureFooter />
    </div>
  );
};

export default PageNotFound;
