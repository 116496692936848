import React, { useEffect, useState } from "react";
import { pageCategories } from "../../utilities/functions";
import { categoriesItems } from "../../utilities/types";
import $ from "jquery";
import { Link } from "react-router-dom";

const auxCategoriesData: React.FC<categoriesItems[]> = (props) => {
  const [categoriesPages, setCategoriesPages] = useState<
    categoriesItems[][] | null
  >(null);

  useEffect(() => {
    const width = $(window).width();
    if (width && width > 768) {
      const auxData = pageCategories({ data: props, layout: "DESKTOP" });
      setCategoriesPages(auxData);
    } else {
      const auxData = pageCategories({ data: props, layout: "MOBILE" });
      setCategoriesPages(auxData);
    }
  }, []);
  return (
    <div className="backgroundCoverRightBorder">
      <div className="backgroundMaskRightBorder">
        <div className="container">
          <div className="row justify-content-center align-items-center mb-5">
            <p className="fontRalewayLight fs-1 text-center">Categorias</p>
            <div className="row justify-content-center align-items-center">
              <div
                id="carouselExampleControls"
                className="carousel slide row"
                data-bs-ride="carousel"
              >
                <div className="row justify-content-center align-items-center">
                  <div className="col-1 d-none d-md-block">
                    <button
                      className="bgButton btnLeft"
                      type="button"
                      data-bs-target="#carouselExampleControls"
                      data-bs-slide="prev"
                    >
                      <span
                        className="display-1 text-secondary"
                        aria-hidden="true"
                      >
                        &lt;
                      </span>
                    </button>
                  </div>
                  <div className="col-12 col-md-10">
                    <div className="carousel-inner text-center">
                      {categoriesPages &&
                        categoriesPages.map((page, index) => (
                          <div
                            key={index}
                            className={`carousel-item ${
                              index === 0 ? "active" : ""
                            }`}
                          >
                            {page.map((row, indexBtn) => (
                              <Link
                                to={"../categorias/" + row.name}
                                state={{ id: row.id, name: row.name }}
                                key={indexBtn}
                                className="text-decoration-none"
                              >
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm itemsBackground m-1"
                                  key={indexBtn}
                                >
                                  {row.name}
                                </button>
                              </Link>
                            ))}
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="col-12 col-md-1 row justify-content-center">
                    <div className="col-6 d-block d-md-none text-end">
                      <button
                        className="bgButton btnLeft"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="prev"
                      >
                        <span
                          className="display-1 text-secondary"
                          aria-hidden="true"
                        >
                          &lt;
                        </span>
                      </button>
                    </div>
                    <div className="col-6 text-center">
                      <button
                        className="bgButton"
                        type="button"
                        data-bs-target="#carouselExampleControls"
                        data-bs-slide="next"
                      >
                        <span
                          className="display-1 text-secondary"
                          aria-hidden="true"
                        >
                          &gt;
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default auxCategoriesData;
