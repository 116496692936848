import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { LineBottom } from "../components/animations/Icons";
import Footer from "../components/geral/Footer";
import Navbar from "../components/geral/NavBar";
import SearchContent from "../components/geral/SearchContent";
import { getData } from "../utilities/data/get_data";
import { defineSearch } from "../utilities/functions";
import { newsItems } from "../utilities/types";
import Logo from "../media/svgs/logoDesktop.svg";
import { LoadingPage } from "../components/animations/LoadingPage";
import PureFooter from "../components/geral/PureFooter";
import NewsPerCategory from "../components/geral/NewsPerCategory";
import PopUp from "../components/geral/PopUp";
import { Helmet } from "react-helmet";
import GoogleAds from "../components/geral/GoogleAds";

const Categories: React.FC = () => {
  const location = useLocation();
  let auxLocation: any | { id: number; name: string } = location.state;
  const [category, setCategory] = useState<any | { id: number; name: string }>(
    location.state
  );
  const [data, setData] = useState<newsItems[] | null>(null);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const getDataState = async () => {
    const auxData: newsItems[] = await getData(
      {
        dataToGet: "PAGE_PER_CATEGORY",
      },
      auxLocation.id,
      0
    );
    if (auxData && auxData.length > 0) {
      console.log(category.id);
      setData(auxData);
      setDataLoading(false);
    } else {
      navigate("../erro_404/" + location.pathname.split("/")[4]);
    }
  };

  useEffect(() => {
    setCategory(location.state);
    auxLocation = location.state;
    setDataLoading(true);
    getDataState();
  }, [location.state]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (dataLoading) {
    return (
      <div>
        <LoadingPage />
      </div>
    );
  }

  if (data && data.length > 0) {
    return (
      <div>
        <Helmet>
          {data && <title>{category.name}</title>}
          <meta property="og:image" content={Logo} />
          <meta
            property="og:image:width"
            content="450"
            className="yoast-seo-meta-tag"
          />
          <meta
            property="og:image:height"
            content="450"
            className="yoast-seo-meta-tag"
          />
          <meta property="og:image:type" content="image/png" />
          <meta name="description" content={category.name} />
        </Helmet>
        <Navbar />
        <div className="row justify-content-center ">
          <div className="col-2 mt-2">
            <img src={Logo} className="img-fluid" alt="logo LFTV" />
          </div>
        </div>
        <div className="text-center display-5 fs-3 mt-2 container">
          {category.name}
          <LineBottom />
        </div>
        <NewsPerCategory {...data} />
        <GoogleAds />
        <Footer />
        {/* <PopUp /> */}
      </div>
    );
  } else {
    return (
      <div>
        <div className="fullHeight">
          <Navbar />
          <div className="row justify-content-center ">
            <div className="col-2 mt-2">
              <img src={Logo} className="img-fluid" alt="logo LFTV" />
            </div>
          </div>
          <div className="text-center display-5 fs-3 mt-2 container ">
            Verifique o item selecionado
          </div>
        </div>
        <GoogleAds />
        <PureFooter />
        {/* <PopUp /> */}
      </div>
    );
  }
};

export default Categories;
