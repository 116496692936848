import React, { useEffect, useState } from "react";
import { author, newsItems } from "../../utilities/types";
import Logo from "../../media/svgs/logoDesktop.svg";
import { LineTop } from "../animations/Icons";
import manReadingNewsPaper from "../../media/imgs/background/manReadingNewsPaper.png";
import { redifineContent } from "../../utilities/functions";
import { getData } from "../../utilities/data/get_data";
import DOMPurify from "isomorphic-dompurify";
// import { JSDOM } from "jsdom";
// import DOMPurify from "dompurify";

const defineDate = (date: string) => {
  let auxDate = date.split("T");
  auxDate = auxDate[0].split("-");
  let finalDate;
  if (auxDate[1] === "01") {
    finalDate = auxDate[2] + " de ";
    finalDate += "janeiro de ";
    finalDate += auxDate[0];
  } else if (auxDate[1] === "02") {
    finalDate = auxDate[2] + " de ";
    finalDate += "fevereiro de ";
    finalDate += auxDate[0];
  } else if (auxDate[1] === "03") {
    finalDate = auxDate[2] + " de ";
    finalDate += "março de ";
    finalDate += auxDate[0];
  } else if (auxDate[1] === "04") {
    finalDate = auxDate[2] + " de ";
    finalDate += "abril de ";
    finalDate += auxDate[0];
  } else if (auxDate[1] === "05") {
    finalDate = auxDate[2] + " de ";
    finalDate += "maio de ";
    finalDate += auxDate[0];
  } else if (auxDate[1] === "06") {
    finalDate = auxDate[2] + " de ";
    finalDate += "junho de ";
    finalDate += auxDate[0];
  } else if (auxDate[1] === "07") {
    finalDate = auxDate[2] + " de ";
    finalDate += "julho de ";
    finalDate += auxDate[0];
  } else if (auxDate[1] === "08") {
    finalDate = auxDate[2] + " de ";
    finalDate += "agosto de ";
    finalDate += auxDate[0];
  } else if (auxDate[1] === "09") {
    finalDate = auxDate[2] + " de ";
    finalDate += "setembro de ";
    finalDate += auxDate[0];
  } else if (auxDate[1] === "10") {
    finalDate = auxDate[2] + " de ";
    finalDate += "outubro de ";
    finalDate += auxDate[0];
  } else if (auxDate[1] === "11") {
    finalDate = auxDate[2] + " de ";
    finalDate += "novembro de ";
    finalDate += auxDate[0];
  } else {
    finalDate = auxDate[2] + " de ";
    finalDate += "dezembro de ";
    finalDate += auxDate[0];
  }
  return finalDate;
};

const NewsContent: React.FC<newsItems> = (data) => {
  // const [content, setContent] = useState<string[][] | null>(null);
  const [content, setContent] = useState<string>(data.content.rendered);
  const [date, setDate] = useState<string>("");
  const [author, setAuthor] = useState<author | null>(null);

  // const window = new JSDOM("").window;
  // const purify = DOMPurify(window);
  const clean = DOMPurify.sanitize(data.content.rendered);

  const getAuthor = async () => {
    const auxAuthor: author = await getData({
      dataToGet: "AUTHOR",
    });
    if (auxAuthor) {
      console.log(auxAuthor);
      setAuthor(auxAuthor);
    }
  };

  useEffect(() => {
    const auxDate = defineDate(data.date);
    setDate(auxDate);
    getAuthor();
    // const newContent = redifineContent(data.content.rendered);
    // setContent(newContent);
  }, []);

  return (
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="col-12 col-lg-8">
          <img
            src={data.jetpack_featured_media_url}
            className="img-fluid w-100"
            alt="Imagem da notícias"
          />
          <div>
            <h1
              className="text-center display-5 fontBebasNeue mt-3 mb-5"
              dangerouslySetInnerHTML={{
                __html: data.title.rendered,
              }}
            >
              {/* {data.title.rendered} */}
            </h1>
            <div>
              <p className="display-5 fs-6">{data.x_date}</p>
              <p>{data.x_author}</p>
            </div>
            <div>
              <div
                className="divContents_ textJustiify"
                dangerouslySetInnerHTML={{ __html: clean }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-6 col-md-6 col-lg-3 mt-5">
          <img src={Logo} className="img-fluid" alt="logo LFTV" />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-lg-6 mt-5 mb-5">
          <img
            src={manReadingNewsPaper}
            className="img-fluid"
            alt="Homem lendo jornal"
          />
          <LineTop />
        </div>
      </div>
    </div>
  );
};

export default NewsContent;
