import React, { useEffect, useState } from "react";
import { commentsItems } from "../../utilities/types";

const Comments: React.FC<commentsItems[]> = (props) => {
  const data: commentsItems[] = props;
  const [comments, setComments] = useState<string[]>([]);
  useEffect(() => {
    let auxKeys: string[] = [];
    Object.keys(data).forEach((key) => {
      auxKeys = [...auxKeys, key];
    });
    setComments(auxKeys);
  }, []);
  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-10 fontBebasNeue display-5 mb-5 fontNewsTitleColor">
          Comentarios
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-10">
          {comments &&
            comments.map((item: string) => {
              const date = data[+item].date.split("T")[0].split("-");
              return (
                <div key={item}>
                  <div className="backgroundNews p-3 row align-items-center">
                    <div className="row rounded-circle justify-content-center align-items-center dateBackgroundColor col-2 shadow-lg">
                      <div className="row text-center justify-content-center">
                        <div className="fs-2">{date[2]}</div>
                        <hr className="lineWidth" />
                        <div className="fs-4">{date[1]}</div>
                        <hr className="lineWidth" />
                        <div className="fs-6">{date[0]}</div>
                      </div>
                    </div>
                    <div className="col-8 fs-2 nameComments">
                      {data[+item].author_name}
                    </div>
                    <div className="row justify-content-center col-10">
                      {data[+item].content.rendered
                        .split("<p>")
                        .map((paragraph, index) => (
                          <p key={index}>{paragraph}</p>
                        ))}
                    </div>
                  </div>
                </div>
              );
            })}
          {!data && (
            <div className="display-5 text-center">
              Nenhuma comentario relacionado a esta notícia
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Comments;
