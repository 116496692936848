import React, { useEffect, useState } from "react";
import { refineSubtitle } from "../../utilities/functions";
import { newsItems } from "../../utilities/types";
import $, { easing } from "jquery";
import { LineBottom, LineTop, NextPage, PrevPage } from "../animations/Icons";
import manReadingNewsPaper from "../../media/imgs/background/manReadingNewsPaper.png";
import { getData } from "../../utilities/data/get_data";
import { Link } from "react-router-dom";
import { REPLACE_URL } from "../../utilities/constraints";
import DOMPurify from "isomorphic-dompurify";

const GeneralNews: React.FC<newsItems[]> = (props) => {
  const [data, setDataLoaded] = useState<newsItems[]>(props);
  const [offSet, setOffSet] = useState<number>(0);
  const [firstTimeOffSet, setFirstTimeOffSet] = useState<boolean>(true);
  const [latsPageToSearch, setLastPageToSearch] = useState<boolean>(false);

  const defineImageBackgroundNews = () => {
    $("#id_first_news_generalnews").css(
      "background-image",
      `url(${data[0].jetpack_featured_media_url})`
    );
    $("#id_second_news_generalnews").css(
      "background-image",
      `url(${data[1].jetpack_featured_media_url})`
    );
    $("#id_third_news_generalnews").css(
      "background-image",
      `url(${data[2].jetpack_featured_media_url})`
    );
    $("#id_fourth_news_generalnews").css(
      "background-image",
      `url(${data[3].jetpack_featured_media_url})`
    );
    $("#id_fifth_news_generalnews").css(
      "background-image",
      `url(${data[4].jetpack_featured_media_url})`
    );
    $("#id_sixth_news_generalnews").css(
      "background-image",
      `url(${data[5].jetpack_featured_media_url})`
    );
    $("#id_senventh_news_generalnews").css(
      "background-image",
      `url(${data[6].jetpack_featured_media_url})`
    );
    $("#id_eighth_news_generalnews").css(
      "background-image",
      `url(${data[7].jetpack_featured_media_url})`
    );
    $("#id_ninth_news_generalnews").css(
      "background-image",
      `url(${data[8].jetpack_featured_media_url})`
    );
    $("#id_last_news_generalnews").css(
      "background-image",
      `url(${data[9].jetpack_featured_media_url})`
    );
  };
  const contentOpacityMedium = () => {
    $("#id_general_news_content").fadeTo("slow", 0.5);
  };
  const contentOpacityMax = () => {
    $("#id_general_news_content").fadeTo("slow", 1);
  };

  const scroolToTop = () => {
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $("#id_img_general_news_top").offset()?.top,
      },
      1000,
      () => contentOpacityMax()
    );
  };

  const getDataGeneralNews = async () => {
    const auxGeneralNewsData: newsItems[] = await getData(
      {
        dataToGet: "GENERAL_NEWS_NAVIGATE",
      },
      offSet * 10
    );

    if (auxGeneralNewsData.length === 0) {
      setOffSet(offSet - 1);
      setLastPageToSearch(true);
      contentOpacityMax();
    } else if (auxGeneralNewsData.length < 10) {
      const newData: newsItems[] | void = refineSubtitle(auxGeneralNewsData);
      scroolToTop();
      setLastPageToSearch(true);
      setDataLoaded(newData);
    } else if (auxGeneralNewsData) {
      const newData: newsItems[] | void = refineSubtitle(auxGeneralNewsData);
      scroolToTop();
      setDataLoaded(newData);
      if (latsPageToSearch) {
        setLastPageToSearch(false);
      }
    }
  };

  useEffect(() => {
    if (firstTimeOffSet) {
      setFirstTimeOffSet(false);
    } else {
      getDataGeneralNews();
      contentOpacityMedium();
    }
  }, [offSet]);

  useEffect(() => {
    defineImageBackgroundNews();
  }, [data]);

  useEffect(() => {
    const newData: newsItems[] | void = refineSubtitle(props);
    setDataLoaded(newData);
  }, []);
  return (
    <div className="container">
      <div className="row justify-content-center align-items-center">
        <div className="col-12 col-lg-10 mt-5 mb-5">
          <LineTop />
          <div className="positionLabelFixed">
            <div className="fontBebasNeue display-1 text-black mt-3 mt-md-4 mb-md-3 text-center">
              Noticiário Geral
            </div>
          </div>
          <LineBottom />
        </div>
        <div id="id_img_general_news_top" className="col-12 col-lg-8">
          <img
            src={manReadingNewsPaper}
            className="img-fluid"
            alt="Homem lendo jornal"
          />
        </div>

        <div className="col-12 col-md-8">
          <Link
            to={data[0].link.replace(REPLACE_URL, "")}
            state={{ data: data[0] }}
            className="text-decoration-none"
          >
            <div
              id="id_first_news_generalnews"
              className="divBigImg mt-1 imgBigCardsNews text-center"
            >
              <div className="row justify-content-start align-items-center maxHeight">
                <div className="d-none d-md-block col-6 backgroundBlueBlur">
                  <div className="row justify-content-center align-items-center maxHeight p-2">
                    <h3
                      className="fontBebasNeue text-light"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data[0].title.rendered),
                      }}
                    >
                      {/* {data[0].title.rendered} */}
                    </h3>
                    <p
                      className="text-light fs-5 fontIbarraReal"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data[0].excerpt.rendered),
                      }}
                    >
                      {/* {data[0].excerpt.rendered} */}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-block d-md-none col-12 backgroundBlueBlur">
              <div className="row justify-content-center align-items-center maxHeight p-2 text-center">
                <h3
                  className="fontBebasNeue text-light"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(data[0].title.rendered),
                  }}
                >
                  {/* {data[0].title.rendered} */}
                </h3>
                <p
                  className="text-light fs-5 fontIbarraReal"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(data[0].excerpt.rendered),
                  }}
                >
                  {/* {data[0].excerpt.rendered} */}
                </p>
              </div>
            </div>
          </Link>
        </div>

        <div className="hrdivider mb-2 mt-2 d-block d-md-none w-100" />
        <div className="col-12 col-md-4 divLeft">
          <Link
            to={data[1].link.replace(REPLACE_URL, "")}
            state={{ data: data[1] }}
            className="text-decoration-none"
          >
            <div
              id="id_second_news_generalnews"
              className="col-12 mb-2 mt-1 divSmallImg"
            >
              <div className="row align-items-end h-100">
                <div className="row align-items-center col-12 backgroundBlueBlurSmall">
                  <h3 className="text-center fs-6 fontIbarraReal text-light">
                    {data[1].title.rendered}
                  </h3>
                </div>
              </div>
            </div>
          </Link>

          <div className="hrdivider mb-2 mt-2 d-block d-md-none w-100" />
          <Link
            to={data[2].link.replace(REPLACE_URL, "")}
            state={{ data: data[2] }}
            className="text-decoration-none"
          >
            <div
              id="id_third_news_generalnews"
              className="col-12 mt-2 divSmallImg"
            >
              <div className="row align-items-end h-100">
                <div className="row align-items-center col-12 backgroundBlueBlurSmall">
                  <h3
                    className="text-center fs-6 fontIbarraReal text-light"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(data[2].title.rendered),
                    }}
                  >
                    {/* {data[2].title.rendered} */}
                  </h3>
                </div>
              </div>
            </div>
          </Link>
        </div>
        <div className="hrdivider mb-2 mt-2 d-block d-md-none w-100" />
        <div className="row col-12">
          <div className="col-12 col-lg-6">
            <Link
              to={data[3].link.replace(REPLACE_URL, "")}
              state={{ data: data[3] }}
              className="text-decoration-none"
            >
              <div
                id="id_fourth_news_generalnews"
                className="col-12 mt-1 divMediumImg"
              >
                <div className="row align-items-end h-100">
                  <div className="row align-items-center col-12 backgroundBlueBlurSmall">
                    <h3
                      className="text-center fs-6 fontIbarraReal text-light"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data[3].title.rendered),
                      }}
                    >
                      {/* {data[3].title.rendered} */}
                    </h3>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          <div className="hrdivider mb-2 mt-2 d-block d-md-none w-100" />

          <div className="col-12 col-lg-6">
            <Link
              to={data[4].link.replace(REPLACE_URL, "")}
              state={{ data: data[4] }}
              className="text-decoration-none"
            >
              <div
                id="id_fifth_news_generalnews"
                className="col-12 mt-1 divMediumImg"
              >
                <div className="row align-items-end h-100">
                  <div className="row align-items-center col-12 backgroundBlueBlurSmall">
                    <h3
                      className="text-center fs-6 fontIbarraReal text-light"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data[4].title.rendered),
                      }}
                    >
                      {/* {data[4].title.rendered} */}
                    </h3>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="hrdivider mb-2 mt-2 d-block d-md-none w-100" />
        <div className="row ">
          <div className="col-12 col-md-4">
            <Link
              to={data[5].link.replace(REPLACE_URL, "")}
              state={{ data: data[5] }}
              className="text-decoration-none"
            >
              <div
                id="id_sixth_news_generalnews"
                className="col-12 mb-2 mt-1 divSmallImg"
              >
                <div className="row align-items-end h-100">
                  <div className="row align-items-center col-12 backgroundBlueBlurSmall">
                    <h3
                      className="text-center fs-6 fontIbarraReal text-light"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data[5].title.rendered),
                      }}
                    >
                      {/* {data[5].title.rendered} */}
                    </h3>
                  </div>
                </div>
              </div>
            </Link>

            <div className="hrdivider mb-2 mt-2 d-block d-md-none w-100" />
            <Link
              to={data[6].link.replace(REPLACE_URL, "")}
              state={{ data: data[6] }}
              className="text-decoration-none"
            >
              <div
                id="id_senventh_news_generalnews"
                className="col-12 mt-2 divSmallImg"
              >
                <div className="row align-items-end h-100">
                  <div className="row align-items-center col-12 backgroundBlueBlurSmall">
                    <h3
                      className="text-center fs-6 fontIbarraReal text-light"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data[6].title.rendered),
                      }}
                    >
                      {/* {data[6].title.rendered} */}
                    </h3>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          <div className="hrdivider mb-2 mt-2 d-block d-md-none w-100" />

          <div className="col-12 col-md-8">
            <Link
              to={data[7].link.replace(REPLACE_URL, "")}
              state={{ data: data[7] }}
              className="text-decoration-none"
            >
              <div
                id="id_eighth_news_generalnews"
                className="divBigImgRight mt-1 text-center"
              >
                <div className="row justify-content-end align-items-center maxHeight">
                  <div className="d-none d-md-block col-6 backgroundBlueBlur">
                    <div className="row justify-content-center align-items-center maxHeight p-2">
                      <h3
                        className="fontBebasNeue text-light"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(data[7].title.rendered),
                        }}
                      >
                        {/* {data[7].title.rendered} */}
                      </h3>
                      <p
                        className="text-light fs-5 fontIbarraReal"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(data[7].excerpt.rendered),
                        }}
                      >
                        {/* {data[7].excerpt.rendered} */}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-block d-md-none col-12 backgroundBlueBlur">
                <div className="row justify-content-center align-items-center maxHeight p-2 text-center">
                  <h3
                    className="fontBebasNeue text-light"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(data[7].title.rendered),
                    }}
                  >
                    {/* {data[7].title.rendered} */}
                  </h3>
                  <p
                    className="text-light fs-5 fontIbarraReal"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(data[7].excerpt.rendered),
                    }}
                  >
                    {/* {data[7].excerpt.rendered} */}
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <div className="hrdivider mb-2 mt-2 d-block d-md-none w-100" />
        <div className="row col-12">
          <div className="col-12 col-lg-6">
            <Link
              to={data[8].link.replace(REPLACE_URL, "")}
              state={{ data: data[8] }}
              className="text-decoration-none"
            >
              <div
                id="id_ninth_news_generalnews"
                className="col-12 mt-1 divMediumImg"
              >
                <div className="row align-items-end h-100">
                  <div className="row align-items-center col-12 backgroundBlueBlurSmall">
                    <h3
                      className="text-center fs-6 fontIbarraReal text-light"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data[8].title.rendered),
                      }}
                    >
                      {/* {data[8].title.rendered} */}
                    </h3>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          <div className="hrdivider mb-2 mt-2 d-block d-md-none w-100" />

          <div className="col-12 col-lg-6">
            <Link
              to={data[9].link.replace(REPLACE_URL, "")}
              state={{ data: data[9] }}
              className="text-decoration-none"
            >
              <div
                id="id_last_news_generalnews"
                className="col-12 mt-1 divMediumImg"
              >
                <div className="row align-items-end h-100">
                  <div className="row align-items-center col-12 backgroundBlueBlurSmall">
                    <h3
                      className="text-center fs-6 fontIbarraReal text-light"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(data[9].title.rendered),
                      }}
                    >
                      {/* {data[9].title.rendered} */}
                    </h3>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="row justify-content-center align-items-center mt-3 mb-5">
          <div className="container row w-100">
            <div className="col-6 col-md-3">
              {!latsPageToSearch && (
                <div
                  className="row justify-content-center align-items-center btnButtons pt-2 pb-2"
                  onClick={() => setOffSet(offSet + 1)}
                >
                  <div className="col-3 col-md-2">
                    <PrevPage />
                  </div>
                  <div className="col-6 fontRalewayRegular fs-5 text-start">
                    Anterior
                  </div>
                </div>
              )}
            </div>
            <div className="col-6 col-md-3 ms-auto">
              {offSet !== 0 && (
                <div
                  className="row justify-content-center align-items-center btnButtons pt-2 pb-2"
                  onClick={() => setOffSet(offSet - 1)}
                >
                  <div className="col-6 fontRalewayRegular fs-5 text-end">
                    Próximo
                  </div>
                  <div className="col-3 col-md-2">
                    <NextPage />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralNews;
