import React from "react";
import logoAnimated from "../../media/svgs/logoDesktopAnimated.svg";

// import { Container } from './styles';

export const LoadingPage: React.FC = () => {
  return (
    <div className="row justify-content-center align-items-center h-100 w-100 position-absolute">
      <div className="w-100 row justify-content-center">
        <div className="col-5 col-lg-3 mt-2">
          <img src={logoAnimated} className="img-fluid" alt="logo LFTV" />
        </div>
      </div>
    </div>
  );
};

export const LoadingContent: React.FC = () => {
  return (
    <div className="row justify-content-center align-items-center w-100 loadingContent">
      <div className="col-12 col-md-4 row justify-content-center">
        <div className="spinner-grow backgroundLoadinggrow m-2" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
        <div
          className="spinner-grow backgroundLoadinggrow m-2 spinner-grow-delay"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
        <div
          className="spinner-grow backgroundLoadinggrow m-2 spinner-grow-delay1"
          role="status"
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );
};
